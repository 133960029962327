import React from 'react';
import Calendar from 'react-calendar';
import './custom-calendar.css';
import Swal from "sweetalert2";

const CalendarComponentBusinessExtension = ({
    dateRange,
    onDateChange,
    isSameDay,
    isUnavailableDate,
    oldSelectedDates,
    newSelectedDates, // New selected dates
    oldUnavailableDates,
    newUnavailableDates, // New unavailable dates
    setNewUnavailableDates,
    currentStep,
    dayAvailability,
    extendModal,
    originalStartDate,
    originalEndDate,
    newEndDate,
}) => {

    const onClickDay = (day) => {
        const dateString = day.toLocaleDateString("en-US"); // Normalize date string for comparison
        const clickedDate = new Date(day.getFullYear(), day.getMonth(), day.getDate());
        const dayOfWeek = clickedDate.toLocaleDateString("en-US", { weekday: "short" }); // e.g., "Mon"
    
        if ((dateRange[1].toISOString() === originalEndDate.toISOString()) && currentStep !== 2) {
            Swal.fire({
                icon: "error",
                title: "End Date Required",
                text: "You need to set the extended end date in the first step before proceeding.",
                confirmButtonColor: "#7BBA83",
            });
            return; // Exit early
        }
        
    
        if (currentStep === 2) {
            // Allow only extending the end date beyond the original range
            if (dateRange[0] && clickedDate > originalEndDate) {
                onDateChange([dateRange[0], clickedDate]);
            }
        } else if (currentStep > 4) {
            if (clickedDate > newEndDate) {
                // Exit early if the clicked date is out of range
                return;
            }
    
            // Step 5: Check if the clicked date is part of selectedDates
            if (newSelectedDates.includes(dateString)) {
                Swal.fire({
                    icon: "warning",
                    title: "Date-Specific Date",
                    text: `The date ${dateString} is set as a date-specific date. Please remove it from the date-specific list before marking it as unavailable.`,
                    confirmButtonColor: "#7BBA83",
                });
                return; // Exit early to prevent toggling
            }
    
            // Check if the date's weekday is disabled in weekly hours (dayAvailability)
            if (!dayAvailability[dayOfWeek]) {
                Swal.fire({
                    icon: "info",
                    title: "Weekly Hour Restriction",
                    text: `The date ${dateString} is unavailable based on the weekly visiting hours. To enable it, set it as a date-specific date.`,
                    confirmButtonColor: "#7BBA83",
                });
                return; // Exit early to respect weekly hours
            }
    
            // Toggle the date in newUnavailableDates
            setNewUnavailableDates((currentUnavailableDates) => {
                const dateIndex = currentUnavailableDates.findIndex(
                    (unavailableDate) =>
                        unavailableDate.getFullYear() === clickedDate.getFullYear() &&
                        unavailableDate.getMonth() === clickedDate.getMonth() &&
                        unavailableDate.getDate() === clickedDate.getDate()
                );
    
                if (dateIndex === -1) {
                    // If the date is not in the array, add it
                    return [...currentUnavailableDates, clickedDate];
                } else {
                    // If the date is in the array, remove it
                    const newUnavailableDates = [...currentUnavailableDates];
                    newUnavailableDates.splice(dateIndex, 1);
                    return newUnavailableDates;
                }
            });
        }
    };
    
    const isDateInRange = (date, range) => {
        const start = range[0] ? new Date(range[0].getFullYear(), range[0].getMonth(), range[0].getDate()) : null;
        const end = range[1] ? new Date(range[1].getFullYear(), range[1].getMonth(), range[1].getDate()) : null;

        const checkDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        return start && end && checkDate >= start && checkDate <= end;
    };

    return (
        <div className="bg-white rounded-xl shadow-custom p-4">
            <Calendar
                onChange={currentStep === 2 ? null : onDateChange}
                minDate={currentStep === 2 ? dateRange[0] : new Date()} // Prevent dates before start date in extend mode
                value={dateRange && dateRange.length ? dateRange : null}
                selectRange={false} // No range selection
                onClickDay={onClickDay}
                formatShortWeekday={(locale, date) =>
                    date.toLocaleString(locale, { weekday: 'narrow' }).toUpperCase()
                }
                tileContent={({ date, view }) =>
                    view === 'month' && isSameDay(date, new Date()) ? (
                        <div
                            style={{
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '5px',
                                    height: '5px',
                                    background: '#7096D1',
                                    borderRadius: '9999px',
                                    position: 'absolute',
                                    left: '-9px',
                                    bottom: '-15px',
                                }}
                            ></div>
                        </div>
                    ) : null
                }
                tileClassName={({ date, view }) => {
                    if (view !== 'month') {
                        return null;
                    }

                    const isSelected =
                        oldSelectedDates.some(
                            (selectedDateObj) =>
                                new Date(selectedDateObj.date).toLocaleDateString("en-US") === date.toLocaleDateString("en-US")
                        ) ||
                        newSelectedDates.includes(date.toLocaleDateString("en-US"));

                    if (isSelected) {
                        return 'selected-date';
                    }

                    // Check if the date is in unavailable dates (both old and new)
                    const isSpecial =
                        (isUnavailableDate(date) ||
                            oldUnavailableDates.some((unavailableDate) =>
                                unavailableDate.getFullYear() === date.getFullYear() &&
                                unavailableDate.getMonth() === date.getMonth() &&
                                unavailableDate.getDate() === date.getDate()
                            ) ||
                            newUnavailableDates.some((newUnavailableDate) =>
                                newUnavailableDate.getFullYear() === date.getFullYear() &&
                                newUnavailableDate.getMonth() === date.getMonth() &&
                                newUnavailableDate.getDate() === date.getDate()
                            )) && isDateInRange(date, dateRange);

                    if (isSpecial) {
                        return 'special-date';
                    }
                    // Check if the date is in selected dates (both old and new)

                    return null;
                }}
                tileDisabled={({ date, view }) => {
                    // Allow clicking on months and years
                    if (view !== 'month') return false;

                    const clickedDate = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                    );

                    // Disable dates within the original date range (including start and end)
                    if (clickedDate >= originalStartDate && clickedDate <= originalEndDate) {
                        return true;
                    }

                    // Disable dates before the current start date
                    if (extendModal && clickedDate < dateRange[0]) return true;

                    return false;
                }}
            />
            <div className="m-8 flex justify-center">
                <div className="flex items-center mr-4">
                    <div className="w-6 h-6 bg-mi-blue rounded-full border mr-2"></div>
                    <div className="text-sm font-semibold text-gray-600">Start/End Date</div>
                </div>
                <div className="flex items-center mr-4">
                    <div className="w-6 h-6 bg-mi-red rounded-full border mr-2"></div>
                    <div className="text-sm font-semibold text-gray-600">Unavailable Dates</div>
                </div>
                <div className="flex items-center mr-4">
                    <div className="w-6 h-6 bg-yellow-400 rounded-full border mr-2"></div>
                    <div className="text-sm font-semibold text-gray-600">Selected Dates</div>
                </div>
            </div>
        </div>
    );
};

export default CalendarComponentBusinessExtension;
