import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import TWButton from "../TWButton";
import TWSelect from "../TWSelect";
import TWDropDown from "../TWDropDown";

const Address = ({ data, userName, accountType }) => {
    const [userLocation, setUserLocation] = useState(null);
    const [geoError, setGeoError] = useState("");
    const [isValidZipCode, setIsValidZipCode] = useState(true);
    const navigate = useNavigate();

    const isAddressAvailable = data && data.length > 0;

    // Client-related states
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedOwner, setSelectedOwner] = useState(null);
    const [clientAddresses, setClientAddresses] = useState([]);
    const [userAddresses, setUserAddresses] = useState(data || []);

    const [addresses, setAddresses] = useState(data || []);
    const [selectedAddressId, setSelectedAddressId] = useState(
        isAddressAvailable ? data[0].address_id : null
    );

    const [newAddress, setNewAddress] = useState(false);

    const [line1, setLine1] = useState(
        isAddressAvailable
            ? data[0].line_1 ? data[0].line_1 : ""
            : "");
    const [line2, setLine2] = useState(
        isAddressAvailable
            ? data[0].line_2 ? data[0].line_2 : ""
            : "");
    const [line3, setLine3] = useState(
        isAddressAvailable
            ? data[0].line_3 ? data[0].line_3 : ""
            : "");
    const [city, setCity] = useState(
        isAddressAvailable
            ? data[0].city ? data[0].city : ""
            : "");
    const [state, setState] = useState(
        isAddressAvailable
            ? data[0].state ? data[0].state : ""
            : "");
    const [zipcode, setZipcode] = useState(
        isAddressAvailable
            ? data[0].zip_code ? data[0].zip_code : ""
            : "");

    const [loading, setLoading] = useState(false);

    const csrf_token = Cookies.get("csrftoken");

    const fiftyStates = [
        { value: "AK", label: "Alaska" },
        { value: "AL", label: "Alabama" },
        { value: "AR", label: "Arkansas" },
        { value: "AZ", label: "Arizona" },
        { value: "CA", label: "California" },
        { value: "CO", label: "Colorado" },
        { value: "CT", label: "Connecticut" },
        { value: "DC", label: "District of Columbia" },
        { value: "DE", label: "Delaware" },
        { value: "FL", label: "Florida" },
        { value: "GA", label: "Georgia" },
        { value: "HI", label: "Hawaii" },
        { value: "IA", label: "Iowa" },
        { value: "ID", label: "Idaho" },
        { value: "IL", label: "Illinois" },
        { value: "IN", label: "Indiana" },
        { value: "KS", label: "Kansas" },
        { value: "KY", label: "Kentucky" },
        { value: "LA", label: "Louisiana" },
        { value: "MA", label: "Massachusetts" },
        { value: "MD", label: "Maryland" },
        { value: "ME", label: "Maine" },
        { value: "MI", label: "Michigan" },
        { value: "MN", label: "Minnesota" },
        { value: "MO", label: "Missouri" },
        { value: "MS", label: "Mississippi" },
        { value: "MT", label: "Montana" },
        { value: "NC", label: "North Carolina" },
        { value: "ND", label: "North Dakota" },
        { value: "NE", label: "Nebraska" },
        { value: "NH", label: "New Hampshire" },
        { value: "NJ", label: "New Jersey" },
        { value: "NM", label: "New Mexico" },
        { value: "NV", label: "Nevada" },
        { value: "NY", label: "New York" },
        { value: "OH", label: "Ohio" },
        { value: "OK", label: "Oklahoma" },
        { value: "OR", label: "Oregon" },
        { value: "PA", label: "Pennsylvania" },
        { value: "RI", label: "Rhode Island" },
        { value: "SC", label: "South Carolina" },
        { value: "SD", label: "South Dakota" },
        { value: "TN", label: "Tennessee" },
        { value: "TX", label: "Texas" },
        { value: "UT", label: "Utah" },
        { value: "VA", label: "Virginia" },
        { value: "VT", label: "Vermont" },
        { value: "WA", label: "Washington" },
        { value: "WI", label: "Wisconsin" },
        { value: "WV", label: "West Virginia" },
        { value: "WY", label: "Wyoming" },
    ];

    const getClientList = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/get_client_list`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        try {
            const response = await fetch(url, requestMetaData);
            const result = await response.json();
            if (!result || !result.success) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    confirmButtonColor: "#7BBA83",
                    footer: "Please try again later.",
                });
            } else {
                const clients = result.data.map(client => ({
                    value: client.id.toString(),
                    label: client.name,
                    isClient: true,
                }));
                clients.unshift({ value: "user", label: userName, isClient: false });
                setClientOptions(clients);

                setSelectedOwner(clients[0]);
                setAddresses(userAddresses);
                setSelectedAddressId(isAddressAvailable ? userAddresses[0].address_id : null);
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong while fetching client list!",
            });
        }
    };

    const fetchClientAddresses = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/get_client_addresses`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        try {
            const response = await fetch(url, requestMetaData);
            const result = await response.json();
            if (!result || !result.success) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    confirmButtonColor: "#7BBA83",
                    footer: "Please try again later.",
                });
            } else {
                setClientAddresses(result.data);
                setAddresses(result.data);
                if (result.data.length > 0) {
                    setSelectedAddressId(result.data[0].address_id);
                } else {
                    setSelectedAddressId(null);
                }
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong while fetching client addresses!",
            });
        }
    };

    const createAddress = async () => {
        const isZipValid = await checkValidZipCode();

        if (!isZipValid) {
            return; // Exit if ZIP code is not valid
        }

        const saveData = {
            clientId: selectedOwner?.isClient ? selectedOwner.value : null,
            line1,
            line2,
            line3,
            city,
            state,
            zipcode,
        };
        const url = selectedOwner?.isClient
            ? `${process.env.REACT_APP_DJANGO_API}/add_client_address`
            : `${process.env.REACT_APP_DJANGO_API}/create_address`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(saveData),
        };

        try {
            const response = await fetch(url, requestMetaData);
            const result = await response.json();
            if (!result || !result.success) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    confirmButtonColor: "#7BBA83",
                    footer: "Please try again later.",
                });
            } else {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Address created successfully!",
                }).then(() => {
                    window.location.reload();
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong while creating the address!",
            });
        }
    };

    const deleteAddress = async () => {
        if (addresses.length <= 1) {
            Swal.fire({
                icon: "error",
                title: "Cannot Delete",
                text: "You must have at least one address on file. Deletion not allowed.",
            });
            return;
        }
    
        // 2. Validate that an address is selected
        const addressObj = addresses.find((a) => a.address_id === selectedAddressId);
        if (!addressObj) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "No address selected to delete!",
            });
            return;
        }

        const url = selectedOwner?.isClient
            ? `${process.env.REACT_APP_DJANGO_API}/delete_client_address/${addressObj.address_id}`
            : `${process.env.REACT_APP_DJANGO_API}/delete_address`;

        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: addressObj.address_id }),
        };

        try {
            const response = await fetch(url, requestMetaData);
            const result = await response.json();
            if (!result || !result.success) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    confirmButtonColor: "#7BBA83",
                    footer: "Please try again later.",
                });
            } else {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Address deleted successfully!",
                }).then(() => {
                    window.location.reload();
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong while deleting the address.",
            });
            console.error("Delete Address Error:", error);
        }
    };

    const saveChanges = async () => {
        const addressObj = addresses.find((a) => a.address_id === selectedAddressId);
        if (!addressObj) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "No address selected to update!",
            });
            return;
        }

        const isZipValid = await checkValidZipCode();
        if (!isZipValid) {
            return; // Exit if the ZIP code is not valid
        }

        const saveData = {
            id: addressObj.address_id,
            line1,
            line2,
            line3,
            city,
            state,
            zipcode,
        };

        const url = selectedOwner?.isClient
            ? `${process.env.REACT_APP_DJANGO_API}/update_client_address/${addressObj.address_id}`
            : `${process.env.REACT_APP_DJANGO_API}/update_address`;

        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(saveData),
        };

        try {
            const response = await fetch(url, requestMetaData);
            const result = await response.json();
            if (!result || !result.success) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    confirmButtonColor: "#7BBA83",
                    footer: "Please try again later.",
                });
            } else {
                Swal.fire({
                    icon: "success",
                    title: "Done!",
                    text: "Address updated successfully!",
                    confirmButtonColor: "#7BBA83",
                }).then(() => {
                    window.location.reload();
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong while updating the address!",
                confirmButtonColor: "#7BBA83",
            });
        }
    };

    const checkValidZipCode = async () => {
        try {
            const token = await auth.currentUser.getIdToken();
            const url = `${process.env.REACT_APP_DJANGO_API}/check_zipcode_valid`;
            const requestMetaData = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrf_token,
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({ zipcode }),
            };

            const response = await fetch(url, requestMetaData);
            const result = await response.json();

            if (!result || !result.success) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    confirmButtonColor: "#7BBA83",
                    footer: "Please try again later.",
                });
                return false; // Return false if an error occurs
            } else {
                const [isValid, isAvailable] = result.data;
                setIsValidZipCode(isValid);

                if (isValid && isAvailable) {
                    return true; // Return true if valid and available
                } else if (isValid && !isAvailable) {
                    Swal.fire({
                        icon: "error",
                        title: "Sorry...",
                        text: `The zip code you have entered is in an area we haven't launched in yet.`,
                        confirmButtonColor: "#7BBA83",
                    });
                    return false;
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Invalid ZIP Code",
                        text: "The entered ZIP code is invalid. Please check and try again.",
                        confirmButtonColor: "#7BBA83",
                    });
                    return false;
                }
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong while validating the ZIP code!",
                confirmButtonColor: "#7BBA83",
            });
            return false;
        }
    };


    const currentLocationAddress = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/current_location_address`;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(userLocation),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    const data = result.data;
                    setLine1(data['street']);
                    setCity(data['city']);
                    setState(data['state'][1]);
                    setZipcode(data['zipcode']);
                    setLoading(false);
                }
            });
    }

    const inputCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                setUserLocation({ latitude, longitude });
                setGeoError(false);
            },
                (error) => {
                    setGeoError('Unable to access current location! Please enable the location permission for this site.');
                    setLoading(false);
                }
            );
        } else {
            setGeoError('Unable to access current location! Your browser does not have native geolocation.');
            setLoading(false);
        }
    }

    const handleOwnerChange = (value) => {
        const owner = clientOptions.find((c) => c.value === value);
        setSelectedOwner(owner);

        if (value === "user") {
            setAddresses(userAddresses);
            setSelectedAddressId(userAddresses.length > 0 ? userAddresses[0].address_id : null);
        } else {
            const clientSpecificAddresses = clientAddresses.filter(
                (addr) => addr.client_id === parseInt(value, 10)
            );
            setAddresses(clientSpecificAddresses);
            setSelectedAddressId(clientSpecificAddresses.length > 0 ? clientSpecificAddresses[0].address_id : null);
        }
    };


    function handleAddressChange(value) {
        const numericId = parseInt(value, 10);
        setSelectedAddressId(numericId);
    }

    useEffect(() => {
        if (userLocation) {
            currentLocationAddress();
        }
    }, [userLocation]);

    useEffect(() => {
        if (newAddress) {
            setLine1("");
            setLine2("");
            setLine3("");
            setCity("");
            setState("");
            setZipcode("");
        } else {
            const addressObj = addresses.find((addr) => addr.address_id === selectedAddressId);
            if (addressObj) {
                setLine1(addressObj.line_1 || "");
                setLine2(addressObj.line_2 || "");
                setLine3(addressObj.line_3 || "");
                setCity(addressObj.city || "");
                setState(addressObj.state || "");
                setZipcode(addressObj.zip_code || "");
            }
        }
    }, [newAddress, selectedAddressId, addresses]);

    useEffect(() => {
        if (accountType === 3) {
            getClientList();
            fetchClientAddresses();
        } else {
            clientOptions.unshift({ value: "user", label: userName, isClient: false });
        }

        setSelectedOwner({ value: "user", label: userName, isClient: false });

        setAddresses(userAddresses);
        setSelectedAddressId(isAddressAvailable ? userAddresses[0]?.address_id : null);
    }, []);


    useEffect(() => {
        if (selectedOwner) {
            let filteredAddresses;

            if (selectedOwner.value === "user") {
                filteredAddresses = userAddresses;
            } else {
                filteredAddresses = clientAddresses.filter(
                    (addr) => addr.client_id === parseInt(selectedOwner.value, 10)
                );
            }

            setAddresses(filteredAddresses);
            setSelectedAddressId(filteredAddresses.length > 0 ? filteredAddresses[0].address_id : null);
        }
    }, [selectedOwner, userAddresses, clientAddresses]);

    return (
        <>
            <div className="flex flex-col gap-10 py-8 px-10 md:px-40">
                <div className="flex flex-row items-center gap-4">
                    <h3 className="md:w-[20%] font-bold text-2xl text-gray-600">Addresses</h3>

                    {accountType === 3 && (
                        <div className="w-full md:w-1/3 mx-2">
                            <h4>Select Address Holder:</h4>
                            <TWDropDown
                                white
                                data={[
                                    { value: "", label: "Select Address Holder", disabled: true },
                                    ...clientOptions.map((client) => ({
                                        value: client.value.toString(),
                                        label: client.label,
                                    })),
                                ]}
                                excludeZeroKey={true}
                                onChange={(value) => {
                                    if (value) {
                                        handleOwnerChange(value);
                                    }
                                }}
                                value={selectedOwner?.value || "user"}
                            >
                                Select Address Holder
                            </TWDropDown>
                        </div>
                    )}

                    <div className={`w-full md:w-1/3 ${accountType !== 3 ? "ml-auto" : ""}`}>
                        {selectedOwner && (
                            <div className="text-left">
                                <h4>Select Address:</h4>
                                <TWDropDown
                                    white
                                    data={[
                                        { value: "", label: "Select Address", disabled: true },
                                        ...addresses.map((addr) => ({
                                            value: addr.address_id,
                                            label: `${addr.line_1 || "N/A"}, ${addr.city || "N/A"}`,
                                        })),
                                    ]}
                                    excludeZeroKey={true}
                                    onChange={(value) => {
                                        if (value) {
                                            handleAddressChange(value);
                                        }
                                    }}
                                    value={selectedAddressId || ""}
                                >
                                    Select Address
                                </TWDropDown>
                                {addresses.length === 0 && (
                                    <p style={{ color: "red" }}>No addresses available for the selected holder.</p>
                                )}
                            </div>
                        )}
                    </div>


                    <div className="hidden md:flex justify-self-end items-end justify-end self-end content-end">
                        <TWButton
                            sm
                            onClick={() => setNewAddress(!newAddress)}
                            disabled={loading || !isAddressAvailable}
                        >
                            {newAddress ? "Cancel New Address" : "Add New Address"}
                        </TWButton>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-10">
                    <div className="flex flex-col gap-10 md:w-1/2">
                        <div>
                            <h4 className="w-full font-semibold text-gray-500">Line 1:</h4>
                            <div className="w-full flex">
                                <input
                                    id="autocomplete"
                                    type="text"
                                    value={line1}
                                    placeholder="Enter Street 1"
                                    onChange={(e) => setLine1(e.target.value)}
                                    required
                                    className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"
                                />
                            </div>
                        </div>

                        <div>
                            <h4 className="w-full font-semibold text-gray-500">Line 2 (optional):</h4>
                            <div className="w-full flex">
                                <input
                                    type="text"
                                    value={line2}
                                    placeholder="Enter Street 2"
                                    onChange={(e) => setLine2(e.target.value)}
                                    className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"
                                />
                            </div>
                        </div>

                        <div>
                            <h4 className="w-full font-semibold text-gray-500">Line 3 (optional):</h4>
                            <div className="w-full flex">
                                <input
                                    type="text"
                                    value={line3}
                                    placeholder="Enter Street 3"
                                    onChange={(e) => setLine3(e.target.value)}
                                    className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-10 mb-4 md:w-1/2">
                        <div>
                            <h4 className="w-full font-semibold text-gray-500">City:</h4>
                            <div className="w-full flex">
                                <input
                                    type="text"
                                    value={city}
                                    placeholder="Enter your city here"
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                    className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"
                                />
                            </div>
                        </div>

                        <div>
                            <h4 className="w-full font-semibold text-gray-500">State:</h4>
                            <div className="w-full flex">
                                <TWSelect
                                    whitebox
                                    value={state}
                                    disabled={loading}
                                    onChange={(e) => setState(e.target.value)}
                                >
                                    {fiftyStates.map((province, index) => (
                                        <option key={index} value={province.value}>
                                            {province.label}
                                        </option>
                                    ))}
                                </TWSelect>
                            </div>
                        </div>

                        <div>
                            <h4 className="w-full font-semibold text-gray-500">Zipcode:</h4>
                            <div className="w-full flex">
                                <input
                                    type="text"
                                    value={zipcode}
                                    placeholder="Enter your zipcode here"
                                    onChange={(e) => setZipcode(e.target.value)}
                                    required
                                    className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"
                                />
                            </div>
                            {!isValidZipCode && (
                                <div className="flex md:flex-row w-full pt-3 pl-1 text-red-500 text-sm">
                                    Please enter a valid US zip code
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-4 md:flex-row w-full items-center justify-end px-10 md:px-0">
                    <div className="w-full flex-auto flex flex-row justify-start items-center">
                        {newAddress ? (
                            <p className="font-bold text-mi-green text-lg">
                                Adding New Address . . .
                            </p>
                        ) : (
                            <div className="w-full md:w-1/6">
                                <TWButton
                                    delete
                                    md
                                    onClick={async () => {
                                        setLoading(true);
                                        await deleteAddress();
                                        setLoading(false);
                                    }}
                                    loading={loading}
                                    disabled={loading || !selectedAddressId}
                                >
                                    Delete Address
                                </TWButton>
                            </div>
                        )}
                    </div>

                    {geoError && (
                        <div className="flex md:flex-row w-full justify-end py-0 text-red-500">
                            {geoError}
                        </div>
                    )}

                    <div className="w-full items-center md:w-1/6">
                        <TWButton
                            md
                            onClick={() => {
                                inputCurrentLocation();
                                setLoading(true);
                            }}
                            loading={loading}
                            disabled={loading}
                        >
                            Use Current Location
                        </TWButton>

                        <TWButton
                            md
                            onClick={async () => {
                                setLoading(true);
                                if (newAddress) {
                                    await createAddress();
                                } else {
                                    await saveChanges();
                                }
                                setLoading(false);
                            }}
                            loading={loading}
                            disabled={loading}
                        >
                            Save Address
                        </TWButton>

                    </div>

                    <div className="w-full items-center md:hidden">
                        <TWButton
                            md
                            onClick={() => setNewAddress(!newAddress)}
                            disabled={loading || !isAddressAvailable}
                        >
                            {newAddress ? "Cancel New Address" : "Add New Address"}
                        </TWButton>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Address;