import { Rating } from "@mui/material";
import { isoToDate, formatDate } from "../../dateUtils";
import { auth } from "../../firebase";

import Swal from "sweetalert2";
import Cookies from "js-cookie";

import InstagramIcon from "@mui/icons-material/Instagram";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import ProfilePic from "../ProfilePic";
import TWButton from "../TWButton";
import HorizontalTimeline from "../timeline/HorizontalTimeline";
import { useState } from "react";
import CreateSubmissionModal from "../CreateSubmissionModal";

const InfluencerTimelineCard = (props) => {
    const [rating, setRating] = useState(props.data.rating);
    const [submissionModal, setSubmissionModal] = useState(false);
    const csrf_token = Cookies.get("csrftoken");

    const confirmPickupWrapper = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "By confirming pickup, you are confirming that you have picked up the product provided to you by the business for content creation and/or visited their place of business as intended for the campaign. This action cannot be undone.",
            showCancelButton: true,
            confirmButtonColor: "#7BBA83",
            cancelButtonColor: "#ef4444",
            confirmButtonText: "Yes, confirm pickup!"
        }).then((result) => {
            if (result.isConfirmed) {
                props.confirmPickup();
            }
        });
    }

    // function to send a new rating to the backend
    const create_rating = async (newRating) => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/create_rating`;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                rating: newRating,
                contractID: props.data.contract_id,
            }),
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Done!",
                            text: "Successfully updated rating!",
                            confirmButtonColor: "#7BBA83",
                        });
                    };
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    // helper function to detect when a new rating is inputted
    const rate = (newRating) => {
        if (newRating !== rating) {
            create_rating(newRating);
            setRating(newRating);
        }
    }

    return (
        <>
            {(props.data.status === "Current" || props.data.status === "Fullfilled")
                ? <h2>Contract Timeline</h2>
                : <h2 className="mt-5">Proposal Timeline</h2>
            }
            <div className="w-full bg-white rounded-3xl gap-2 md:overflow-y-auto md:scrollbar p-8 border shadow-all-xl">
                <div className="w-full bg-white rounded-xl pt-4">
                    <div className="w-full flex flex-row px-8 mb-8 items-center gap-5">
                        <div className="w-[75px]">
                            <ProfilePic border image={props.data.partner.profile_picture} />
                        </div>
                        <div className="flex flex-col">
                            <p className="font-bold text-neutral-950 font-poppins">{props.data.partner.name}</p>
                            <p className="text-xs font-bold text-neutral-500">{props.data.category}</p>
                        </div>
                    </div>
                    <div className="flex flex-col my-4 md:w-[95%] ml-10">
                        <HorizontalTimeline data={props.data} step={props.step} />
                    </div>
                    <div className="grid grid-cols-2 px-4 md:px-8 py-4 gap-4">
                        <div>
                            <h2 className="w-full mb-4 text-left text-mi-blue ">
                                {props.step === 1
                                    ? "Proposal Received!"
                                    : props.step === 1.75
                                        ? "Proposal Declined!"
                                        : props.step === 2
                                            ? "We're currently awaiting payment from the brand"
                                            : props.step === 2.75
                                                ? "Contract Expired: Payment Failed"
                                                : props.step === 3
                                                    ? `Visit Scheduled: ${isoToDate(props.data.visit_date)}`
                                                    : props.step === 3.5
                                                        ? <>
                                                            Visit Scheduled: <p className="text-amber-500">{isoToDate(props.data.amendment_approved[0].new_visit_date)}</p>
                                                        </>
                                                        : props.step === 3.8
                                                            ? <>
                                                                Visit Scheduled: <p className="text-red-500">{isoToDate(props.data.visit_date)}</p>
                                                            </>
                                                            : props.step === 3.9
                                                                ? <>
                                                                    Visit Scheduled: <p className="text-red-500">{isoToDate(props.data.amendment_approved[0].new_visit_date)}</p>
                                                                </>
                                                                : props.step === 4
                                                                    ? `Post Deadline: ${formatDate(props.data.submission_date)}`
                                                                    : props.step === 4.5
                                                                        ? <>
                                                                            Post Deadline: <p className="text-amber-500">{formatDate(props.data.amendment_approved[0].new_submission_date)}</p>
                                                                        </>
                                                                        : props.step === 4.75
                                                                            ? <>
                                                                                Post Deadline: <p className="text-red-500">{formatDate(props.data.submission_date)}</p>
                                                                            </>
                                                                            : props.step === 4.9
                                                                                ? <>
                                                                                    Post Deadline: <p className="text-red-500">{formatDate(props.data.amendment_approved[0].new_submission_date)}</p>
                                                                                </>
                                                                                : props.step === 5
                                                                                    ? "Post Published!"
                                                                                    : "" // should never reach
                                }
                            </h2>
                            {props.step === 1
                                ? <>
                                    <p className="text-gray-600 font-poppins">
                                        {`Contratulations! You just received an proposal from ${props.data.partner.name}.`}
                                    </p>
                                    <p className="text-gray-600 font-poppins mb-4">
                                        You have 48 hours to respond. This proposal expires on <b>{isoToDate(props.data.accept_expiration_date)}</b>
                                    </p>
                                    {(props.data.status === "Current" || props.data.status === "Fullfilled")
                                        ? <p className="text-gray-600 font-poppins">View contract details below</p>
                                        : <p className="text-gray-600 font-poppins">View proposal details below</p>
                                    }
                                </>
                                : props.step === 1.75
                                    ? <>
                                        <p className="text-gray-600 font-poppins mb-4">
                                            You declined this proposal on <b>{isoToDate(props.data.response_date)}</b>
                                        </p>
                                        <p className="text-gray-600 font-poppins">
                                            Decline reason: {props.data.cancellation_reason}
                                        </p>
                                    </>
                                    : props.step === 2
                                        ? <>
                                            <p className="text-gray-600 font-poppins mb-4">
                                                The brand has until <b>{isoToDate(props.data.pay_expiration_date)}</b> to fulfill payment.
                                            </p>
                                            <p className="text-gray-600 font-poppins">
                                                In the event that they do not complete payment to the platform, this contract is null and void.
                                            </p>
                                        </>
                                        : props.step === 2.75
                                            ? <>
                                                <p className="text-gray-600 font-poppins mb-4">
                                                    Unfortunately the brand failed to fulfill payment by <b>{isoToDate(props.data.pay_expiration_date)}</b>.
                                                </p>
                                                <p className="text-gray-600 font-poppins">
                                                    This contract is null and void.
                                                </p>
                                            </>
                                            : (props.step === 3 || props.step === 3.5 || props.step === 3.8 || props.step === 3.9)
                                                ? <>
                                                    <p className="text-gray-600 font-poppins">{props.data.address.line_1}</p>
                                                    {props.data.address.line_2 ? <p className="text-gray-600 font-poppins">{props.data.address.line_2}</p> : <></>}
                                                    {props.data.address.line_3 ? <p className="text-gray-600 font-poppins">{props.data.address.line_3}</p> : <></>}
                                                    <p className="text-gray-600 font-poppins">{props.data.address.city}, {props.data.address.state} {props.data.address.zip_code}</p>
                                                </>
                                                : (props.step === 4 || props.step === 4.5)
                                                    ? <>
                                                        <div className="font-semibold text-gray-600 font-poppins">
                                                            Deliverable: <p className="inline font-normal text-base text-gray-600 font-poppins">
                                                                {props.data.post_type}
                                                            </p>
                                                        </div>
                                                        <div className="font-semibold text-gray-600 font-poppins">
                                                            Platform: <p className="inline font-normal text-base text-gray-600 font-poppins">
                                                                Instagram
                                                            </p>
                                                        </div>
                                                    </>
                                                    : (props.step === 4.75 || props.step === 4.9)
                                                        ? <p className="text-gray-600 font-poppins mb-4">
                                                            You did not complete the deliverable within the agreed upon time. The brand may request a refund. Please submit the post or file a claim.
                                                        </p>
                                                        : props.step === 5
                                                            ? <p className="text-gray-600 font-poppins mb-4">
                                                                Congratulations on successfully publishing your final deliverable for this contract! You should receive payment with the final amount of {props.data.price_details.take_home} after 2 weeks.
                                                            </p>
                                                            : "" // should never reach
                            }
                        </div>
                        {(props.step >= 3 && props.step < 5) &&
                            <div>
                                <p className="w-full mb-4 text-left text-slate-700 text-lg font-semibold Dosis">
                                    Product Included: {props.data.campaign.product_name}
                                </p>
                                <p className="text-gray-600 font-poppins">
                                    {props.data.campaign.product_description}
                                </p>
                            </div>
                        }
                        {(props.step === 5) &&
                            <div className="flex flex-col w-full justify-end items-end">
                                {/* <a className="w-full mb-4 text-right text-gray-500 NotoSans underline">
                                    Payment FAQs
                                </a> */}
                                <TWButton secondary onClick={() => Swal.fire({
                                    title: "We're sorry for the trouble!",
                                    html:
                                        `<p>If it's been more than two weeks since you submit your post,</p>
                                        <p>please contact us at <b>kyle.painting@myinfluency.com</b></p>
                                        <p>We will get back to you as soon as possible!</p>`,
                                    confirmButtonColor: "#7BBA83",
                                })}>
                                    It's been more than two weeks and I haven't received payment
                                </TWButton>
                            </div>
                        }
                    </div>
                    {(!props.data.visit_confirmed_influencer && props.step >= 3 && props.step < 4) &&
                        <div className="w-full flex flex-row justify-end items-center p-4">
                            <div className="md:w-1/6 relative">
                                <TWButton sm onClick={confirmPickupWrapper} >
                                    <div className="flex flex-row gap-2 items-center">
                                        <CheckRoundedIcon className="text-white" />
                                        Confirm Pickup
                                    </div>
                                </TWButton>
                            </div>
                        </div>
                    }

                    {(props.step >= 4 && props.step < 5) &&
                        <div className="w-full flex flex-row justify-end items-center p-4">
                            <div className="md:w-1/6">
                                <TWButton sm onClick={() => setSubmissionModal(true)}>
                                    <div className="flex flex-row gap-2 items-center">
                                        <InstagramIcon className="text-white" />
                                        Connect Post
                                    </div>
                                </TWButton>
                            </div>
                        </div>
                    }
                    {(props.step === 5) &&
                        <div className="flex flex-col w-full h-full justify-end items-end">
                            <p className="text-gray-600 font-poppins">Rate this Business</p>
                            <Rating
                                name="simple-controlled"
                                value={parseInt(rating)}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        rate(newValue);
                                    }
                                }}
                            />
                        </div>
                    }
                </div>
            </div>
            {/* Submission modal */}
            {submissionModal && <CreateSubmissionModal contractID={props.data.contract_id} setShowModal={setSubmissionModal} />}
        </>
    );
}
export default InfluencerTimelineCard;