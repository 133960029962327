import 'react-datepicker/dist/react-datepicker.css'
import ReactGA from "react-ga4";
import { useState } from "react";
import { auth } from '../../firebase';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

import ProfilePic from "../ProfilePic";
import Spinner from '../Spinner';
import TWButton from "../TWButton";
import TWSelect from '../TWSelect';
import TWTextInput from '../TWTextInput';
import { useMediaQuery } from "react-responsive";

const CartInfluencerCard = (props) => {
    const csrf_token = Cookies.get("csrftoken");
    const [loading, setLoading] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState(null);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

    const removeFromCart = async () => {
        props.setCartLoading(true);
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/remove_cartinflu`;
        const finalBody = {
            influencerUid: props.offerData.influencerUid,
            campaign: props.data.campaign,
        }
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(finalBody),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Internal Error occured",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: result.alert,
                        confirmButtonColor: "#7BBA83",
                    });

                    ReactGA.send({
                        hitType: "remove_from_cart",
                        currency: "USD",
                        value: 0,
                        items: [{
                            item_id: props.offerData.influencerUid,
                            item_name: props.data.name,
                        }]
                    });
                    props.setCartLoading(false);
                    const data = result.data;
                    props.setCartInfluList(data);
                }
            });
    };

    const sendOffer = async () => {
        setLoading(true);
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/send_offer`;
        let finalOfferData = props.offerData;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(finalOfferData),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result) {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Somthing went wrong.",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else if (!result.success) {
                    switch (result.data.failure_reason) {
                        case 1:
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "The start date must be at least 48 hours in the future!",
                                confirmButtonColor: "#7BBA83",
                            });
                            break;
                        case 2:
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "The end date must be at least ten days after your start date!",
                                confirmButtonColor: "#7BBA83",
                            });
                            break;
                        case 3:
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Your agency subscription must be active/paid in order to request price change!",
                                confirmButtonColor: "#7BBA83",
                            });
                            break;
                        case 4:
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "You cannot enter a price lower than $0!",
                                confirmButtonColor: "#7BBA83",
                            });
                            break;
                        default:
                            break;
                    }
                    setLoading(false);
                } else {
                    ReactGA.event({
                        category: "Send Proposal",
                        action: "Send single proposal",
                        label: "Send single proposal",
                        value: 1,
                    });
                    const data = result.data;
                    props.setCartInfluList(data);
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: result.alert,
                        confirmButtonColor: "#7BBA83",
                    }).then(() => window.location.reload());
                }
            }
            );
    };

    const handlePostTypeChange = (campaignId, influencerUid, postTypeId) => {
        setSelectedPrice(null); // Reset the price when a new post type is selected
        // Check if the influencer is eligible for pricing update
        const priceData = props.priceData.find(price => price.price_type === Number(postTypeId));

        if (props.isPremium && priceData) {
            setSelectedPrice(priceData.price_amount);  // Update the price based on the selected post type
        }

        // Call parent's function to update offer data for post type of specific influencer
        props.postType(campaignId, influencerUid, postTypeId);
    };

    const handleProductChange = (campaignId, influencerUid, productId) => {
        // Call parent's function to update offer data for product of specific influencer
        props.product(campaignId, influencerUid, productId);
    };

    const handleAddressChange = (campaignId, influencerUid, addressId) => {
        // Call parent's function to update offer data for address of specific influencer
        props.address(campaignId, influencerUid, addressId);
    };

    return (
        <>

            {!props.data
                ? <div className="w-full h-full flex flex-col"><Spinner /></div>

                : <div className="flex flex-col md:flex md:flex-row items-start w-full rounded-xl my-6 py-4 px-3 bg-white shadow-lg">
                    {/*body*/}
                    {!isSmallScreen && (
                        <div className="flex flex-col flex-grow md:gap-2 px-6 py-4 w-full">
                            <div className="flex flex-col grid md:grid-cols-3 sm:grid-cols-1 gap-x-5 md:pt-2">
                                <div className="flex flex-row row-span-2 items-center order-1 gap-3 md:pl-5 pb-4">

                                    <div className="h-32 w-32">
                                        <ProfilePic border={true} image={props.data.profile_pic ? props.data.profile_pic : null} />
                                    </div>
                                    <div className="flex flex-col">
                                        <h3 className="text-start text-xl font-bold text-slate-600">
                                            {props.data.name}
                                        </h3>
                                        <h3 className="text-start text-sm font-semibold text-slate-400">
                                            <a href={`https://instagram.com/${props.data.ig_handle}`} className="text-mi-green truncate" style={{ maxWidth: 'calc(100% - 20px)' }} target="_blank">
                                                @{props.data.ig_handle}
                                            </a>
                                        </h3>
                                    </div>

                                </div>
                                <div className='flex flex-col order-2 sm:order-2 gap-4 justify-start md:justify-between'>

                                    <div className="w-full flex flex-row gap-4">
                                        <div className="w-full">
                                            <h4 className="mb-1 text-base sm:text-lg font-bold text-gray-800">Post Type</h4>
                                            <TWSelect whitebox onChange={(e) => {
                                                handlePostTypeChange(props.data.campaign, props.data.influencer_id, e.target.value);
                                            }}>
                                                <option value="">Select</option>
                                                {props.priceData && props.priceData.map(price => {
                                                    let label = "";
                                                    switch (price.price_type) {
                                                        case 0: label = "Feed Post"; break;
                                                        case 1: label = "Reel"; break;
                                                        case 2: label = "Videos"; break;
                                                        case 3: label = "Stories"; break;
                                                        default: label = "";
                                                    }
                                                    return (
                                                        label && <option key={price.price_type} value={price.price_type}>{`${label} - $${price.price_amount}`}</option>
                                                    );
                                                })}
                                            </TWSelect>
                                        </div>
                                    </div>

                                    <div className="flex flex-row gap-4">
                                        <div className="w-full order-3 mb-1">
                                            <div className="mb-1 text-base sm:text-lg font-bold text-gray-800">Product</div>
                                            <TWSelect whitebox name="campaign-name" onChange={(e) => handleProductChange(props.data.campaign, props.data.influencer_id, e.target.value)}>
                                                <option value="">Select</option>
                                                {props.prodList.map((product, index) => (
                                                    <option key={index} value={product.prod_id}>{product.prod_name}</option>
                                                ))}
                                            </TWSelect>
                                        </div>
                                        <div className="w-full order-5 justify-end mb-1">
                                            <div className="mb-1 text-base sm:text-lg font-bold text-gray-800">Address</div>
                                            <div className="w-full">
                                                <select
                                                    className="w-full p-2 rounded-lg border outline-gray-400 text-gray-800 disabled:cursor-not-allowed disabled:opacity-50"
                                                    onChange={(e) =>
                                                        handleAddressChange(props.data.campaign, props.data.influencer_id, e.target.value)
                                                    }
                                                >
                                                    <option value="">Select</option>
                                                    {(props.addressList || []).map((address, index) => (
                                                        <option key={index} value={address.address_id}>
                                                            {`${address.line_1}, ${address.line_2 || ""}, ${address.line_3 || ""}, ${address.city}, ${address.state}, ${address.zip_code}`}
                                                        </option>
                                                    ))}
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='order-6 justify-end flex flex-row w-full gap-2'>
                                    <div className="mb-1 sm:flex hidden items-end">
                                        <TWButton sm delete onClick={removeFromCart} loading={loading || props.cartLoading} disabled={loading || props.cartLoading}>Remove</TWButton>
                                    </div>
                                    <div className="my-auto mb-1">
                                        <TWButton sm onClick={sendOffer}
                                            loading={loading || props.cartLoading}
                                            disabled={!(
                                                props.offerData?.address &&
                                                (props.offerData?.postType !== null && props.offerData?.postType !== undefined) &&
                                                props.offerData?.product &&
                                                (!props.isPremium || (props.isPremium && props.offerData?.price !== null && props.offerData?.price !== undefined))
                                            ) || loading || props.cartLoading}>
                                            Send Proposal
                                        </TWButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {isSmallScreen && (
                        <div className="w-full flex flex-col gap-y-3">

                            <div className="flex flex-row justify-between gap-3 md:px-5 pb-4">
                                <div className="w-full gap-4 flex flex-row">
                                    <div className="h-20 w-20">
                                        <ProfilePic border={true} image={props.data.profile_pic ? props.data.profile_pic : null} />
                                    </div>
                                    <div className="flex flex-col">
                                        <h3 className="text-start text-lg font-semibold text-slate-600">
                                            {props.data.name}
                                        </h3>

                                        <h3 className="text-start text-sm font-semibold text-slate-400">
                                            {props.data.category}
                                        </h3>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" className="lg:hidden text-gray-400 w-8 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white relative  z-40" data-modal-hide="defaultModal" onClick={removeFromCart} loading={loading || props.cartLoading} disabled={loading || props.cartLoading} >
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>
                            </div>

                            <div className="w-full flex flex-col">
                                <h4 className="mb-1 text-base sm:text-lg font-bold text-gray-800">Post Type</h4>
                                <TWSelect whitebox onChange={(e) => {

                                    props.postType(props.index, e.target.value);
                                    if (props.priceData) {
                                        let selectedPriceData = null;
                                        for (let i = 0; i < props.priceData.length; i++) {
                                            if (props.priceData[i].price_type === Number(e.target.value)) {
                                                selectedPriceData = props.priceData[i];
                                                break;
                                            }
                                        }
                                        if (props.isPremium) {
                                            setSelectedPrice(selectedPriceData ? selectedPriceData.price_amount : null);
                                        }
                                    }
                                }}>
                                    <option value="">Select</option>
                                    {
                                        props.priceData &&
                                        props.priceData.map(price => {
                                            let label = "";
                                            switch (price.price_type) {
                                                case 0: label = "Feed Post"; break;
                                                case 1: label = "Reel"; break;
                                                case 2: label = "Videos"; break;
                                                case 3: label = "Stories"; break;
                                                default: label = "";
                                            }
                                            return (
                                                label && <option key={price.price_type} value={price.price_type}>{`${label} - $${price.price_amount}`}</option>
                                            )
                                        })
                                    }
                                </TWSelect>
                            </div>
                            <div className=" mb-1">
                                <div className="mb-1 text-base sm:text-lg font-bold text-gray-800">Product</div>
                                <TWSelect whitebox name="campaign-name" onChange={e => props.product(props.index, e.target.value)}>
                                    <option value="">Select</option>
                                    {props.prodList.map((product, index) => {
                                        return (
                                            <option key={index} value={product.prod_id}>{product.prod_name}</option>
                                        )
                                    })}
                                </TWSelect>
                            </div>
                            <div className="mb-1">
                                <div className="mb-1 text-base sm:text-lg font-bold text-gray-800">Address</div>
                                <div className="w-full">
                                    <select
                                        className="w-full p-2 rounded-lg border outline-gray-400 text-gray-800 disabled:cursor-not-allowed disabled:opacity-50"
                                        onChange={(e) =>
                                            handleAddressChange(props.data.campaign, props.data.influencer_id, e.target.value)
                                        }
                                    >
                                        <option value="">Select</option>
                                        {props.addressList.map((address, index) => (
                                            <option key={index} value={address.address_id}>
                                                {`${address.line_1}, ${address.line_2 || ""}, ${address.line_3 || ""}, ${address.city}, ${address.state}, ${address.zip_code}`}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="my-auto mb-1 flex justify-center">
                                <div className="my-auto mb-1 flex justify-center">
                                    <TWButton sm onClick={sendOffer}
                                        loading={loading || props.cartLoading}
                                        disabled={!(props.offerData.address
                                            && (props.offerData.postType !== null && props.offerData.postType !== undefined)
                                            && props.offerData.product)
                                            || loading
                                            || props.cartLoading}>
                                        Send Proposal
                                    </TWButton>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            }
        </>
    );
}
export default CartInfluencerCard;