import { useState } from "react";

const TWTextField = (props) => {
    const [type, setType] = useState("text");

    const execute = (e) => {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
        if (props.onEnter) {
            if (e.key === "Enter" && e.target.value) {
                props.onEnter(e.target.value);
            }
        }
        if (props.type) {
            setType(props.type);
        }
    };

    return (
        <>
            {props.whitebox ? (
                <input
                    type={type}
                    value={props.value ?? ""} // Default to empty string
                    placeholder={props.placeholder}
                    onKeyPress={(e) => execute(e)}
                    onChange={(e) => execute(e)}
                    required
                    className={`flex rounded-md border-2 text-gray-700 placeholder-neutral-600 text-sm pl-4 p-3 w-full`}
                />
            ) : (
                <input
                    type={type}
                    value={props.value ?? ""} // Default to empty string
                    placeholder={props.placeholder}
                    onKeyPress={(e) => execute(e)}
                    onChange={(e) => execute(e)}
                    required
                    className="flex bg-blue-200 bg-opacity-30 rounded-full shadow-xl border-1 text-gray-700 placeholder-neutral-600 placeholder:text-xs text-sm pl-4 p-2 w-11/12"
                />
            )}
        </>
    );
};

export default TWTextField;
