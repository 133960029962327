import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import Spinner from "../Spinner";
import ProfilePicSettings from "./ProfilePicSettings";
import BusinessProfileSettings from "./BusinessProfileSettings";
import InfluencerProfileSettings from "./InfluencerProfileSettings";
import Unlink from "./Unlink";
import Address from "./Address";

const Profile = (props) => {
    const [userData, setUserData] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const csrf_token = Cookies.get("csrftoken");

    useEffect(() => {
        const getUserData = async () => {
            const token = await auth.currentUser.getIdToken();
            const url = `${process.env.REACT_APP_DJANGO_API}/get_user_info`;
            const requestMetaData = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrf_token,
                    "Authorization": `Bearer ${token}`,
                },
            };
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        setUserData(result.data);
                    }
                });
        }
        getUserData();
    }, [csrf_token]);

    // Function to handle profile picture updates from ProfilePicSettings
    const handleProfilePictureUpdate = (newProfilePicture) => {
        setProfilePicture(newProfilePicture);
    };

    return (
        <>
            <div className="divide-y">
                {(!userData)
                    ? <div className="w-full h-screen flex flex-col"><Spinner /></div>
                    : <>
                        <ProfilePicSettings
                            data={userData}
                            onSaveProfilePicture={handleProfilePictureUpdate}
                        />
                        {(userData.is_influencer)
                            ? <>
                                <InfluencerProfileSettings
                                    data={userData}
                                    profilePicture={profilePicture}
                                />
                                <Unlink />
                            </>
                            : <>
                                <BusinessProfileSettings data={userData}
                                    profilePicture={profilePicture} />
                                <Address data={userData.addresses} userName={`${userData.first_name} ${userData.last_name}`} accountType={userData.account_type} />
                            </>
                        }
                    </>
                }
            </div>
        </>
    );
};

export default Profile;
