import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import Swal from 'sweetalert2';

import LogoGif from "../../assets/images/logoGif.gif";
import UserSignup from "./UserSignup";
import BusinessSignup from "./BusinessSignup";
import BusinessAddressSignup from "./BusinessAddressSignup";
import Cookies from "js-cookie";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";
import coffeeTable from "../../assets/images/coffeeTable.svg"

const BusinessSignupFlow = (props) => {
    const [step, setStep] = useState(props.step);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({
        email: auth.currentUser.email,
        isInfluencer: false,
        firstName: props.firstName,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    const [businessData, setBusinessData] = useState({});

    const [addressData, setAddressData] = useState({});
    const csrf_token = Cookies.get("csrftoken");
    const navigate = useNavigate();

    const nextStep = async (data) => {
        // add provided data to userData
        if (step === 1) {
            setUserData({
                ...userData,
                ...data,
            });
        } else if (step === 2) {
            setBusinessData({
                ...businessData,
                ...data,
            });
            await new Promise((resolve) => {
                setUserData((prevUserData) => ({
                    ...prevUserData,
                    phoneNumber: data.phoneNumber,
                }));
                resolve();
            });
        } else {
            setAddressData({
                ...addressData,
                ...data,
            })
        }

        setStep(step + 1);
    }

    const createUser = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/create_user`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(userData),
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        let footer = "Please try again later.";
                        if (result) {
                            if (result.data === "Access Key already in use!") {
                                footer = "That access key is already in use!";
                            } else if (result.data === "Could not find access key!") {
                                footer = "We couldn't find that access key! Please check your key and try again.";
                            }
                        }
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: footer,
                        });
                        if (footer === "Please try again later.") { navigate("/create_account"); }
                        else {
                            setStep(1);
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    const createBusiness = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/create_associated_business`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(businessData),
        };

        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        setLoading(false);
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    const createAddress = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/create_business_address`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(addressData),
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        navigate("/home");
                        setLoading(false);
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }

    useEffect(() => {
        if (loading) {
            switch (step) {
                case 1:
                    break;
                case 2:
                    // api call to create user object
                    // check to ensure user arrived through form and not redirect
                    if (userData.lastName) {
                        createUser();
                    } else {
                        setLoading(false);
                    }
                    break;
                case 3:
                    // api call to create business object
                    // check to ensure user arrived through form and not redirect
                    if (businessData.companyName) {
                        createBusiness();
                    } else {
                        setLoading(false);
                    }
                    break;
                case 4:
                    // api call to create an associated address
                    createAddress();
                    break;
                default:
                    break;
            }
        }
    }, [loading]);

    useEffect(() => {
        if (step > 1) {
            setLoading(true);
        }
    }, [step]);

    return (
        <>
            <div className="flex flex-row h-screen w-screen items-center">
                {/* MAIN CONTENT */}
                <div className="flex-auto h-full flex flex-col items-center gap-4">
                    {/* LOGO */}
                    <div className="flex-auto max-h-[25%] flex flex-col justify-end items-center w-full p-4">
                        <div className="max-w-[18rem]">
                            <img alt="My Influency" src={LogoGif} />
                        </div>
                    </div>

                    {/* FORM */}
                    <div className="flex-auto w-full max-w-[80%] items-center flex flex-col gap-2">
                        {
                            loading
                                ? <Spinner />
                                : (step === 1)
                                    ? <UserSignup nextStep={nextStep} setUserType={props.setUserType} />
                                    : (step === 2)
                                        ? <BusinessSignup nextStep={nextStep} prevStep={() => setStep(step - 1)} name={userData.firstName} />
                                        : <BusinessAddressSignup nextStep={nextStep} prevStep={() => setStep(step - 1)} name={userData.firstName} />
                        }
                    </div>
                </div>
                {/* SIDEBAR */}
                <div className="hidden relative sm:inline-flex h-full w-[33%] bg-gradient-to-br from-mi-blue to-mi-green overflow-hidden">
                    <img src={coffeeTable} alt="Login Image1" className=" scale-125 w-full" />
                </div>
            </div>
        </>
    );
}
export default BusinessSignupFlow;