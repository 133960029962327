import React, { useState, useEffect, Fragment, forwardRef, useRef } from "react";
import { auth } from "../../firebase";
import { isoToDate } from "../../dateUtils";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import Modal from "../Modal";
import TWButton from "../TWButton";
import { useMediaQuery } from "react-responsive";
import { Checkbox } from "@mui/material";
import DatePicker from "react-datepicker";
import ScheduleForm from "./ScheduleTime";
import CloseBlackButton from "../../assets/images/black_close_button.svg";
import CalendarComponentBusinessExtension from "./CalendarBusinessExtension";
import CloseBlueButton from "../../assets/images/blue_close_button.svg";

const ExtendModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [currContract, setCurrContract] = useState(false);

    const [dateSpecificHours, setDateSpecificHours] = useState({});
    const [oldSelectedDates, setOldSelectedDates] = useState(props.selectedDates);
    const [newSelectedDates, setNewSelectedDates] = useState([]);
    const [dailyLimit, setDailyLimit] = useState(props.dailyLimit);

    const csrf_token = Cookies.get("csrftoken");

    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

    const [dateRange, setDateRange] = useState([new Date(props.startDate), new Date(props.endDate)]);
    const [oldUnavailableDates, setOldUnavailableDates] = useState(props.unavailableDates);
    const [newUnavailableDates, setNewUnavailableDates] = useState([]);

    // Steps states
    const [currentStep, setCurrentStep] = useState(2); // Start from step 2 since the user requested steps two to five
    const totalSteps = 5;

    const goForward = () => setCurrentStep((prev) => Math.min(prev + 1, totalSteps));
    const goBackward = () => setCurrentStep((prev) => Math.max(prev - 1, 2));

    const isUnavailableDate = (date) =>
        newUnavailableDates.some(unavailableDate =>
            date.getFullYear() === unavailableDate.getFullYear() &&
            date.getMonth() === unavailableDate.getMonth() &&
            date.getDate() === unavailableDate.getDate()
        );

    const removeNewUnavailableDate = (dateToRemove) => {
        setNewUnavailableDates(currentDates =>
            currentDates.filter(date =>
                date.getTime() !== dateToRemove.getTime()
            )
        );
    };

    const calculateUnavailableDates = (startDate, endDate, dayAvailability) => {
        const oldUnavailableDates = [];
        const currentDate = new Date(startDate);

        while (currentDate < endDate) {
            const dayOfWeek = currentDate.toLocaleDateString("en-US", { weekday: "short" });
            if (!dayAvailability[dayOfWeek]) {
                oldUnavailableDates.push(new Date(currentDate)); // Add a copy of the date
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return oldUnavailableDates;
    };

    // Initialize newUnavailableDates based on the initial end date and day availability
    useEffect(() => {
        const initialUnavailableDates = calculateUnavailableDates(
            new Date(props.endDate),
            new Date(props.endDate),
            dayAvailability
        );
        setNewUnavailableDates(initialUnavailableDates);
    }, [props.endDate]);

    // Update newUnavailableDates when the end date changes
    useEffect(() => {
        if (dateRange[1]) { // Ensure the end date is selected
            const updatedUnavailableDates = calculateUnavailableDates(
                new Date(props.endDate), // Start from the original end date
                new Date(dateRange[1]),  // Use the new end date
                dayAvailability
            );
            setNewUnavailableDates(updatedUnavailableDates);
        }
    }, [dateRange]);


    const onDateChange = (date) => {
        if (currentStep === 2) {
            const currentTime = new Date();
            if (Array.isArray(date) && date.length === 2) {
                const updatedDates = date.map((d) => {
                    if (d !== null) {
                        const newDate = new Date(d);
                        newDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
                        return newDate;
                    }
                    return null;
                });
                setDateRange(updatedDates);
            } else {
                setDateRange(date);
            }
        }

        const selectedDateObj = Array.isArray(date) ? date[0] : date;

        if (!(selectedDateObj instanceof Date)) return; // Exit if invalid date
        const dateString = selectedDateObj.toLocaleDateString("en-US");
        const dayOfWeek = selectedDateObj.toLocaleDateString("en-US", { weekday: "short" });

        if (currentStep === 4) {
            if (selectedDateObj > dateRange[1]) {
                // Exit early if the selected date is out of range
                return;
            }

            // Step 4: Toggle date in newSelectedDates and remove from newUnavailableDates if needed
            setNewSelectedDates((prevSelectedDates) => {
                const isAlreadySelected = prevSelectedDates.includes(dateString);

                if (isAlreadySelected) {
                    // Remove time slots for the date if toggled off
                    setDateSpecificHours((prevHours) => {
                        const updatedHours = { ...prevHours };
                        delete updatedHours[dateString];
                        return updatedHours;
                    });

                    // Check weekly availability and add to newUnavailableDates if day is disabled
                    setNewUnavailableDates((prevUnavailableDates) => {
                        if (!dayAvailability[dayOfWeek]) {
                            return [...prevUnavailableDates, selectedDateObj];
                        }
                        return prevUnavailableDates;
                    });

                    return prevSelectedDates.filter((d) => d !== dateString);
                } else {
                    // Add to newSelectedDates and remove from newUnavailableDates
                    setNewUnavailableDates((prevUnavailableDates) =>
                        prevUnavailableDates.filter(
                            (unavailableDate) =>
                                new Date(unavailableDate).toLocaleDateString("en-US") !== dateString
                        )
                    );
                    return [...prevSelectedDates, dateString];
                }
            });
        }
    }


    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    }

    const handleDayAvailabilityChange = (updatedAvailability) => {
        setDayAvailability(updatedAvailability);

        // Find which days were toggled on/off
        const toggledDays = Object.keys(updatedAvailability).filter(
            (day) => updatedAvailability[day] !== prevDayAvailability.current[day]
        );

        setOldUnavailableDates((prevUnavailableDates) => {
            const updatedUnavailable = [...prevUnavailableDates];
            const newUnavailableDates = [];

            allDatesInRange(dateRange[0], dateRange[1]).forEach((date) => {
                const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });

                if (toggledDays.includes(dayName)) {
                    if (!updatedAvailability[dayName]) {
                        // If day is toggled OFF, add it to oldUnavailableDates
                        newUnavailableDates.push(new Date(date));
                    } else {
                        // If day is toggled ON, remove it from oldUnavailableDates
                        const index = updatedUnavailable.findIndex(
                            (unavailableDate) =>
                                unavailableDate.toDateString() === date.toDateString()
                        );
                        if (index > -1) updatedUnavailable.splice(index, 1);
                    }
                }
            });

            return [...new Set([...updatedUnavailable, ...newUnavailableDates])]; // Remove duplicates
        });

        setDateSpecificHours((prev) => {
            const updatedHours = { ...prev };

            // Remove date-specific hours for dates matching the toggled day if the day is toggled ON
            allDatesInRange(dateRange[0], dateRange[1]).forEach((date) => {
                const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
                if (toggledDays.includes(dayName) && updatedAvailability[dayName]) {
                    delete updatedHours[date.toDateString()];
                }
            });

            return updatedHours;
        });

        setOldSelectedDates((prevSelectedDates) => {
            // Remove selected dates matching the toggled day if the day is toggled ON
            return prevSelectedDates.filter((date) => {
                const dayName = new Date(date).toLocaleDateString('en-US', { weekday: 'short' });
                return !(toggledDays.includes(dayName) && updatedAvailability[dayName]);
            });
        });

        // Update the reference for the next render
        prevDayAvailability.current = updatedAvailability;
    };

    // Utility function to get all dates in range
    const allDatesInRange = (startDate, endDate) => {
        const dates = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const [dayAvailability, setDayAvailability] = useState(
        props.dayAvailability || {
            Sun: true,
            Mon: true,
            Tue: true,
            Wed: true,
            Thu: true,
            Fri: true,
            Sat: true,
        }
    );
    const [timeAvailability, setTimeAvailability] = useState(
        props.businessHours || {
            Sun: [{ start: "09:00", end: "17:00" }],
            Mon: [{ start: "09:00", end: "17:00" }],
            Tue: [{ start: "09:00", end: "17:00" }],
            Wed: [{ start: "09:00", end: "17:00" }],
            Thu: [{ start: "09:00", end: "17:00" }],
            Fri: [{ start: "09:00", end: "17:00" }],
            Sat: [{ start: "09:00", end: "17:00" }],
        }
    );

    const handleScheduleUpdate = (updatedSchedule) => {
        const newTimeAvailability = {};
        Object.entries(updatedSchedule).forEach(([day, { enabled, timeSlots }]) => {
            newTimeAvailability[day] = timeSlots || [{ start: "09:00", end: "17:00" }];
        });
        setTimeAvailability(newTimeAvailability);
    };

    const prevDayAvailability = useRef(dayAvailability);

    useEffect(() => {
        if (dateRange.length === 2 && dateRange[0] && dateRange[1]) {
            const startDate = new Date(dateRange[0]);
            const endDate = new Date(dateRange[1]);

            let allDatesInRange = [];
            for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                allDatesInRange.push(new Date(d.getTime()));
            }

            const changes = {};
            Object.keys(dayAvailability).forEach(day => {
                if (dayAvailability[day] !== prevDayAvailability.current[day]) {
                    changes[day] = dayAvailability[day];
                }
            });

            if (Object.keys(changes).length > 0) {
                updateUnavailableDatesBasedOnChanges(changes, allDatesInRange);
            }

            prevDayAvailability.current = dayAvailability;
        }
    }, [dayAvailability, dateRange]);

    const updateUnavailableDatesBasedOnChanges = (changes, allDatesInRange) => {
        setOldUnavailableDates(current => {
            const newUnavailableDates = allDatesInRange.filter(date => {
                const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
                return changes[dayName] === false;
            });

            const datesToRemove = allDatesInRange.filter(date => {
                const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
                return changes[dayName] === true;
            });

            const updatedDates = current.filter(date =>
                !datesToRemove.some(d =>
                    d.getDate() === date.getDate() &&
                    d.getMonth() === date.getMonth() &&
                    d.getFullYear() === date.getFullYear()
                )
            );

            return [...new Set([...updatedDates, ...newUnavailableDates])];
        });
    };

    const formComplete = () => {
        return currContract;
    };

    const handleCheckboxChange = () => {
        setCurrContract(!currContract);
    };

    // const handleCheckboxChange = () => {
    //     if (!currContract) {
    //         Swal.fire({
    //             icon: "warning",
    //             title: "Confirm Action",
    //             text: "Are you sure you want to extend the existing contracts' end date associated with this campaign? Ensure that you review all associated settings before proceeding.",
    //             confirmButtonText: "Yes, I understand",
    //             cancelButtonText: "Cancel",
    //             showCancelButton: true,
    //             confirmButtonColor: "#7BBA83",
    //         }).then((result) => {
    //             if (result.isConfirmed) {
    //                 setCurrContract(!currContract);
    //             }
    //         });
    //     } else {
    //         // Simply toggle off without confirmation
    //         setCurrContract(!currContract);
    //     }
    // };    

    const getHeaderText = (step) => {
        switch (step) {
            case 2:
                return "Extend Campaign: Scheduling Settings";
            case 3:
                return "Extend Campaign: Weekly Visiting Hours";
            case 4:
                return "Extend Campaign: Date-Specific Visiting Hours";
            case 5:
                return "Extend Campaign: Visit Constraints";
            default:
                return "Extend Campaign";
        }
    };

    // Validation and final submission (extending the campaign)
    const extendCampaign = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/extend_campaign`;
        const token = await auth.currentUser.getIdToken();

        // const sortedNewSelectedDates = [...newSelectedDates].sort((a, b) => new Date(a) - new Date(b));
        const sortedNewUnavailableDates = [...newUnavailableDates].sort((a, b) => new Date(a) - new Date(b));

        const getFormattedUnavailableDates = () => {
            const combined = [...oldUnavailableDates, ...sortedNewUnavailableDates];
            const sortedUniqueDates = [...new Set(combined)].sort((a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                return dateA.getTime() - dateB.getTime();
            });

            const isoFormattedDates = sortedUniqueDates.map(dateString => {
                const date = new Date(dateString);
                return date.toISOString(); // Use toISOString() for consistent ISO format
            });
            return isoFormattedDates
        };

        // Generate the formatted unavailable dates list
        const unavailableDateList = getFormattedUnavailableDates();

        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                campaignID: props.campaignID,
                newDate: dateRange[1] ? dateRange[1].toISOString() : new Date(props.endDate).toISOString(),
                currContract: currContract,
                dayList: dayAvailability,
                busHrList: timeAvailability,
                dailyLimit: dailyLimit,
                dateSpecificHours: dateSpecificHours,
                unavaliableDateList: unavailableDateList,
                // Add any additional fields if required
            }),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                setLoading(false);
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.alert || "Something went wrong!",
                        footer: "Please try again later.",
                    });
                } else {
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: result.alert || "Campaign extended successfully!",
                    }).then(() => window.location.reload());
                }
            });
    };

    // Validation helpers from createCampaignModal logic
    const validateTimeAvailability = () => {
        const missingTimeSlots = Object.keys(dayAvailability).filter(
            (day) => dayAvailability[day] && (!timeAvailability[day] || timeAvailability[day].length === 0)
        );

        if (missingTimeSlots.length > 0) {
            Swal.fire({
                icon: "warning",
                title: "Missing Time Slots",
                text: `Please add at least one time slot for the following days: ${missingTimeSlots.join(", ")}`,
                confirmButtonColor: "#7BBA83",
            });
            return false;
        }
        return true;
    };

    const hasOverlappingTimeSlots = (timeSlots) => {
        for (let i = 0; i < timeSlots.length; i++) {
            for (let j = i + 1; j < timeSlots.length; j++) {
                const [start1, end1] = [
                    new Date(`1970-01-01T${timeSlots[i].start}:00`),
                    new Date(`1970-01-01T${timeSlots[i].end}:00`),
                ];
                const [start2, end2] = [
                    new Date(`1970-01-01T${timeSlots[j].start}:00`),
                    new Date(`1970-01-01T${timeSlots[j].end}:00`),
                ];

                if (start1 < end2 && start2 < end1) {
                    return true;
                }
            }
        }
        return false;
    };

    const validateWeeklyHoursNoOverlap = () => {
        const overlappingDays = [];
        for (const [day, timeSlots] of Object.entries(timeAvailability)) {
            if (dayAvailability[day] && timeSlots && hasOverlappingTimeSlots(timeSlots)) {
                overlappingDays.push(day);
            }
        }

        if (overlappingDays.length > 0) {
            Swal.fire({
                icon: "error",
                title: "Overlapping Time Slots",
                text: `Overlapping time slots detected for the following days: ${overlappingDays.join(", ")}.`,
                confirmButtonColor: "#7BBA83",
            });
            return false;
        }

        return true;
    };

    function StepTwo() {
        const handleDateChangeLocal = (date) => {
            if (date) {
                // Ensure date is after or equal to the original end date
                if (date >= new Date(props.endDate)) {
                    setDateRange([dateRange[0], date]);
                }
            } else {
                // Reset to original end date when cleared
                setDateRange([dateRange[0], new Date(props.endDate)]);
            }
        };

        const DatePickerCustomInput = React.forwardRef(({ value, onClick }, ref) => (
            <button
                className="w-40 rounded-full py-1 bg-gray-200 text-gray-700 shadow-lg"
                onClick={onClick}
                ref={ref}
            >
                {value || 'Select Date'}
            </button>
        ));

        const isCurrentEndDate = dateRange[1]?.toDateString() === new Date(props.endDate).toDateString();

        return (
            <div className="w-min">
                <div className="text-neutral-600 text-base font-semibold font-poppins pb-8">Scheduling Setting</div>
                <div className="text-neutral-600 text-sm font-semibold font-poppins pb-2">Date Range</div>

                {/* Start Date (Fixed, Disabled) */}
                <div className="text-gray-600 py-4">Start Date:</div>
                <DatePicker
                    selected={dateRange[0]}
                    onChange={() => { }}
                    disabled
                    customInput={<DatePickerCustomInput inputName="start" />}
                />

                {/* End Date */}
                <div className="text-gray-600 py-4">New End Date:</div>
                <DatePicker
                    minDate={new Date(props.endDate)} // Prevent selecting before the original end date
                    selected={dateRange[1]} // Currently selected end date
                    onChange={(date) => handleDateChangeLocal(date)} // Handle date change
                    isClearable={!isCurrentEndDate} // Hide clearable button if on current end date
                    disabledKeyboardNavigation
                    customInput={<DatePickerCustomInput inputName="end" />}
                />
            </div>
        );
    }


    function StepThree() {
        return (
            <div className="w-min">
                <div className="text-neutral-600 text-base font-semibold font-poppins pb-4">Weekly Visiting Hours</div>
                <div className="text-neutral-600 text-sm font-semibold font-poppins pb-6 w-[90%]">
                    Set your weekly hours for regular availability.
                </div>

                <div className="text-gray-600 pb-2">Weekly Hours</div>
                <div className="bg-white rounded-xl shadow-custom w-min">
                    <ScheduleForm
                        updateDayAvailability={handleDayAvailabilityChange}
                        dayAvailability={dayAvailability}
                        updateSchedule={handleScheduleUpdate}
                        timeAvailability={timeAvailability}
                        extendModal={true}
                    />
                </div>
            </div>
        );
    }

    function StepFour() {
        const handleHoursChange = (date, index, field, value) => {
            setDateSpecificHours((prev) => {
                const updatedRanges = prev[date].map((range, i) => {
                    if (i === index) {
                        const newRange = { ...range, [field]: value };

                        // Enforce a minimum 1-hour range
                        const start = new Date(`1970-01-01T${newRange.start}:00`);
                        const end = new Date(`1970-01-01T${newRange.end}:00`);

                        if (field === "start" && end - start < 60 * 60 * 1000) {
                            newRange.end = new Date(start.getTime() + 60 * 60 * 1000).toTimeString().slice(0, 5);
                        } else if (field === "end" && end - start < 60 * 60 * 1000) {
                            newRange.start = new Date(end.getTime() - 60 * 60 * 1000).toTimeString().slice(0, 5);
                        }

                        return newRange;
                    }
                    return range;
                });

                return { ...prev, [date]: updatedRanges };
            });
        };

        const handleAddTimeRange = (date) => {
            setDateSpecificHours((prev) => {
                const existingRanges = prev[date] || [];
                const lastEnd = existingRanges.length > 0 ? existingRanges[existingRanges.length - 1].end : "09:00";

                const [hours, minutes] = lastEnd.split(":").map(Number);
                const newStartTime = new Date(1970, 0, 1, hours, minutes + 60);
                const newStart = newStartTime.toTimeString().slice(0, 5);
                const newEnd = new Date(newStartTime.getTime() + 60 * 60 * 1000).toTimeString().slice(0, 5);

                return {
                    ...prev,
                    [date]: [...existingRanges, { start: newStart, end: newEnd }],
                };
            });
        };

        const handleRemoveTimeRange = (date, index) => {
            setDateSpecificHours((prev) => ({
                ...prev,
                [date]: prev[date].filter((_, i) => i !== index),
            }));
        };

        const handleToggleDateAvailability = (date) => {
            const dateString = isoToDate(date, "date"); // Converts to "MM/DD/YYYY"
            const dayOfWeek = new Date(date).toLocaleDateString("en-US", { weekday: "short" });

            let wasUnavailable = false;

            setOldUnavailableDates((prevUnavailableDates) => {
                const updatedUnavailable = prevUnavailableDates.filter((unavailableDate) => {
                    const isSameDate =
                        new Date(unavailableDate).toLocaleDateString("en-US") === dateString;
                    if (isSameDate) wasUnavailable = true;
                    return !isSameDate;
                });
                return updatedUnavailable;
            });

            setNewSelectedDates((prevSelectedDates) => {
                const updatedSelectedDates = prevSelectedDates.filter((d) => d !== dateString);
                return [...updatedSelectedDates];
            });

            setDateSpecificHours((prev) => {
                const updatedHours = { ...prev };
                delete updatedHours[dateString];
                return updatedHours;
            });

            if (!dayAvailability[dayOfWeek] && !wasUnavailable) {
                setOldUnavailableDates((prevUnavailableDates) => [
                    ...prevUnavailableDates,
                    new Date(date),
                ]);
            }
        };

        return (
            <div className="w-full md:w-min min-w-48 px-4 md:px-0">
                <div className="text-neutral-600 text-lg md:text-base font-semibold font-poppins pb-4">Date-Specific Visiting Hours</div>
                <div className="text-neutral-600 text-sm font-semibold font-poppins pb-6 w-full md:w-[90%]">
                    Set specific hours for individual dates when your schedule differs from your regular weekly hours.
                </div>

                <div className="mt-6 p-4 border rounded-md bg-gray-50">
                    <h4 className="font-semibold text-gray-700 mb-2 text-base md:text-md">Date-Specific Hours</h4>
                    <p className="text-sm md:text-base text-neutral-600 mb-4">
                        Override your availability for specific dates.
                    </p>

                    {newSelectedDates
                        .sort((a, b) => new Date(a) - new Date(b))
                        .map((date) => (
                            <div key={date.toString()} className="mb-4">
                                <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                                    <span className="text-sm font-medium text-gray-600">{date.toString()}</span>
                                    <div className="flex items-center space-x-2 mt-2 md:mt-0">
                                        {(dateSpecificHours[date]?.length || 0) < 3 && (
                                            <button
                                                onClick={() => handleAddTimeRange(date)}
                                                className="text-blue-600 text-sm md:text-base"
                                            >
                                                + Add Time Range
                                            </button>
                                        )}
                                        <button
                                            onClick={() => handleToggleDateAvailability(date)}
                                            className="text-gray-400 hover:text-red-600 text-sm md:text-base"
                                        >
                                            ✕
                                        </button>
                                    </div>
                                </div>

                                {(dateSpecificHours[date] || []).map((range, index) => (
                                    <div key={`${date}-${index}`} className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2 mt-2 mr-4">
                                        <input
                                            type="time"
                                            value={range.start}
                                            onChange={(e) => handleHoursChange(date, index, "start", e.target.value)}
                                            className="border rounded-md p-2 text-gray-600 text-sm w-full md:w-auto"
                                        />
                                        <span>to</span>
                                        <input
                                            type="time"
                                            value={range.end}
                                            onChange={(e) => handleHoursChange(date, index, "end", e.target.value)}
                                            className="border rounded-md p-2 text-gray-600 text-sm w-full md:w-auto"
                                        />
                                        <button
                                            onClick={() => handleRemoveTimeRange(date, index)}
                                            className="text-gray-400 hover:text-red-600 text-sm md:text-base mt-2 md:mt-0"
                                        >
                                            ✕
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ))}
                </div>
            </div>
        );
    }

    function StepFive() {
        return (
            <div>
                <h2 className="text-base font-semibold text-neutral-600 pb-8 font-poppins">Visit Constraints</h2>

                <div className="mb-4">
                    <label className="text-sm font-semibold text-neutral-600 font-poppins block">Daily Limit</label>
                    <div className="flex items-center">
                        <input
                            type="number"
                            className="w-min rounded-xl p-1 shadow-lg bg-gray-200 outline-none text-gray-700 mr-2"
                            value={dailyLimit}
                            disabled
                        />
                        <span className="text-gray-600">visits max per day</span>
                    </div>
                </div>

                <div>
                    <label className="text-sm font-semibold text-neutral-600 font-poppins block">Unavailable Dates</label>
                    <div className="text-gray-600 py-2">Click on a date to remove it from the list of unavailable dates.</div>
                    <div style={{ maxHeight: '280px', overflowY: 'auto' }}>
                        <ul className="list-none pl-0">
                            {newUnavailableDates.sort((a, b) => a - b).map((date, index) => (
                                <li key={index} className="mb-2 flex items-center">
                                    <span className="w-24">{date.toLocaleDateString('en-US')}</span>
                                    <button
                                        onClick={() => removeNewUnavailableDate(date)}
                                        className="flex-shrink-0"
                                    >
                                        <img src={CloseBlueButton} alt="Remove" className="w-4 h-4" />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Modal setShowModal={props.setShowModal}>
                <div className="w-[95%] ml-4 mr-4">
                    <div className="flex flex-row mt-6 mb-4 items-center w-full border-b border-solid border-slate-200">
                        <h2 className="flex-auto mb-4">
                            {getHeaderText(currentStep)}
                        </h2>
                        <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mx-3 ml-auto inline-flex items-center fixed top-5 right-2">
                            <img src={CloseBlackButton} onClick={() => props.setShowModal(false)} alt="Close" className="w-6 h-6" />
                        </button>
                    </div>

                    <div className="flex flex-col md:flex-row gap-12 mb-8">
                        <CalendarComponentBusinessExtension
                            onDateChange={onDateChange}
                            isSameDay={isSameDay}
                            isUnavailableDate={isUnavailableDate}
                            setNewUnavailableDates={setNewUnavailableDates}
                            dateRange={dateRange}
                            currentStep={currentStep}
                            minEndDate={new Date(props.endDate)}
                            oldSelectedDates={oldSelectedDates}
                            newSelectedDates={newSelectedDates}
                            oldUnavailableDates={oldUnavailableDates}
                            newUnavailableDates={newUnavailableDates}
                            dayAvailability={dayAvailability}
                            extendModal={true}
                            originalStartDate={new Date(props.startDate)}
                            originalEndDate={new Date(props.endDate)}
                            newEndDate={dateRange[1]}
                        />


                        {currentStep === 2 && <StepTwo />}
                        {currentStep === 3 && <StepThree />}
                        {currentStep === 4 && <StepFour />}
                        {currentStep === 5 && <StepFive />}
                    </div>

                    <div className="w-full flex flex-row items-center mt-4 mb-4">
                        <Checkbox
                            checked={currContract}
                            onChange={handleCheckboxChange}
                            className="p-0 pt-0.5 text-gray-400 mr-1"
                        />
                        <p className="text-sm font-bold text-neutral-800">Extend existing contracts' end date associated with this campaign</p>
                    </div>

                    {/* Footer */}
                    <div className="flex flex-row gap-2 mb-4 mr-4 items-center md:justify-end w-full pt-3 border-t border-solid border-slate-200">
                        <div className="flex-auto md:grow-0">
                            {currentStep !== 2 && (
                                <TWButton md onClick={goBackward} disabled={currentStep === 2}>
                                    Previous
                                </TWButton>
                            )}
                        </div>
                        <div className="flex-auto md:grow-0">
                            {currentStep === totalSteps ? (
                                <TWButton
                                    md
                                    loading={loading}
                                    disabled={!formComplete() || loading}
                                    onClick={() => {
                                        const sortedNewSelectedDates = [...newSelectedDates].sort((a, b) => new Date(a) - new Date(b));

                                        // Validate weekly schedule setup
                                        if (!validateTimeAvailability()) {
                                            Swal.fire({
                                                icon: "error",
                                                title: "Weekly Hours Required",
                                                text: "Please ensure weekly hours are set for each enabled day before submitting.",
                                                confirmButtonColor: "#7BBA83",
                                            });
                                            return;
                                        }

                                        // Check if new selected dates overlap with new unavailable dates
                                        const unavailableDatesSelected = sortedNewSelectedDates.some(date =>
                                            newUnavailableDates.some(unavailableDate =>
                                                new Date(date).toDateString() === new Date(unavailableDate).toDateString()
                                            )
                                        );

                                        if (unavailableDatesSelected) {
                                            Swal.fire({
                                                icon: "error",
                                                title: "Unavailable Date Selected",
                                                text: "One or more time-specific dates are marked as unavailable. Please enable the dates or remove them from the schedule before submitting.",
                                                confirmButtonColor: "#7BBA83",
                                            });
                                            return;
                                        }

                                        // Validate date-specific time slots
                                        const missingTimeSlots = sortedNewSelectedDates.some(date =>
                                            !dateSpecificHours[date] || dateSpecificHours[date].length === 0
                                        );

                                        if (missingTimeSlots) {
                                            Swal.fire({
                                                icon: "error",
                                                title: "Time Slots Required",
                                                text: "Please ensure that all selected dates have at least one time slot before submitting.",
                                                confirmButtonColor: "#7BBA83",
                                            });
                                            return;
                                        }

                                        // If all validations pass, call extendCampaign
                                        setLoading(true);
                                        extendCampaign();
                                    }}
                                >
                                    Submit
                                </TWButton>
                            ) : (
                                <TWButton
                                    md
                                    onClick={() => {
                                        const sortedNewSelectedDates = [...newSelectedDates].sort((a, b) => new Date(a) - new Date(b));

                                        if (currentStep === 2) {
                                            // Validate date range
                                            if (!dateRange || dateRange.length !== 2 || !dateRange[0] || !dateRange[1]) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Date Range Required",
                                                    text: "Please select a valid date range before proceeding to the next step.",
                                                    confirmButtonColor: "#7BBA83",
                                                });
                                                return;
                                            }
                                        } else if (currentStep === 3) {
                                            // Validate weekly hours for overlaps
                                            if (!validateWeeklyHoursNoOverlap()) {
                                                return;
                                            }
                                        } else if (currentStep === 4) {
                                            // Validate date-specific time slots
                                            const missingTimeSlots = sortedNewSelectedDates.some(date =>
                                                !dateSpecificHours[date] || dateSpecificHours[date].length === 0
                                            );

                                            if (missingTimeSlots) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Time Slots Required",
                                                    text: "Please ensure that all selected dates have at least one time slot before proceeding.",
                                                    confirmButtonColor: "#7BBA83",
                                                });
                                                return;
                                            }

                                            // Check if new selected dates are marked unavailable
                                            const unavailableDatesSelected = sortedNewSelectedDates.some(date =>
                                                newUnavailableDates.some(unavailableDate =>
                                                    new Date(date).toDateString() === new Date(unavailableDate).toDateString()
                                                )
                                            );

                                            if (unavailableDatesSelected) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Unavailable Date Selected",
                                                    text: "You have selected unavailable dates. Please enable or remove them before proceeding.",
                                                    confirmButtonColor: "#7BBA83",
                                                });
                                                return;
                                            }
                                        }

                                        // Proceed to the next step
                                        goForward();
                                    }}
                                    disabled={currentStep === totalSteps}
                                >
                                    Next Step
                                </TWButton>
                            )}
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    );
};

export default ExtendModal;
