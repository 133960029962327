import Spinner from "./Spinner";

const TWButton = (props) => {
    const executeOnClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    }
    return (
        <>
            {props.delete
                ? <button disabled={props.disabled} onClick={executeOnClick}
                    className={`
                w-fit
                rounded-full 
                font-medium 
                ${(props.sm)
                            ? "text-sm"
                            : (props.lg)
                                ? "text-lg"
                                : (props.xl)
                                    ? "text-xl"
                                    : (props.twoxl)
                                        ? "text-2xl"
                                        : "text-base"
                        }
                text-white 
                bg-red-600
                hover:bg-red-700
                disabled:opacity-50
                disabled:cursor-not-allowed
                font-poppins
                py-2 
                px-5
                shadow-2xl`}>
                    {
                        props.loading
                            ? <Spinner />
                            : <div className="mx-auto">
                                {props.children}
                            </div>
                    }
                </button>
                : (props.secondary)
                    ? <button disabled={props.disabled} onClick={executeOnClick}
                        className={`
                        font-medium
                        ${(props.sm)
                                ? "text-sm"
                                : (props.lg)
                                    ? "text-lg"
                                    : (props.xl)
                                        ? "text-xl"
                                        : (props.twoxl)
                                            ? "text-2xl"
                                            : "text-base"
                            }
                            disabled:opacity-50
                            disabled:cursor-not-allowed`}>
                        <div className="underline text-mi-green">
                            {props.children}
                        </div>
                    </button>
                    : (props.green)
                        ? <button disabled={props.disabled} onClick={executeOnClick}
                            className={`
                        bg-mi-green
                        hover:bg-green-600
                        duration-300
                        hover:inner-shadow-sm
                        text-white 
                        font-medium
                        font-poppins
                        ${(props.sm)
                                    ? "text-sm"
                                    : (props.lg)
                                        ? "text-lg"
                                        : (props.xl)
                                            ? "text-xl"
                                            : (props.twoxl)
                                                ? "text-2xl"
                                                : "text-base"
                                }
                        rounded-full 
                        w-fit
                        px-5
                        py-2 
                        shadow-all-xl
                        disabled:opacity-50
                        disabled:cursor-not-allowed`}>
                            <div className="w-full flex flex-row items-center">
                                {
                                    props.loading
                                        ? <Spinner />
                                        : <div className="mx-auto">
                                            {props.children}
                                        </div>
                                }
                            </div>
                        </button>
                        : <button disabled={props.disabled} onClick={executeOnClick}
                            className={`
                        bg-mi-blue
                        hover:bg-slate-600
                        duration-300
                        hover:inner-shadow-sm
                        text-white 
                        font-medium
                        font-poppins
                        ${(props.xs)
                                    ? "text-xs"
                                    : (props.sm)
                                        ? "text-sm"
                                        : (props.lg)
                                            ? "text-lg"
                                            : (props.xl)
                                                ? "text-xl"
                                                : (props.twoxl)
                                                    ? "text-2xl"
                                                    : "text-base"
                                }
                            rounded-full 
                            ${(props.full) ?
                                    "w-full"
                                    : "w-fit"
                                }
                            px-5
                            py-2 
                            shadow-all-xl
                            disabled:opacity-50
                            disabled:cursor-not-allowed`}>
                            <div className="w-full flex flex-row items-center">
                                {
                                    props.loading
                                        ? <Spinner />
                                        : <div className="mx-auto">
                                            {props.children}
                                        </div>
                                }
                            </div>
                        </button>
            }
        </>
    );

}
export default TWButton;