import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import TopBar from "./TopBar";
import Spinner from "./Spinner";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import getTitleFromPath from "../documentTitleUtil";
import { SidebarProvider } from "./SidebarContext";

const PageWrapper = (props) => {
    const [user, loading, error] = useAuthState(auth);
    const [pageLoading, setPageLoading] = useState(true);

    const [accountType, setAccountType] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const csrf_token = Cookies.get("csrftoken");

    // check authentication
    useEffect(() => {
        // authentication loading
        if (loading) {
            return;
        }
        // user is logged into an account => set timezone & check account status
        if (user) {
            const checkAuth = async () => {
                const token = await user.getIdToken();
                const url = `${process.env.REACT_APP_DJANGO_API}/check_user`;
                const requestMetaData = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf_token,
                        "Authorization": `Bearer ${token}`,
                    },
                };
                fetch(url, requestMetaData)
                    .then(res => res.json())
                    .then((result) => {
                        if (!result || !result.success) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                confirmButtonColor: "#7BBA83",
                                footer: "Please try again later.",
                            });
                        } else {
                            const signupStep = result.data.step;
                            const accountType = result.data.user_type;
                            setAccountType(accountType);
                            props.setAccountType(accountType);
                            if (!accountType || (signupStep !== 4 && accountType === "Influencer") || (signupStep !== 5 && (accountType === "Business" || accountType === "Agency"))) { navigate("/signup"); }
                            else if (!result.data.access) { navigate("/thankyou"); }
                            else if (accountType === "Influencer" && !result.data.social_setup) {
                                navigate("/link");
                            }
                            else if (accountType == "Business" && !result.data.valid_zip) {
                                navigate("/zipcode");
                            }
                            setPageLoading(false);
                        }
                    });
            }
            checkAuth();
            // no user logged in => redirect to signin screen
        } else {
            navigate("/signin");
        }
    }, [user, loading, error]);

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);
    const specialSidebarPages = ["/explore", "/contracts", "/chat", "/campaigns"];
    const isLocationChat = (window.location.pathname.startsWith("/chat"));

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 640);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isSpecialSidebarPage = specialSidebarPages.some(path => location.pathname.startsWith(path)) || (location.pathname === "/home" && (accountType === "Business" || accountType === "Agency"));

    return (
        <>
            <SidebarProvider>
                <Helmet>
                    <title>{getTitleFromPath(location)}</title>
                </Helmet>
                {pageLoading
                    ? <div className="w-full h-screen flex justify-center items-center">
                        <Spinner />
                    </div>
                    : <>
                        {isSmallScreen
                            ? (
                                <div className="w-full h-lvh flex flex-col">
                                    {!isLocationChat && <TopBar accountType={accountType} />}
                                    <div className="overflow-auto h-svh">
                                        {props.children}
                                    </div>
                                </div>
                            )
                            : (
                                isSpecialSidebarPage
                                    ? (
                                        <div className="w-full flex flex-col">
                                            <div className="overflow-auto">
                                                {props.children}
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <div className="w-full flex flex-col">
                                            <TopBar accountType={accountType} />
                                            <div className="overflow-auto">
                                                {props.children}
                                            </div>
                                        </div>
                                    )
                            )
                        }
                    </>
                }
            </SidebarProvider>
        </>

    );
};

export default PageWrapper;
