import React, { useState } from 'react';
import Calendar from 'react-calendar';
import './custom-calendar.css';
import Swal from "sweetalert2";

const CalendarComponentBusiness = ({ dateRange, onDateChange, isSameDay, isUnavailableDate, selectedDates, unavailableDates, setUnavailableDates, currentStep, minEndDate, dayAvailability }) => {

    const onClickDay = (day) => {
        const dateString = day.toLocaleDateString("en-US"); // Normalize date string for comparison
        const clickedDate = new Date(day.getFullYear(), day.getMonth(), day.getDate());
        const dayOfWeek = clickedDate.toLocaleDateString("en-US", { weekday: "short" }); // e.g., "Mon"

        if (currentStep > 4) {
            // Step 5: Check if the clicked date is part of selectedDates
            if (selectedDates.includes(dateString)) {
                Swal.fire({
                    icon: "warning",
                    title: "Date-Specific Date",
                    text: `The date ${dateString} is set as a date-specific date. Please remove it from the date-specific list before marking it as unavailable.`,
                    confirmButtonColor: "#7BBA83",
                });
                return; // Exit early to prevent toggling
            }

            // Check if the date's weekday is disabled in weekly hours (dayAvailability)
            if (!dayAvailability[dayOfWeek]) {
                Swal.fire({
                    icon: "info",
                    title: "Weekly Hour Restriction",
                    text: `The date ${dateString} is unavailable based on the weekly visiting hours. To enable it, adjust your weekly hours or set it as a date-specific date.`,
                    confirmButtonColor: "#7BBA83",
                });
                return; // Exit early to respect weekly hours
            }

            // Toggle the date in unavailableDates
            setUnavailableDates((currentUnavailableDates) => {
                // Check if the date is already in unavailableDates
                const dateIndex = currentUnavailableDates.findIndex(
                    (unavailableDate) =>
                        unavailableDate.getFullYear() === clickedDate.getFullYear() &&
                        unavailableDate.getMonth() === clickedDate.getMonth() &&
                        unavailableDate.getDate() === clickedDate.getDate()
                );

                if (dateIndex === -1) {
                    // If the date is not in the array, add it
                    return [...currentUnavailableDates, clickedDate];
                } else {
                    // If the date is in the array, remove it
                    const newUnavailableDates = [...currentUnavailableDates];
                    newUnavailableDates.splice(dateIndex, 1);
                    return newUnavailableDates;
                }
            });
        }
    };

    const isDateInRange = (date, range) => {
        const start = range[0] ? new Date(range[0].getFullYear(), range[0].getMonth(), range[0].getDate()) : null;
        const end = range[1] ? new Date(range[1].getFullYear(), range[1].getMonth(), range[1].getDate()) : null;

        const checkDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        const isInRange = (start && end) ? (checkDate >= start && checkDate <= end) : false;

        return isInRange;
    };


    return (
        <div className="bg-white rounded-xl shadow-custom">
            <Calendar
                onChange={onDateChange}
                minDate={currentStep === 2 ? new Date() : (dateRange[0] ? new Date(dateRange[0]) : new Date())}
                maxDate={currentStep === -1 ? undefined : (currentStep === 2 ? undefined : (dateRange[1] ? new Date(dateRange[1]) : undefined))}
                value={dateRange && dateRange.length ? dateRange : null}
                selectRange={currentStep === 2}
                allowPartialRange={true}
                returnValue={currentStep > 2 ? "range" : "start"}
                onClickDay={onClickDay}
                formatShortWeekday={(locale, date) => date.toLocaleString(locale, { weekday: 'narrow' }).toUpperCase()}
                tileContent={({ date, view }) =>
                    view === 'month' && isSameDay(date, new Date()) ? (
                        <div style={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <div style={{
                                width: '5px',
                                height: '5px',
                                background: '#7096D1',
                                borderRadius: '9999px',
                                position: 'absolute',
                                left: '-9px',
                                bottom: '-15px'
                            }}></div>
                        </div>
                    ) : null
                }
                tileClassName={({ date, view }) => {
                    if (view !== 'month')  {
                        return null;
                    }

                    const isSpecial = isUnavailableDate(date) && isDateInRange(date, dateRange);
                    if (isSpecial) {
                        return 'special-date';
                    }

                    const isSelected = selectedDates.some((selectedDate) =>
                        isSameDay(new Date(selectedDate), date)
                    );
                    if (isSelected) {
                        return 'selected-date'; // Add yellow class for selected dates
                    }

                    return null;
                }}

                tileDisabled={({ date, view }) => {
                    if (currentStep === -1 && minEndDate && date <= minEndDate && view === 'month') {
                        return true;
                    }
                    return false;
                }}
            />
            <div className="m-8 flex justify-center">
                <div className="flex items-center mr-4">
                    <div className="w-6 h-6 bg-mi-blue rounded-full border mr-2"></div>
                    <div className="text-sm font-semibold text-gray-600">Start/End Date</div>
                </div>
                {(currentStep > 2 || currentStep === -1 || (Array.isArray(unavailableDates) && unavailableDates.length > 0)) && (
                    <div className="flex items-center mr-4">
                        <div className="w-6 h-6 bg-mi-red rounded-full border mr-2"></div>
                        <div className="text-sm font-semibold text-gray-600">Unavailable Dates</div>
                    </div>
                )}
                {(currentStep > 3 || (Array.isArray(selectedDates) && selectedDates.length > 0)) && (
                    <div className="flex items-center mr-4">
                        <div className="w-6 h-6 bg-yellow-400 rounded-full border mr-2"></div>
                        <div className="text-sm font-semibold text-gray-600">Selected Dates</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CalendarComponentBusiness;
