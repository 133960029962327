import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import whiteLogo2 from "../../assets/images/whiteLogo2.svg";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const Zipcode = (props) => {

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const csrf_token = Cookies.get("csrftoken");

    // check authentication
    useEffect(() => {
        // authentication loading
        if (loading) {
            return;
        }
        // user is logged into an account => set timezone & check account status
        if (user) {
            const checkAuth = async () => {
                const token = await auth.currentUser.getIdToken();
                const url = `${process.env.REACT_APP_DJANGO_API}/check_user`;
                const requestMetaData = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf_token,
                        "Authorization": `Bearer ${token}`,
                    },
                };
                fetch(url, requestMetaData)
                    .then(res => res.json())
                    .then((result) => {
                        if (!result || !result.success) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                confirmButtonColor: "#7BBA83",
                                footer: "Please try again later.",
                            });
                        } else {
                            const signupStep = result.data.step;
                            const accountType = result.data.user_type;
                            props.setAccountType(accountType);
                            //if (!accountType || (signupStep !== 4 && accountType === "Influencer") || (signupStep !== 5 && (accountType === "Business" || accountType === "Agency"))) { navigate("/signup"); }
                        }
                    });
            }
            checkAuth();
            // no user logged in => redirect to signin screen
        } else {
            navigate("/signin");
        }
    }, [user, loading, error]);

    return (
        <>
            <div className="flex flex-col h-screen w-screen justify-center items-center bg-gradient-to-br from-mi-blue to-mi-green">
                <div className="flex flex-col bg-white h-2/5 w-2/5 mx-4 my-3 rounded-md rounded-lg shadow-all-xl">
                    <div className="flex flex-col gap-2 rounded-xl w-fit p-4">
                        <h2 className="text-center text-3xl text-mi-blue font-semibold p-1">Thanks for signing up! </h2>
                        <div className="text-white md:text-center">
                            <p className="font-poppins"> We haven’t rolled out officially in your market yet. We’ll send you an email when we’ve officially launched in your area! </p>
                        </div>
                    </div>
                    <div className="flex justify-center items-center">
                        <img alt="MyInfluency Logo" src={whiteLogo2} className="object-scale-down"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Zipcode;