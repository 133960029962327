import React, { useState, useEffect, useMemo } from "react";
import moment from "moment-timezone";
import { auth } from "../../firebase";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import TWButton from "../TWButton";
import AmendmentCard from "./AmendmentCard";
import CalendarComponentInfluencer from "../campaign/CalendarInfluencer";
import Modal from "../Modal";
import '../campaign/custom-calendar.css';
import { isoToDate, formatDate } from "../../dateUtils";
import DatesModal from "./DatesModal"; // Import DatesModal component

const InfluencerAmendment = ({ data, dateRange, unavailableDates, busHr, timezone, visitDate, submissionDate, setVisitDate, setSubmissionDate, specDateList }) => {
    const [loading, setLoading] = useState(false);
    const [showAllAmendments, setShowAllAmendments] = useState(false);
    const [showVisitDateModal, setShowVisitDateModal] = useState(false);
    const [showSubmissionDateModal, setShowSubmissionDateModal] = useState(false);
    const csrf_token = Cookies.get("csrftoken");

    const [date, setDate] = useState(null);
    const [visitTime, setVisitTime] = useState("");
    const [submissionTime, setSubmissionTime] = useState("");
    const [amendedVisitDate, setAmendedVisitDate] = useState(null);
    const [amendedSubmissionDate, setAmendedSubmissionDate] = useState(null);
    
    const onDateChange = (date, type) => {
        setDate(date);
        if (type === 'visit') {
            setAmendedVisitDate(date);
            setVisitDate(date);
        } else if (type === 'submission') {
            setAmendedSubmissionDate(date);
            setSubmissionDate(date);
        }
    };

    const onTimeSelected = (time, type) => {
        if (type === 'visit') {
            setVisitTime(time);
        } else if (type === 'submission') {
            setSubmissionTime(time);
        }
    };

    const create_amendment = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/create_amendment/${data.contract_id}`;

        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                visitDate: amendedVisitDate.toISOString(),
                submissionDate: amendedSubmissionDate.toISOString(),
                visitTime: visitTime,
                submissionTime: submissionTime,
                timezone: timezone,
                comments: "",
                contractID: data.contract_id
            }),
        };
        await fetch(url, requestMetaData)
            .then(res => {
                const isBadRequest = res.status === 400;
                return res.json().then(data => ({
                    isBadRequest: isBadRequest,
                    body: data
                }));
            })
            .then(result => {
                if (result.isBadRequest) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.body.data || "Dates must be within date range.",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please check the details and try again.",
                    });
                } else if (!result.body || !result.body.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: "Successfully Created Amendment!",
                        confirmButtonColor: "#7BBA83",
                    }).then(() => window.location.reload());
                }
            })
            .finally(() => {
                setLoading(false); // Always turn off loading indicator
            });
    }

    const formComplete = () => {
        return (amendedVisitDate && amendedSubmissionDate);
    }

    const getHeaderText = (step) => {
        switch (step) {
            case 1:
                return "Reschedule Date: Visit Date";
            case 2:
                return "Reschedule Date: Submission Date";
            default:
                return "Reschedule Date";
        }
    };

    const newVisitDate = visitDate ? new Date(isoToDate(visitDate, "date")) : null; // Extract the date part only
    const formattedSubmissionDate = submissionDate ? new Date(formatDate(submissionDate)) : null; // Extract the date part only

    return (
        <>
            <div className="flex-auto w-full pb-4 pt-2 rounded-xl bg-white">
                <p className="font-semibold text-gray-500">Select New Dates</p>
                <p className="text-gray-600">Visit Date: </p>
                <div className="bg-white text-mi-blue underline" onClick={() => setShowVisitDateModal(true)}>
                    {amendedVisitDate ? `Selected Amended Visit Date: ${isoToDate(amendedVisitDate, "date")} ${visitTime}` 
                    : ("Select New Visit Date")}
                </div>
                {showVisitDateModal &&
                    <DatesModal 
                        setShowModal={setShowVisitDateModal}
                        onDateSelected={(date) => onDateChange(date, 'visit')}
                        onTimeSelected={(time) => onTimeSelected(time, 'visit')}
                        contractID={data.contract_id}
                        time={true}
                        setTz={timezone}
                        step={3}
                        minDate={new Date()}
                        maxDate={amendedSubmissionDate || new Date(data.end_date)}
                        dateRange={dateRange}
                        unavailableDates={unavailableDates}
                        busHr={busHr}
                        timezone={timezone}
                        visitDate={amendedVisitDate || newVisitDate}
                        submissionDate={null}
                        specDateList={specDateList}
                    />
                }
                <p className="text-gray-600">Submission Date: </p>
                <div className="bg-white text-mi-blue underline" onClick={() => setShowSubmissionDateModal(true)}>
                {amendedSubmissionDate ? `Selected Amended Submission Date: ${formatDate(amendedSubmissionDate)}` 
                : ("Select New Submission Date")}
                </div>
                {showSubmissionDateModal &&
                    <DatesModal 
                        setShowModal={setShowSubmissionDateModal}
                        onDateSelected={(date) => onDateChange(date, 'submission')}
                        onTimeSelected={(time) => onTimeSelected(time, 'submission')}
                        contractID={data.contract_id}
                        time={false}
                        setTz={timezone}
                        step={4}
                        minDate={amendedVisitDate || new Date()}
                        maxDate={new Date(data.end_date)}
                        dateRange={dateRange}
                        unavailableDates={unavailableDates}
                        busHr={busHr}
                        timezone={timezone}
                        visitDate={null}
                        submissionDate={amendedSubmissionDate || formattedSubmissionDate}
                        specDateList={specDateList}
                    />
                }
                <div className="mb-4" />
                <div className="flex flex-row w-full items-center justify-end">
                    <div className="md:w-1/6">
                        <TWButton sm loading={loading} disabled={!formComplete()} onClick={() => { create_amendment(); setLoading(true); }}>Submit Date Change</TWButton>
                    </div>
                </div>
            </div>
            {(data.amendment_approved && data.amendment_approved.length > 0) &&
                <>
                    <p className="w-full text-left font-bold text-mi-blue Dosis text-xl">Reschedule Log</p>
                    <div className="flex flex-col gap-4 w-full">
                        {data.amendment_approved.slice(0, 2).map((amendment, index) => <AmendmentCard key={index} amendment={amendment} />)}
                        {data.amendment_approved.slice(2).map((amendment, index) => {
                            return (
                                <div key={index} className={`${showAllAmendments ? "" : "hidden"}`}>
                                    <AmendmentCard amendment={amendment} />
                                </div>
                            );
                        })}
                        <div className="flex flex-row justify-end pr-4">
                            <TWButton secondary onClick={() => setShowAllAmendments(!showAllAmendments)}>{showAllAmendments ? "Show less" : "Show all..."}</TWButton>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default InfluencerAmendment;
