import React, { useState, useEffect } from 'react';

const days = [
  { name: 'Sun', enabled: true },
  { name: 'Mon', enabled: true },
  { name: 'Tue', enabled: true },
  { name: 'Wed', enabled: true },
  { name: 'Thu', enabled: true },
  { name: 'Fri', enabled: true },
  { name: 'Sat', enabled: true },
];

const ScheduleForm = ({ dayAvailability, updateDayAvailability, timeAvailability, updateSchedule, extendModal }) => {
  const [schedule, setSchedule] = useState(
    days.reduce((acc, day) => {
      const dayData = dayAvailability[day.name];
      const timeData = timeAvailability[day.name] || [{ start: '09:00', end: '17:00' }];
      acc[day.name] = {
        enabled: dayData !== undefined ? dayData : day.enabled,
        timeSlots: Array.isArray(timeData) ? timeData : [{ start: '09:00', end: '17:00' }],
      };
      return acc;
    }, {})
  );

  useEffect(() => {
    setSchedule((currentSchedule) => {
      const updatedSchedule = { ...currentSchedule };
      days.forEach((day) => {
        const dayData = dayAvailability[day.name];
        const timeData = timeAvailability[day.name] || [{ start: '09:00', end: '17:00' }];
        updatedSchedule[day.name] = {
          enabled: dayData !== undefined ? dayData : day.enabled,
          timeSlots: Array.isArray(timeData) ? timeData : [{ start: '09:00', end: '17:00' }],
        };
      });
      return updatedSchedule;
    });
  }, [dayAvailability, timeAvailability]);

  const handleTimeChange = (day, index, type, value) => {
    setSchedule((prevSchedule) => {
      const updatedTimeSlots = prevSchedule[day].timeSlots.map((slot, i) =>
        i === index ? { ...slot, [type]: value } : slot
      );

      const updatedSchedule = {
        ...prevSchedule,
        [day]: {
          ...prevSchedule[day],
          timeSlots: updatedTimeSlots,
        },
      };

      updateSchedule(updatedSchedule);
      return updatedSchedule;
    });
  };

  const handleAddTimeSlot = (day) => {
    setSchedule((prevSchedule) => {
      const existingTimeSlots = prevSchedule[day].timeSlots || [];
      if (existingTimeSlots.length >= 3) return prevSchedule;

      let newStart, newEnd;
      if (existingTimeSlots.length === 0) {
        newStart = "09:00";
        newEnd = "17:00";
      } else {
        const lastSlot = existingTimeSlots[existingTimeSlots.length - 1];
        newStart = new Date(`1970-01-01T${lastSlot.end}:00`);
        newStart.setHours(newStart.getHours() + 1);
        newEnd = new Date(newStart.getTime() + 60 * 60 * 1000).toTimeString().slice(0, 5);
        newStart = newStart.toTimeString().slice(0, 5);
      }

      const updatedSchedule = {
        ...prevSchedule,
        [day]: {
          ...prevSchedule[day],
          timeSlots: [...existingTimeSlots, { start: newStart, end: newEnd }],
        },
      };

      updateSchedule(updatedSchedule);
      return updatedSchedule;
    });
  };

  const handleRemoveTimeSlot = (day, index) => {
    setSchedule((prevSchedule) => {
      const updatedTimeSlots = prevSchedule[day].timeSlots.filter((_, i) => i !== index);

      const updatedSchedule = {
        ...prevSchedule,
        [day]: {
          ...prevSchedule[day],
          timeSlots: updatedTimeSlots,
        },
      };

      if (updatedTimeSlots.length === 0) {
        updateDayAvailability({ ...dayAvailability, [day]: false });
        updatedSchedule[day].enabled = false;
      }

      updateSchedule(updatedSchedule);
      return updatedSchedule;
    });
  };

  return (
    <div className="bg-white rounded-lg p-4 space-y-4">
      {extendModal && (
        <div className="text-mi-black p-2 rounded">
            Weekly visiting hours cannot be changed while extending a campaign.
            You can add date-specific hours for the new extended dates.
        </div>
      )}

      {days.map((day) => (
        <div key={day.name} className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                type="checkbox"
                id={day.name}
                className="w-5 h-5 text-blue-600 rounded focus:ring-blue-500"
                checked={schedule[day.name].enabled}
                disabled={extendModal} // Disable checkbox if extendModal is true
                onChange={(e) =>
                  updateDayAvailability({ ...dayAvailability, [day.name]: e.target.checked })
                }
              />
              <label htmlFor={day.name} className="ml-2 text-sm font-medium text-gray-900">
                {day.name}
              </label>
            </div>
            {schedule[day.name].enabled &&
              schedule[day.name].timeSlots.length < 3 &&
              !extendModal && ( // Hide "Add time slot" button if extendModal is true
                <button
                  onClick={() => handleAddTimeSlot(day.name)}
                  className="text-blue-600 text-sm"
                >
                  + Add time slot
                </button>
              )}
          </div>
          {schedule[day.name].enabled &&
            (schedule[day.name].timeSlots || []).map((slot, index) => (
              <div key={index} className="flex items-center gap-2 ml-8">
                <input
                  type="time"
                  value={slot.start}
                  onChange={(e) => handleTimeChange(day.name, index, 'start', e.target.value)}
                  className="border-gray-300 focus:border-blue-500 rounded text-sm"
                  disabled={extendModal} // Disable input if extendModal is true
                />
                <span>to</span>
                <input
                  type="time"
                  value={slot.end}
                  onChange={(e) => handleTimeChange(day.name, index, 'end', e.target.value)}
                  className="border-gray-300 focus:border-blue-500 rounded text-sm"
                  disabled={extendModal} // Disable input if extendModal is true
                />
                {!extendModal && ( // Hide "Remove time slot" button if extendModal is true
                  <button
                    onClick={() => handleRemoveTimeSlot(day.name, index)}
                    className="text-gray-400 hover:text-red-600 text-sm"
                  >
                    ✕
                  </button>
                )}
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default ScheduleForm;
