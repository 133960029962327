import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { auth } from "../../firebase";
import CartInfluencerCard from "./CartInfluencerCard";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { formatDate } from "../../dateUtils";

import TWButton from "../TWButton";
import TWSelect from "../TWSelect";
import Modal from "../Modal";
import Spinner from "../Spinner";
import { useMediaQuery } from "react-responsive";

const CartModal = (props) => {
    const [campaignSelect, setCampaignSelect] = useState("");
    const [displayCartInflu, setDisplayCartInflu] = useState([]);
    const [offerData, setOfferData] = useState({});
    const [loading, setLoading] = useState(false);
    const [prodList, setProdList] = useState();
    const [campaignTitles, setCampaignTitles] = useState({});
    const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
    const csrf_token = Cookies.get("csrftoken");

    useEffect(() => {
        const titles = {};
        props.campaignList.forEach((campaign) => {
            const { campaign_title, campaign_id } = campaign;
            titles[campaign_id] = campaign_title;
        });
        setCampaignTitles(titles);
    }, [props.campaignList]);

    const getProductsList = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/get_products_list`;
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.alert,
                        footer: "Please try again later.",
                    });
                } else {
                    const data = result.data;
                    setProdList(data);
                }
            });
    }

    useEffect(() => {
        let filterCartList = [];
        const campaignSelectNumber = Number(campaignSelect);

        if (campaignSelect) {
            // Filter only the data for the selected campaign
            filterCartList = props.data.filter(elem => elem.campaign === campaignSelectNumber);
        } else {
            // If no campaign is selected, use all available data
            filterCartList = props.data;
            getProductsList(); // Assuming this fetches and updates the product list globally
        }

        setDisplayCartInflu(filterCartList); // Update the displayed influencers based on the filtered list

        setOfferData(prevOfferData => {
            const newOfferData = {};

            if (campaignSelect) {
                // Initialize or keep existing data for the selected campaign
                newOfferData[campaignSelectNumber] = prevOfferData[campaignSelectNumber] || [];

                // Update or populate new entries for the selected campaign
                filterCartList.forEach((cartInflu) => {
                    const { influencer_id } = cartInflu;

                    // Find existing entry for influencer in the selected campaign
                    const existingEntry = newOfferData[campaignSelectNumber].find(inf => inf.influencerUid === influencer_id);

                    // If the influencer entry does not exist, create a new one with placeholder null values
                    if (!existingEntry) {
                        newOfferData[campaignSelectNumber].push({
                            influencerUid: influencer_id,
                            address: null,  // Initially set to null, to be updated when needed
                            product: null,  // Initially set to null, to be updated when needed
                            postType: null,  // Initially set to null, to be updated when needed
                            campaign: null, // Initially set to null, to be updated when needed
                            price: null // Initially set to null, to be updated when needed
                        });
                    } // Existing entries remain unmodified
                });
            } else {
                // If no specific campaign is selected, populate offer data for all campaigns
                props.data.forEach((cartInflu) => {
                    const { campaign, influencer_id } = cartInflu;
                    const existingEntries = prevOfferData[campaign] || [];

                    // Check if an existing entry for this influencer is already in the offer data
                    const existingEntry = existingEntries.find(inf => inf.influencerUid === influencer_id);

                    // Add or update the influencer entry
                    if (!existingEntry) {
                        const newEntry = {
                            influencerUid: influencer_id,
                            address: null,
                            product: null,
                            postType: null,
                            campaign: null,
                            price: null
                        };
                        if (!newOfferData[campaign]) {
                            newOfferData[campaign] = [];
                        }
                        newOfferData[campaign].push(newEntry);
                    } else {
                        // If an entry already exists, push the existing entry to ensure it is retained
                        if (!newOfferData[campaign]) {
                            newOfferData[campaign] = [];
                        }
                        newOfferData[campaign].push(existingEntry);
                    }
                });
            }

            return newOfferData;  // Return the updated offer data
        });
    }, [campaignSelect, props.data]);

    // This can be used to find any information contained in the dropdown

    const influName = (campaignId, influencerUid, newName) => {
        setOfferData(prev => {
            const newOfferData = { ...prev };
            if (newOfferData[campaignId]) {
                const updatedInfluencers = newOfferData[campaignId].map(influencer =>
                    influencer.influencerUid === influencerUid ? { ...influencer, name: newName } : influencer
                );
                newOfferData[campaignId] = updatedInfluencers;
            }
            return newOfferData;
        });
    };

    const product = (campaignId, influencerUid, productId) => {
        setOfferData(prev => {
            const newOfferData = { ...prev };
            if (newOfferData[campaignId]) {
                const productDetails = prodList.find(p => p.prod_id === Number(productId));
                const updatedInfluencers = newOfferData[campaignId].map(influencer =>
                    influencer.influencerUid === influencerUid ? { ...influencer, product: productDetails ? productDetails.prod_id : null } : influencer
                );
                newOfferData[campaignId] = updatedInfluencers;
            }
            return newOfferData;
        });
    };

    const postType = (campaignId, influencerUid, postTypeId) => {
        setOfferData(prevData => {
            // Fetch campaign data for the given influencer and campaign
            const campaign = props.data.find(camp =>
                camp.campaign === campaignId && camp.influencer_id === influencerUid
            );

            // If campaign data exists, find the price details for the selected post type
            const postTypeDetails = campaign?.price.find(pt => pt.price_type === Number(postTypeId));
            // Update the offer data for the specific campaign and influencer
            return {
                ...prevData,
                [campaignId]: (prevData[campaignId] || []).map(influencer => {
                    if (influencer.influencerUid === influencerUid) {
                        return {
                            ...influencer,
                            postType: postTypeId ? (postTypeDetails ? postTypeDetails.price_type : influencer.postType) : null,
                            campaign: campaignId,
                            price: (props.isPremium && postTypeDetails) ? postTypeDetails.price_amount : influencer.price
                        };
                    }
                    return influencer;
                })
            };
        });
    };

    const address = (campaignId, influencerUid, addressId) => {
        setOfferData(prev => {
            const updatedData = { ...prev };
            const addressDetails = props.addressList.find(a => a.address_id === Number(addressId));
            const updatedInfluencers = updatedData[campaignId].map(influencer =>
                influencer.influencerUid === influencerUid ? { ...influencer, address: addressDetails ? addressDetails.address_id : null } : influencer
            );
            updatedData[campaignId] = updatedInfluencers;
            return updatedData;
        });
    };

    const handlePriceChange = (campaignId, influencerUid, newPrice) => {
        const price = parseFloat(newPrice);
        const validPrice = !isNaN(price) && price >= 0 ? price : null;

        setOfferData(prevData => ({
            ...prevData,
            [campaignId]: (prevData[campaignId] || []).map(influencer => {
                if (influencer.influencerUid === influencerUid) {
                    return {
                        ...influencer,
                        price: validPrice
                    };
                }
                return influencer;
            })
        }));
    };


    const setCartInfluList = (data) => {
        props.setCartInfluList(data);
    };

    const checkOfferData = () => {
        // Iterate over each array of influencers in each campaign
        for (const key in offerData) {
            if (offerData.hasOwnProperty(key)) {
                for (const influencer of offerData[key]) {
                    if (!(influencer.address && (influencer.postType !== null && influencer.postType !== undefined) && influencer.product)) {
                        return true;
                    }
                    if (props.isPremium && (influencer.price === null || influencer.price === undefined)) {
                        return true;
                    }
                }
            }
        }

        if (displayCartInflu.length === 0) {
            return true;
        }

        return false;
    };

    const confirmSendOfferAll = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "By confirming you will be sending a proposal to all influencers in the queue. This action cannot be undone.",
            showCancelButton: true,
            confirmButtonColor: "#7BBA83",
            cancelButtonColor: "#ef4444",
            confirmButtonText: "Yes, confirm!"
        }).then((result) => {
            if (result.isConfirmed) {
                sendOfferAll();
            }
        });
    }

    const sendOfferAll = async () => {
        setLoading(true);
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/send_Offer_All`;
        let finalOfferData = offerData;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(finalOfferData),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else if (!result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Some proposals failed to send!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please make sure the start date is at least two days in the future, and the end date is at least ten days after the start date for all proposals.",
                    });
                } else {
                    ReactGA.event({
                        category: "Send Proposal",
                        action: "Send multiple proposals",
                        label: "Send multiple proposals",
                        value: offerData.length,
                    });
                    const data = result.data.explore_cartinflu;
                    props.setCartInfluList(data);
                    Swal.fire({
                        icon: "success",
                        title: "Done!",
                        text: "All proposals sent!",
                        confirmButtonColor: "#7BBA83",
                    }).then(() => window.location.reload());
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    confirmButtonColor: "#7BBA83",
                    footer: "Please try again later.",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Modal setShowModal={props.setShowModal}>
                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white fixed top-8 right-10 z-40" data-modal-hide="defaultModal" onClick={() => props.setShowModal(false)} >
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
                {!props.data
                    ? <div className="w-full h-fit flex flex-col"><Spinner /></div>
                    : <div className="rounded-lg flex flex-col h-[85%] sm:h-full w-full p-3 max-h-full overflow-y-auto sm:overflow-auto bg-gray-50">
                        {/* HEADER */}
                        <div className="flex flex-row gap-4 items-center justify-between py-5 mx-3">
                            {isSmallScreen ? (
                                <div className="text-xl font-bold text-mi-black">
                                    <h2 className="inline text-xl font-bold"> Proposal Queue </h2>

                                    <span>
                                        {!campaignSelect ? "(" + displayCartInflu.length + ")" : null}
                                    </span>
                                </div>
                            ) : (
                                <div className="text-xl font-bold text-gray-800 font-poppins">
                                    <h2 className="inline text-xl font-bold">Proposal Queue</h2>
                                    <span className="mx-2">
                                        {!campaignSelect ? "(" + displayCartInflu.length + ")" : null}
                                    </span>
                                </div>
                            )}
                        </div>
                        <hr />
                        <div className="px-2 mt-7 mb-3">
                            <div className="flex flex-col sm:flex-row justify-between">
                                {/* appears on mobile */}
                                {isSmallScreen && (
                                    <div className="w-full mr-8 mb-6 mt-1">
                                        <TWSelect lightblue name="campaign-name" onChange={e => setCampaignSelect(e.target.value)} >
                                            <option value="">All</option>
                                            {props.campaignList.map((campaign, index) => {
                                                const relevantInfluencers = props.data?.filter(cartInflu => cartInflu.campaign === campaign.campaign_id) || [];
                                                return (
                                                    <>
                                                        {relevantInfluencers.length > 0 && (  // Check if there are any relevant influencers
                                                            <>
                                                                <option key={index} value={campaign.campaign_id}>{campaign.campaign_title}</option>
                                                            </>
                                                        )}
                                                    </>
                                                )
                                            })}
                                        </TWSelect>
                                    </div>
                                )}
                                <h2 className="font-semibold text-sm sm:text-[20px] items-center text-gray-700 flex flex-row gap-1">
                                    Showing proposals for
                                    <div className={`${campaignSelect ? "text-mi-blue" : ""}`}>{campaignSelect ? campaignTitles[campaignSelect] : "all"}:</div>
                                </h2>
                                {/* appears on desktop */}
                                {!isSmallScreen && (
                                    <div className="w-3/12 mr-8 mt-1">
                                        <TWSelect lightblue name="campaign-name" onChange={e => setCampaignSelect(e.target.value)} >
                                            <option value="">All</option>
                                            {props.campaignList.map((campaign, index) => {
                                                const relevantInfluencers = props.data?.filter(cartInflu => cartInflu.campaign === campaign.campaign_id) || [];
                                                return (
                                                    <>
                                                        {relevantInfluencers.length > 0 && (
                                                            <>
                                                                <option key={index} value={campaign.campaign_id}>{campaign.campaign_title}</option>
                                                            </>
                                                        )}
                                                    </>
                                                )
                                            })}
                                        </TWSelect>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* BODY */}
                        <div className="flex flex-col">
                            {props.campaignList.map((campaign) => {
                                const relevantInfluencers =
                                    displayCartInflu?.filter((cartInflu) => cartInflu.campaign === campaign.campaign_id) || [];
                                const relevantAddresses = campaign.client_id
                                    ? props.addressList?.filter(addy => addy.client_id === campaign.client_id)
                                    : props.addressList
                                return (
                                    <div className="px-2" key={campaign.campaign_id}>
                                        {relevantInfluencers && relevantInfluencers.length > 0 && (
                                            <>
                                                <h2 className="font-extrabold Poppins text-mi-blue mt-8">
                                                    {campaign.campaign_title}{" "}
                                                    {formatDate(campaign.start_date)}-{formatDate(campaign.end_date)}{" "}
                                                    {props.accountType === "Agency" && (
                                                        <span className="text-gray-500">
                                                            Client: {campaign.client_name}
                                                        </span>
                                                    )}
                                                </h2>
                                                {relevantInfluencers.map((cartInflu, index) => (
                                                    <div key={index}>
                                                        <CartInfluencerCard
                                                            index={index}
                                                            data={cartInflu}
                                                            offerData={offerData[campaign.campaign_id][index]}
                                                            cartLoading={loading}
                                                            setCartLoading={setLoading}
                                                            addressList={relevantAddresses}
                                                            address={address}
                                                            postType={postType}
                                                            influName={influName}
                                                            priceData={relevantInfluencers[index]?.price}
                                                            priceChange={handlePriceChange}
                                                            product={product}
                                                            prodList={prodList ? prodList.filter(elem => elem.campaign === cartInflu.campaign) : []}
                                                            setCartInfluList={setCartInfluList}
                                                            isPremium={props.isPremium}
                                                            paid={props.paid}
                                                        />
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        <hr className="hidden sm:flex mt-4" />
                        {/*FOOTER*/}
                        {/* DESKTOP VERSION */}
                        {!isSmallScreen && (
                            <div className="relative flex justify-end border-solid border-slate-200 mx-3 py-3">
                                <div className="max-w-sm">
                                    <TWButton onClick={confirmSendOfferAll} loading={loading} disabled={checkOfferData() || loading}>Send All Proposals</TWButton>
                                </div>
                            </div>
                        )}
                    </div>

                }

                {/*FOOTER*/}
                {/* MOBILE VERSION */}
                {isSmallScreen && (
                    <div className="fixed w-full bottom-0">
                        <div className="w-full flex border-solid border-slate-200">
                            <div className=" shadow-t-xl w-full h-[120px] border-t flex items-end justify-center items-center ">
                                <div className="max-w-sm">
                                    <TWButton onClick={confirmSendOfferAll} loading={loading} disabled={checkOfferData() || loading}>Send All Proposals</TWButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Modal >
        </>
    );

}
export default CartModal;
