import { useState,useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';

import TWButton from "../../TWButton";
const VisitDetailCard = (props) => {
    const [prodName, setProdName] = useState();
    const [prodDescription, setProdDescription] = useState();
    const [curraddressID, setCurrAddressID] = useState();

    useEffect(() => {
        setProdName(props.data.prodName);
        setProdDescription(props.data.prodDes);
        setCurrAddressID(props.data.addressID);
    }, []);

    const formComplete = () => {
        if(prodName !==props.data.prodName){
            return true;
        }
        if(prodDescription !==props.data.prodDes){

            return true;
        }
        if(curraddressID !==props.data.addressID){
            return true;
        }
        return false;
    };
    
    const findObjectById = (id) => {
        return props.addressList.find(obj => obj.id === parseInt(id));
    }

    const saveEdit = () => {
        let addressLocation = findObjectById(curraddressID);

        props.setAddressID(curraddressID);
        props.setAddressLoc(addressLocation);
        props.setProdDes(prodDescription);
        props.setProdName(prodName);
        props.setShowModal(false);
    }

    return (
        <>  
            <div className="flex flex-col p-4 mb-2 w-full h-hull bg-white rounded-xl gap-4">
                <div className=" flex flex-row">
                    <div class="flex flex-auto text-neutral-600 text-xl font-bold font-['Poppins']">Visit Details</div>
                    <CloseIcon onClick={() => {props.setShowModal(false)}}/>
                </div>
                <hr className="color-[#DBDFE6] "></hr>
                <div className="grid grid-cols-2 gap-4 ">
                    <div className="flex flex-col flex-auto gap-2">
                        <div class="w-[273px] text-neutral-600 text-base font-semibold font-['Poppins']">Product and Service Included</div>
                        <input className="px-5 py-2.5 rounded-[5px] border border-zinc-200" value={prodName} onChange={e => { setProdName(e.target.value) }}/>
                        <div class="text-neutral-600 text-base font-semibold font-['Poppins']">Address</div>
                        <select className="w-full rounded-[5px] border border-zinc-200 px-5 py-2.5  text-gray-800 disabled:cursor-not-allowed disabled:opacity-50"
                            onChange={(e) => setCurrAddressID(e.target.value)}
                            defaultValue={curraddressID}
                            >
                            {props.addressList.map((address, index) => {
                                return (
                                    <option key={index} value={address.id}>{address.line_1}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="flex flex-col flex-auto gap-2">
                        <div class="w-48 text-neutral-600 text-base font-semibold font-['Poppins']">Product Description</div>
                        <textarea  className="h-full w-full px-5 py-2.5 rounded-[5px] border border-zinc-200" value={prodDescription} onChange={e => { setProdDescription(e.target.value) }} />
                    </div>
                </div>
                <div className="md:flex md:flex-row md:items-center md:justify-end w-full">
                <TWButton sm disabled={!formComplete()} onClick={() => saveEdit()}>Save Edits</TWButton>
                </div>
            </div>
        </>
    )
}

export default VisitDetailCard;
