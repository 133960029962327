import { useLayoutEffect, useRef, useState } from "react";
import { isoToDate, formatDate } from "../../dateUtils";
import { checkout } from "../../stripe";
import { Checkbox } from "@mui/material";
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import EditIcon from '@mui/icons-material/Edit';

import TWButton from "../TWButton";
import BusinessAmendment from "./BusinessAmendment";

const BusinessContractBody = (props) => {
    const [productOverflow, setProductOverflow] = useState(false);
    const [fullDescription, setFullDescription] = useState(false);
    const [tosAccept, setTosAccept] = useState(false);
    const productRef = useRef(null);

    useLayoutEffect(() => {
        if (productRef.current && !fullDescription) {
            setProductOverflow(productRef.current.offsetHeight < productRef.current.scrollHeight);
        }
    });

    // Helper function to add days to a date
    const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    return (
        <>
            {(props.data.status === "Current" || props.data.status === "Fullfilled")
                ? <h2>Contract Details</h2>
                : <h2 className="font-poppins text-neutral-950 font-bold text-xl mt-8">Proposal Details</h2>
            }
            <div className="w-full h-fit flex flex-col items-center mx-auto p-8 mb-4 rounded-3xl gap-2 bg-white  md:overflow-y-auto md:scrollbar border drop-shadow-lg">
                {/* Campaign Info */}
                <h2 className="w-full text-left text-neutral-950 font-bold font-poppins">Campaign Descriptions</h2>
                <div className="mt-2 mb-2 h-fit w-full bg-white rounded-xl">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="flex flex-col gap-4">
                            <h3>Title: <h3 className="inline ">{props.data.campaign.title}</h3></h3>
                            <h3>Description: </h3>
                            <p className="inline font-normal text-base text-mi-black font-poppins">{props.data.campaign.description}</p>
                            <h3>Additional Details: </h3>
                            <p className="inline font-normal text-base text-mi-black font-poppins">{props.data.campaign.additional_details}</p>
                        </div>
                    </div>
                </div>
                <h2 className="w-full text-left mb-8 mt-8 text-neutral-950 font-bold font-poppins">Deliverable Details</h2>
                {/* Promotion Info */}
                <div className="w-full flex flex-col gap-2">

                    <div className="flex items-center">
                        <h3 className="w-1/6">Execution Timeframe:</h3>
                        <h3 className="font-medium">{formatDate(addDays(props.data.start_date, 1))} - {formatDate(addDays(props.data.end_date, 1))}</h3>
                    </div>

                    <div className="flex items-center">
                        <h3 className="w-1/6">Deliverable:</h3>
                        <h3 className="font-medium">Instagram {props.data.post_type}</h3>
                    </div>

                    <div className="flex items-center">
                        <h3 className="w-1/6">Focus of Promotion:</h3>
                        <h3 className="font-medium">{props.data.campaign.promotion_type}</h3>
                    </div>
                </div>


                {/* Intended Usage */}
                <div className="w-full bg-white rounded-xl py-8">
                    <p className="font-semibold text-mi-black font-poppins mb-4">You have rights to use the influencer's content for promotion at the following links:</p>
                    {(props.data.campaign.intended_usage !== null && props.data.campaign.intended_usage.length !== 0)
                        ? props.data.campaign.intended_usage.map((item, index) => <div key={index}>
                            <a href={item} className="text-mi-blue underline font-poppins" >{item}</a>
                            <br />
                        </div>)
                        : <p className="text-mi-black font-poppins">No intended usage provided</p>
                    }

                </div>

                <div className="w-full flex flex-col items-center space-y-8">
                    <h2 className="w-full text-left">Visit Details <EditIcon fontSize="small" className="mb-2 text-sm" onClick={() => props.setOpenVisitDetail(true)} /></h2>

                    <div className="flex w-full grid grid-cols-2">
                        {/* Left Column */}
                        <div className="w-full flex flex-col gap-4">
                            {/* Product or Service Included */}
                            {(props.edited.prodName === props.data.campaign.product_name)
                                ? <div className="flex flex-col gap-4">
                                    <h3>Product or Service Included:</h3>
                                    <p className="text-mi-black">{props.data.campaign.product_name}</p>
                                </div>
                                : <div className="flex flex-col gap-4">
                                    <h3>Product or Service Included: <span className="text-neutral-600 text-xs font-medium font-['Poppins']">(Updated)</span></h3>
                                    <p className="text-mi-black">{props.edited.prodName}</p>
                                </div>
                            }

                            {/* Address */}
                            {(props.edited.addressID === props.data.address.id)
                                ? <div className="flex flex-col gap-4">
                                    <h3 className="inline mr-2">Address:</h3>
                                    <span className="text-mi-black">
                                        {
                                            `${props.data.address.line_1}, ${props.data.address.line_2 ? `${props.data.address.line_2}, ` : ''}
                                        ${props.data.address.line_3 ? `${props.data.address.line_3}, ` : ''}
                                        ${props.data.address.city}, ${props.data.address.state} ${props.data.address.zip_code}`
                                        }
                                    </span>
                                </div>
                                : <div className="flex flex-col gap-4">
                                    <h3 className="inline mr-2">Address: <span className="text-neutral-600 text-xs font-medium font-['Poppins']">(Updated)</span></h3>
                                    <span className="text-mi-black">
                                        {
                                            `${props.edited.addressLoc.line_1}, ${props.edited.addressLoc.line_2 ? `${props.edited.addressLoc.line_2}, ` : ''}
                                        ${props.edited.addressLoc.line_3 ? `${props.edited.addressLoc.line_3}, ` : ''}
                                        ${props.edited.addressLoc.city}, ${props.edited.addressLoc.state} ${props.edited.addressLoc.zip_code}`
                                        }
                                    </span>
                                </div>
                            }
                        </div>

                        {/* Right Column */}
                        <div className="w-1/2 flex flex-col">
                            {/* Product Description */}
                            {(props.edited.prodDes === props.data.campaign.product_description)
                                ? <div className="flex flex-col gap-4">
                                    <h3>Description:</h3>
                                    <p ref={productRef} className={`text-mi-black ${!fullDescription ? "line-clamp-2" : ""}`}>
                                        {props.data.campaign.product_description}
                                    </p>
                                    {productOverflow &&
                                        <div className="flex justify-end w-full">
                                            <button onClick={() => setFullDescription(!fullDescription)} className="text-blue-600 underline text-sm font-semibold">{fullDescription ? "Hide" : "Read more..."}</button>
                                        </div>
                                    }
                                </div>
                                : <div className="flex flex-col gap-4">
                                    <h3>Description: <span className="text-neutral-600 text-xs font-medium font-['Poppins']">(Updated)</span></h3>
                                    <p ref={productRef} className={`text-mi-black ${!fullDescription ? "line-clamp-2" : ""}`}>
                                        {props.edited.prodDes}
                                    </p>
                                    {productOverflow &&
                                        <div className="flex justify-end w-full">
                                            <button onClick={() => setFullDescription(!fullDescription)} className="text-blue-600 underline text-sm font-semibold">{fullDescription ? "Hide" : "Read more..."}</button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {(props.data.status !== "Pending") &&
                    <>
                        <h2 className="w-full text-left mt-10">Influencer's Dates</h2>
                        <div className={`grid grid-cols-1 md:${(props.data.status !== "Pending") ? "grid-cols-3" : "grid-cols-2"} gap-4 w-full`}>
                            <div className="pb-4 pt-2 rounded-xl bg-white">
                                {Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0 ? (
                                    <>
                                        <p className="text-mi-black">Visit Date: {isoToDate(props.data.amendment_approved[0].new_visit_date, "datetime")}</p>
                                        <p className="text-mi-black">Final Post Date: {isoToDate(props.data.amendment_approved[0].new_submission_date)}</p>
                                    </>
                                ) : (
                                    <>
                                        <p className="text-mi-black">Visit Date: {props.data.visit_date ? isoToDate(props.data.visit_date, "datetime") : 'Not Selected'}</p>
                                        <p className="text-mi-black">Final Post Date: {props.data.submission_date ? isoToDate(props.data.submission_date) : 'Not Selected'}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                }

                {/* Compensation */}
                <h2 className="w-full text-left font-bold mb-4">Compensation</h2>
                <div className="mb-2 h-fit w-full bg-white rounded-xl">
                    <div className="w-full flex flex-row justify-between mb-2">
                        <h3 className="text-left">Influencer Rate</h3>
                        <h3 className="text-right">{props.data.price_details.influencer_price}</h3>
                    </div>

                    <div className="w-full flex flex-row justify-between mb-4">
                        <h3 className="text-left">My Influency Commission Fee (6.5%)</h3>
                        <h3 className="text-right">+ {props.data.price_details.MI_fees}</h3>
                    </div>

                    <div className="w-full h-[2px] my-2 bg-mi-black mb-4"></div>

                    <div className="w-full flex flex-row justify-between mb-2">
                        <h3 className="text-left">Total Price</h3>
                        <h1 className="text-right">{props.data.price_details.total_price}</h1>
                    </div>
                    {props.data.status !== "Pending" &&
                        <div className="w-full flex flex-row justify-between mb-2">
                            <h3 className="text-left">
                                {props.data.status === "Ready For Pay"
                                    ?
                                    <>
                                        <div className="pb-4 pt-2 rounded-xl bg-white">
                                            {/* <h2 className="font-bold  text-gray-950 text-opacity-70 mb-1 font-['Poppins']">Accept</h2> */}
                                            <p className="font-poppins font-bold text-mi-blue mt-8 mb-8">To accept:</p>
                                            <p className=" text-gray-600 text-sm font-poppins mb-8">
                                                {props.data.agreement_text}
                                            </p>
                                            <div className="w-full flex flex-row items-center">
                                                <label className="flex items-center cursor-pointer">
                                                    <Checkbox
                                                        onChange={() => setTosAccept(!tosAccept)}
                                                        className="p-0 pt-0.5 text-gray-400 mr-1"
                                                        checked={tosAccept}
                                                    />
                                                    <p className="text-sm font-bold text-neutral-800">
                                                        I have read and understand the terms of this proposal
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-end">
                                            <TWButton lg disabled={!tosAccept} onClick={() => checkout(props.data.contract_id)}>
                                                <div className="flex flex-row gap-2 items-center">
                                                    <ShoppingCartRoundedIcon className="text-white" />
                                                    Proceed to Payment
                                                </div>
                                            </TWButton>
                                        </div>
                                    </>

                                    : "Payment Date"
                                }
                            </h3>
                            <h3 className="text-right">
                                {props.data.status === "Ready For Pay"
                                    ? isoToDate(props.data.pay_expiration_date).slice(0, -7)
                                    : (props.data.transaction && props.data.transaction.timestamp
                                        ? isoToDate(props.data.transaction.timestamp).slice(0, -7)
                                        : 'Not Selected'
                                    )
                                }
                            </h3>
                        </div>
                    }
                </div>

                {/* Amendments */}
                {(props.data.status === "Current" || props.data.status === "Ready For Pay") &&
                    <BusinessAmendment data={props.data} />
                }

            </div >
        </>
    );
}
export default BusinessContractBody;