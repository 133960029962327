import { auth } from "../../firebase";
import { useEffect, useState, useCallback } from "react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import TWButton from "../TWButton";
import TWDropDown from "../TWDropDown";
import TWTextField from "../TWTextArea";
import TWSelect from "../TWSelect";
import { Ping } from "../Pings";

const InfluencerProfileSettings = ({ data, profilePicture}) => {
    const [firstName, setFirstName] = useState(data.first_name);
    const [lastName, setLastName] = useState(data.last_name);
    const [gender, setGender] = useState(data.gender);
    const [bio, setBio] = useState(data.bio);
    const [zipCode, setZipCode] = useState(data.zip_code);
    const [category, setCategory] = useState(data.category);
    const [otherCategory, setOtherCategory] = useState(data.category_other);
    const [instagramURL, setInstagramURL] = useState(data.ig_handle);
    const [email, setEmail] = useState(data.email);
    const [phoneNumber, setPhoneNumber] = useState(data.phone_number);
    const [priceReels, setPriceReels] = useState(data.price_reels);
    const [priceFeedPosts, setPriceFeedPosts] = useState(data.price_feed_posts);
    const [priceVideos, setPriceVideos] = useState(data.price_videos);
    const [priceStories, setPriceStories] = useState(data.price_stories);
    const [loading, setLoading] = useState(false);

    const [isValidZipCode, setIsValidZipCode] = useState(true);
    const navigate = useNavigate();

    const [tag, setTag] = useState(data.tags || [""]);
    const csrf_token = Cookies.get("csrftoken");

    const genders = [
        { value: "M", label: "Male" },
        { value: "F", label: "Female" },
        { value: "N", label: "Non-Binary" },
    ]

    const checkValidZipCode = async () => {
        setLoading(true);
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/check_zipcode_valid`;
        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({'zipcode': zipCode}),
        };
        await fetch(url, requestMetaData)
            .then(res => res.json())
            .then((result) => {
                if (!result || !result.success) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                } else {
                    const data = result.data;
                    setIsValidZipCode(data[0]);

                    //if valid and available zip, proceed as normal
                    if (data[0] && data[1]){
                        saveChanges();
                    } 
                    //if valid but unavailable zip, provide a warning
                    else if (data[0] && !data[1]) {
                        Swal.fire({
                            icon: "warning",
                            title: "Hold On!",
                            text: `The zip code you have entered is in an area we haven't launched in yet. 
                            Until we launch in that area, brands likely won't be able to find you if you 
                            choose to change your zip code to this.`,
                            confirmButtonColor: "#7BBA83",
                            showCancelButton: true,
                            cancelButtonText: "Cancel",
                        }).then((result) => {
                            if (result.isConfirmed){
                                saveChanges();
                            } else {
                                setLoading(false);
                            }
                        })
                    }
                    // if invalid zip, don't continue
                    else {
                        setLoading(false);
                    }
                }
            });
    }

    const saveChanges = useCallback(async () => {
        const formattedTags = tag.filter(t => t !== '');
        const invalidTags = formattedTags.filter(t => !t.startsWith('#'));
        if (invalidTags.length > 0) {
            Swal.fire({
                icon: "error",
                title: "Invalid Tags",
                text: "All tags must start with a #. Please correct the tag(s).",
                confirmButtonColor: "#7BBA83",
            });
            setLoading(false);
            return;
        }

        const saveData = {
            first_name: firstName,
            last_name: lastName,
            gender: gender,
            bio: bio,
            category: category,
            tags: formattedTags,
            other_category: otherCategory,
            instagram_handle: instagramURL,
            email: email,
            phone_number: phoneNumber,
            zip_code: zipCode,
            price_reels: priceReels,
            price_feed_posts: priceFeedPosts,
            price_videos: priceVideos,
            price_stories: priceStories,
            pfp: profilePicture || null,
        };
        const url = `${process.env.REACT_APP_DJANGO_API}/save_user_info`;
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrf_token,
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(saveData),
            });
            const result = await response.json();
            if (!result || !result.success) {
                throw new Error("Failed to save profile settings");
            }
            Swal.fire({
                icon: "success",
                title: "Done!",
                text: "Successfully updated profile settings!",
                confirmButtonColor: "#7BBA83",
            }).then(() => window.location.reload());

            setFirstName(result.data.first_name || "");
            setLastName(result.data.last_name || "");
            setBio(result.data.bio || "");
            setEmail(result.data.email || "");
            setCategory(result.data.category || "");
            setTag(result.data.tags || [""]);
            setOtherCategory(result.data.category_other || "");
            setInstagramURL(result.data.instagram_handle || "");
            setPhoneNumber(result.data.phone_number || "");
            setZipCode(result.data.zip_code || "");
            setPriceReels(result.data.price_reels !== null ? result.data.price_reels : "0.0");
            setPriceFeedPosts(result.data.price_feed_posts !== null ? result.data.price_feed_posts : "0.0");
            setPriceVideos(result.data.price_videos !== null ? result.data.price_videos : "0.0");
            setPriceStories(result.data.price_stories !== null ? result.data.price_stories : "0.0");
            setLoading(false);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        } finally {
            setLoading(false);
        }
    }, [firstName, lastName, gender, bio, zipCode, category, tag, otherCategory, instagramURL, email, phoneNumber, priceReels, priceFeedPosts, priceVideos, priceStories, profilePicture, csrf_token]);

    // useEffect(() => {
    //     if (loading) {
    //         saveChanges();
    //     }
    // }, [loading, saveChanges]);

    const addTag = () => {
        setTag((prev) => [...prev, ""]);
    };

    const tagName = (index, tagName) => {
        setTag((prevTags) => {
            const newTags = [...prevTags];
            newTags[index] = tagName;
            return newTags;
        });
    };

    const deleteTag = (index) => {
        const list = [...tag];
        list.splice(index, 1);
        setTag(list);
    };

    return (
        <>
            <div className="flex flex-col gap-10 w-full h-full p-2 px-10 md:px-40">
                <h3 className="w-full font-bold text-2xl text-gray-600 mt-5">Profile Settings</h3>
                <div className="flex flex-col md:flex-row gap-10">
                    <div className="md:flex-auto">
                        <h4 className="w-full font-semibold text-gray-500">First Name:</h4>
                        <input type="text" value={firstName} placeholder="Enter your first name here" onChange={e => { setFirstName(e.target.value) }} required className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                    </div>

                    <div className="md:flex-auto">
                        <h4 className="w-full font-semibold text-gray-500">Last Name:</h4>
                        <div className="w-full flex">
                            <input type="text" value={lastName} placeholder="Enter your last name here" onChange={e => { setLastName(e.target.value) }} required className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                        </div>
                    </div>

                    <div>
                        <h4 className="flex-auto font-semibold text-gray-500">Gender:</h4>
                        <TWSelect whitebox value={gender} disabled={loading} onChange={e => setGender(e.target.value)}>
                            {
                                genders.map((option, index) => {
                                    return <option key={index} value={option.value}>{option.label}</option>
                                })
                            }
                        </TWSelect>
                    </div>
                </div>

                <div>
                    <h4 className="w-full font-semibold text-gray-500">Bio:</h4>
                    <div className="w-full flex">
                        <TWTextField value={bio} placeholder="Enter a bio" onChange={e => { setBio(e) }} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></TWTextField>
                    </div>
                </div>

                {/* <h4 className="w-full font-semibold text-gray-500">Instagram URL:</h4>
                    <div className="w-full flex mb-10">
                        <input type="text" value={instagramURL} placeholder="Enter your Instagram URL here" onChange={e => { setInstagramURL(e.target.value) }} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                    </div> */}

                <div>
                    <h4 className="w-full font-semibold text-gray-500">{"Price for Feed Posts ($):"}</h4>
                    <div className="w-full flex">
                        <input type="number" value={priceFeedPosts} onChange={e => { setPriceFeedPosts(e.target.value) }} className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700" min="0.00" max="10000.00" step="0.01" />
                    </div>
                </div>

                <div>
                    <h4 className="w-full font-semibold text-gray-500">{"Price for Reels ($):"}</h4>
                    <div className="w-full flex">
                        <input type="number" value={priceReels} onChange={e => { setPriceReels(e.target.value) }} className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700" min="0.00" max="10000.00" step="0.01" />
                    </div>
                </div>

                <div>
                    <h4 className="w-full font-semibold text-gray-500">{"Price for Videos ($):"}</h4>
                    <div className="w-full flex">
                        <input type="number" value={priceVideos} onChange={e => { setPriceVideos(e.target.value) }} className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700" min="0.00" max="10000.00" step="0.01" />
                    </div>
                </div>

                <div>
                    <h4 className="w-full font-semibold text-gray-500">{"Price for Stories ($):"}</h4>
                    <div className="w-full flex">
                        <input type="number" value={priceStories} onChange={e => { setPriceStories(e.target.value) }} className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700" min="0.00" max="10000.00" step="0.01" />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-10">
                    <div className="flex-auto">
                        <h4 className="w-full font-semibold text-gray-500">Zip Code:</h4>
                        <div className="w-full">
                            <input type="text" value={zipCode} onChange={e => { setZipCode(e.target.value) }} placeholder="e.g. 3000" required className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                        </div>
                        {isValidZipCode
                            ? null
                            : <div className="flex md:flex-row w-full pt-3 pl-1 text-red-500 text-sm">
                                Please enter a valid US zip code
                            </div>
                        }
                    </div>

                    <div className="flex-auto">
                        <h4 className="w-full font-semibold text-gray-500">Email:</h4>
                        <div className="w-full">
                            <input type="text" value={email} splaceholder="Enter your email" onChange={e => { setEmail(e.target.value) }} required className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                        </div>
                    </div>

                    <div className="flex-auto">
                        <h4 className="w-full font-semibold text-gray-500">Phone Number:</h4>
                        <div className="w-full">
                            <input type="text" value={phoneNumber} placeholder="Enter your phone number here" onChange={e => { setPhoneNumber(e.target.value) }} required className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-10">
                    <div className="md:w-1/2">
                        <h4 className="w-full font-semibold text-gray-500">Category:</h4>
                        <div className="w-full">
                            <TWDropDown white
                                onChange={e => {
                                    setCategory(e);
                                    if (category !== "18" && category !== "Other") {
                                        setOtherCategory("");
                                    }
                                }}
                                data={
                                    [
                                        { value: 1, label: "Artist" },
                                        { value: 2, label: "Celebrity" },
                                        { value: 3, label: "Gamer" },
                                        { value: 4, label: "Skincare" },
                                        { value: 5, label: "Blogger" },
                                        { value: 6, label: "Makeup/Beauty" },
                                        { value: 7, label: "Fashion" },
                                        { value: 8, label: "Travel" },
                                        { value: 9, label: "Lifestyle" },
                                        { value: 10, label: "Health" },
                                        { value: 11, label: "Fitness" },
                                        { value: 12, label: "Luxury" },
                                        { value: 13, label: "Pets" },
                                        { value: 14, label: "Food" },
                                        { value: 15, label: "Cultural" },
                                        { value: 16, label: "Parenting" },
                                        { value: 17, label: "Sustainability" },
                                        { value: 18, label: "Other" },
                                    ]
                                }>{category}</TWDropDown>
                        </div>
                    </div>

                    <div className="md:w-1/2">
                        <h4 className="w-full font-semibold text-gray-500">Other:</h4>
                        <div className="w-full">
                            <input type="text" value={otherCategory} disabled={category !== "18" && category !== "Other"} placeholder="e.g. Bird Watching" onChange={e => { setOtherCategory(e.target.value) }} className="w-full rounded-xl p-1 bg-gray-200 outline-gray-400 focus:text-gray-700"></input>
                        </div>
                    </div>

                </div>


                <div className="flex flex-col md:flex-row gap-10">
                    <div className="flex-auto">
                        <h4 className="w-full font-semibold text-gray-500">Tags:</h4>
                        <div className="w-full flex flex-row gap-x-3">
                            {tag.map((t, index) => (
                                <div key={index} className="flex flex-col gap-2">
                                    <div className="relative flex w-full">
                                        <input type="text" placeholder="#MyInfluency" value={t} onChange={e => tagName(index, e.target.value)} required className="flex-auto w-full rounded-xl p-1 pr-8 bg-gray-200 outline-gray-400 focus:text-gray-700 disabled:cursor-not-allowed"></input>
                                        <button disabled={tag.length <= 1}
                                            className={`h-min w-fit scale-125 absolute right-1 top-[0.135rem] ${tag.length <= 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            onClick={() => tag.length > 1 && deleteTag(index)}>
                                            <Ping className="text-xs text-gray-500 hover:text-neutral-400" >x</Ping>
                                        </button>
                                        {/* </div> */}
                                    </div>

                                </div>
                            ))}
                            <div className="shrink-0">
                                {tag.length < 5 && (
                                    <TWButton sm type="button"
                                        onClick={addTag}
                                        className="add-btn">+ Add Tag</TWButton>
                                )}

                            </div>
                        </div>
                    </div>

                </div>

                <div className="w-full flex flex-row justify-center md:justify-end items-center">
                    <div className="w-full md:w-[30%] mb-5">
                        <TWButton
                            md
                            onClick={() => checkValidZipCode()}
                            disabled={loading}
                            loading={loading}
                        >
                            Save Changes
                        </TWButton>
                    </div>
                </div>
            </div>
        </>
    );
}
export default InfluencerProfileSettings;
