import { useEffect, useState, Fragment, forwardRef, useRef } from "react";
import { auth } from "../../firebase";
import { isoToDate } from "../../dateUtils";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import Modal from "../Modal";
import TWButton from "../TWButton";
import TWTextField from "../TWTextField";
import TWTextArea from "../TWTextArea";
import TWDropDown from "../TWDropDown";
import { InfoPing } from "../Pings";
import DatePicker from "react-datepicker";
import ScheduleForm from "./ScheduleTime";
import CloseBlackButton from "../../assets/images/black_close_button.svg";
import CalendarComponentBusiness from "./CalendarBusiness";
import CloseBlueButton from "../../assets/images/blue_close_button.svg";

const CreateCampaignModal = (props) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [promotionType, setPromotionType] = useState("");
    const [additionalDetails, setAdditionalDetails] = useState("");
    const [dateRange, setDateRange] = useState([]);

    const [dailyLimit, setDailyLimit] = useState(4);

    const [unavailableDates, setUnavailableDates] = useState([]);

    const [dateSpecificHours, setDateSpecificHours] = useState({});
    const [selectedDates, setSelectedDates] = useState([]);

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 5;

    const goForward = () => setCurrentStep((prev) => Math.min(prev + 1, totalSteps));
    const goBackward = () => setCurrentStep((prev) => Math.max(prev - 1, 1));

    const isUnavailableDate = (date) =>
        unavailableDates.some(unavailableDate =>
            date.getFullYear() === unavailableDate.getFullYear() &&
            date.getMonth() === unavailableDate.getMonth() &&
            date.getDate() === unavailableDate.getDate()
        );

    const onDateChange = (date) => {
        if (currentStep === 2) {
            const currentTime = new Date();
            if (Array.isArray(date) && date.length === 2) {
                const updatedDates = date.map((d) => {
                    if (d !== null) {
                        const newDate = new Date(d);
                        newDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
                        return newDate;
                    }
                    return null;
                });
                setDateRange(updatedDates);
            } else {
                setDateRange(date);
            }
        }


        const selectedDateObj = Array.isArray(date) ? date[0] : date;

        if (!(selectedDateObj instanceof Date)) return; // Exit if invalid date
        const dateString = selectedDateObj.toLocaleDateString("en-US");
        const dayOfWeek = selectedDateObj.toLocaleDateString("en-US", { weekday: "short" }); // Get day of week (e.g., "Fri")

        if (currentStep === 4) {
            // Step 4: Toggle date in selectedDates and remove from unavailableDates if needed
            setSelectedDates((prevSelectedDates) => {
                const isAlreadySelected = prevSelectedDates.includes(dateString);

                if (isAlreadySelected) {
                    // Remove time slots for the date if toggled off
                    setDateSpecificHours((prevHours) => {
                        const updatedHours = { ...prevHours };
                        delete updatedHours[dateString];
                        return updatedHours;
                    });

                    // Check weekly availability and add to unavailableDates if day is disabled
                    setUnavailableDates((prevUnavailableDates) => {
                        if (!dayAvailability[dayOfWeek]) {
                            return [...prevUnavailableDates, selectedDateObj];
                        }
                        return prevUnavailableDates;
                    });

                    return prevSelectedDates.filter((d) => d !== dateString);
                } else {
                    // Add to selectedDates and remove from unavailableDates
                    setUnavailableDates((prevUnavailableDates) =>
                        prevUnavailableDates.filter(
                            (unavailableDate) =>
                                new Date(unavailableDate).toLocaleDateString("en-US") !== dateString
                        )
                    );
                    return [...prevSelectedDates, dateString];
                }
            });
        }

    };

    useEffect(() => {
        // Reset states when dateRange is empty
        if (!dateRange || dateRange.length === 0 || dateRange.every((d) => !d)) {
            setUnavailableDates([]);
            setSelectedDates([]);
            setDateSpecificHours({});
        }
    }, [dateRange]);

    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    }

    const handleDayAvailabilityChange = (updatedAvailability) => {
        setDayAvailability(updatedAvailability);

        // Find which days were toggled on/off
        const toggledDays = Object.keys(updatedAvailability).filter(
            (day) => updatedAvailability[day] !== prevDayAvailability.current[day]
        );

        setUnavailableDates((prevUnavailableDates) => {
            const updatedUnavailable = [...prevUnavailableDates];
            const newUnavailableDates = [];

            allDatesInRange(dateRange[0], dateRange[1]).forEach((date) => {
                const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });

                if (toggledDays.includes(dayName)) {
                    if (!updatedAvailability[dayName]) {
                        // If day is toggled OFF, add it to unavailableDates
                        newUnavailableDates.push(new Date(date));
                    } else {
                        // If day is toggled ON, remove it from unavailableDates
                        const index = updatedUnavailable.findIndex(
                            (unavailableDate) =>
                                unavailableDate.toDateString() === date.toDateString()
                        );
                        if (index > -1) updatedUnavailable.splice(index, 1);
                    }
                }
            });

            return [...new Set([...updatedUnavailable, ...newUnavailableDates])]; // Remove duplicates
        });

        setDateSpecificHours((prev) => {
            const updatedHours = { ...prev };

            // Remove date-specific hours for dates matching the toggled day if the day is toggled ON
            allDatesInRange(dateRange[0], dateRange[1]).forEach((date) => {
                const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
                if (toggledDays.includes(dayName) && updatedAvailability[dayName]) {
                    delete updatedHours[date.toDateString()];
                }
            });

            return updatedHours;
        });

        setSelectedDates((prevSelectedDates) => {
            // Remove selected dates matching the toggled day if the day is toggled ON
            return prevSelectedDates.filter((date) => {
                const dayName = new Date(date).toLocaleDateString('en-US', { weekday: 'short' });
                return !(toggledDays.includes(dayName) && updatedAvailability[dayName]);
            });
        });

        // Update the reference for the next render
        prevDayAvailability.current = updatedAvailability;
    };

    // Utility function to get all dates in range
    const allDatesInRange = (startDate, endDate) => {
        const dates = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };


    const [dayAvailability, setDayAvailability] = useState({
        Sun: true,
        Mon: true,
        Tue: true,
        Wed: true,
        Thu: true,
        Fri: true,
        Sat: true,
    });

    const [timeAvailability, setTimeAvailability] = useState({
        Sun: [{ start: "09:00", end: "17:00" }],
        Mon: [{ start: "09:00", end: "17:00" }],
        Tue: [{ start: "09:00", end: "17:00" }],
        Wed: [{ start: "09:00", end: "17:00" }],
        Thu: [{ start: "09:00", end: "17:00" }],
        Fri: [{ start: "09:00", end: "17:00" }],
        Sat: [{ start: "09:00", end: "17:00" }],
    });

    const handleScheduleUpdate = (updatedSchedule) => {
        const newTimeAvailability = {};
        Object.entries(updatedSchedule).forEach(([day, { enabled, timeSlots }]) => {
            newTimeAvailability[day] = timeSlots || [{ start: "09:00", end: "17:00" }];
        });
        setTimeAvailability(newTimeAvailability);
    };


    // Reference to store previous day availability
    const prevDayAvailability = useRef(dayAvailability);

    useEffect(() => {
        // Check if dateRange is incomplete or empty and reset states
        if (!dateRange.length || dateRange.length === 1 || !dateRange[0] || !dateRange[1]) {
            setUnavailableDates([]);
            setDayAvailability({
                Sun: true, Mon: true, Tue: true, Wed: true, Thu: true, Fri: true, Sat: true
            });
        }
    }, [dateRange]); // This effect purely handles resetting states

    useEffect(() => {
        if (dateRange.length === 2 && dateRange[0] && dateRange[1]) {
            const startDate = new Date(dateRange[0]);
            const endDate = new Date(dateRange[1]);

            let allDatesInRange = [];
            for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                allDatesInRange.push(new Date(d.getTime()));
            }

            const changes = {};
            Object.keys(dayAvailability).forEach(day => {
                if (dayAvailability[day] !== prevDayAvailability.current[day]) {
                    changes[day] = dayAvailability[day];
                }
            });

            if (Object.keys(changes).length > 0) {
                updateUnavailableDatesBasedOnChanges(changes, allDatesInRange);
            }

            // Update the ref to the current state for the next render
            prevDayAvailability.current = dayAvailability;
        }
    }, [dayAvailability, dateRange]);

    const updateUnavailableDatesBasedOnChanges = (changes, allDatesInRange) => {
        setUnavailableDates(current => {
            const newUnavailableDates = allDatesInRange.filter(date => {
                const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
                return changes[dayName] === false;
            });

            const datesToRemove = allDatesInRange.filter(date => {
                const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
                return changes[dayName] === true;
            });

            const updatedDates = current.filter(date =>
                !datesToRemove.some(d =>
                    d.getDate() === date.getDate() &&
                    d.getMonth() === date.getMonth() &&
                    d.getFullYear() === date.getFullYear()
                )
            );

            return [...new Set([...updatedDates, ...newUnavailableDates])]; // Combine and remove duplicates
        });
    };


    const [clientOptions, setClientOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [newClientName, setNewClientName] = useState("");
    const [clientEmails, setClientEmails] = useState([]);
    const [newClientEmail, setNewClientEmail] = useState("");
    const [isAddingNewClientName, setAddingNewClientName] = useState(false);
    const [isAddingNewClientEmail, setAddingNewClientEmail] = useState(false);

    const handleClientChange = (selectedValue) => {
        if (selectedValue === "-99") {
            setAddingNewClientName(true);
            setAddingNewClientEmail(true);
            setNewClientName("");
            setNewClientEmail("");
            setClientEmails([]);
            setSelectedClient(null);
        } else if (selectedValue === "Select Client") {
            setAddingNewClientName(false);
            setAddingNewClientEmail(false);
            setSelectedClient(null);
            setNewClientName("");
            setNewClientEmail("");
            setClientEmails([]);
        } else {
            const client = clientOptions.find(c => c.value === selectedValue);
            if (client) {
                setSelectedClient({
                    ...client,
                    emails: client.contacts || [],
                });
                setClientEmails(client.contacts || []);
                setNewClientName(client.label);
                setAddingNewClientName(false);
            }
        }
    };

    const handleClientEmailChange = (selectedOption) => {
        if (selectedOption === "-99") {
            setAddingNewClientEmail(true);
            setNewClientEmail("");
            setSelectedClient(prev => ({
                ...prev,
                emails: [],
            }));
        } else if (selectedOption === "Select Email") {
            setAddingNewClientEmail(false);
            setNewClientEmail("");
            setSelectedClient(prev => ({
                ...prev,
                emails: clientEmails.length > 0 ? [clientEmails[0]] : [],
            }));
        } else {
            const selectedEmail = clientEmails.find(email => email.id.toString() === selectedOption);
            if (selectedEmail) {
                setNewClientEmail(selectedEmail.email);
                setAddingNewClientEmail(false);
                setSelectedClient(prev => ({
                    ...prev,
                    emails: [selectedEmail],
                }));
            }
        }
    };

    const cancelNewClientAndEmail = () => {
        setAddingNewClientName(false);
        setAddingNewClientEmail(false);
        setSelectedClient(clientOptions[0] || null);
        setNewClientName("");
        setNewClientEmail("");
    };

    const [product, setProduct] = useState([
        {
            productName: null,
            productDescription: null,
        }
    ]);

    const [loading, setLoading] = useState(false);

    const csrf_token = Cookies.get("csrftoken");

    const [serviceList, setServiceList] = useState([]);

    const handleServiceChange = (e, index) => {
        const list = [...serviceList];
        list[index] = e;
        setServiceList(list);
    };

    const handleServiceRemove = (index) => {
        const list = [...serviceList];
        list.splice(index, 1);
        setServiceList(list);
    };

    const handleServiceAdd = () => {
        setServiceList([...serviceList, []]);
    };

    const promotions = [
        {
            value: "P",
            label: "Product",
        },
        {
            value: "S",
            label: "Service",
        },
    ];

    const formComplete = () => {
        const basicChecks = title && description && product && promotionType && checkProdData() && checkProdLength();
        if (props.accountType === "Agency") {
            return basicChecks && checkClientData();
        } else {
            return basicChecks;
        }
    }


    const productName = (index, prod) => {
        setProduct((prev) => {
            const newProduct = prev.map(a => { return { ...a } });
            newProduct[index].productName = prod;
            return newProduct;
        });
    };
    const productDesc = (index, prod) => {
        setProduct((prev) => {
            const newProduct = prev.map(a => { return { ...a } });
            newProduct[index].productDescription = prod;
            return newProduct;
        });
    };

    const addProduct = () => {
        setProduct((prev) => {
            const newProduct = prev.map(a => { return { ...a } });
            newProduct.push({
                productName: null,
                productDescription: null,
            });
            return newProduct;
        });
    };

    const deleteProduct = (index) => {
        const list = [...product];
        list.splice(index, 1);
        setProduct(list);
    };

    const checkProdLength = () => {
        if (product.length === 0 && promotionType !== "L") {
            return false
        }
        return true
    }

    const checkProdData = () => {
        for (const data of product) {
            if (!(data.productName && data.productDescription) && promotionType !== "L") {
                return false;
            }
        }
        return true;
    };
    const checkClientData = () => {
        return (
            selectedClient === "" ||
            (selectedClient.value === "-99" && newClientName !== null && newClientName !== "" && newClientEmail !== null && newClientEmail !== "") ||
            (selectedClient.value !== "-99" && selectedClient.label !== "Select Client" && selectedClient !== null)
        );
    }

    const getHeaderText = (step) => {
        switch (step) {
            case 1:
                return "Create a Campaign: Basic Details";
            case 2:
                return "Create a Campaign: Scheduling Settings";
            case 3:
                return "Create a Campaign: Weekly Visiting Hours";
            case 4:
                return "Create a Campaign: Date-Specific Visiting Hours";
            case 5:
                return "Create a Campaign: Visit Constraints";
            default:
                return "Create a Campaign";
        }
    };
    const getClientList = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/get_client_list`;
        const token = await auth.currentUser.getIdToken();
        const requestMetaData = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
        };
        try {
            await fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        const clients = result.data.map(client => ({
                            value: client.id.toString(),
                            label: client.name,
                            contacts: client.contacts || [{ id: "-99", email: "No emails available" }],
                        }));
                        setClientOptions([...clients]);
                    }
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    };
    // function to create and launch a campaign
    const createAndLaunchCampaign = async () => {
        const url = `${process.env.REACT_APP_DJANGO_API}/create_campaign`;
        const token = await auth.currentUser.getIdToken();
        // Calculate the number of available days
        const availableDays = countAvailableDays(dateRange[0], dateRange[1], unavailableDates);

        const getFormattedUnavailableDates = (dates) => {
            return dates
                .sort((a, b) => new Date(a) - new Date(b)) // Sort dates
                .map(date => isoToDate(date, "date")); // Use isoToDate to format the date
        };

        // Generate the formatted unavailable dates list
        const unavailableDateList = getFormattedUnavailableDates(unavailableDates);
        // Check if available days are less than 7
        if (availableDays < 7) {
            Swal.fire({
                icon: "error",
                title: "Insufficient Available Days",
                text: `You must have at least 7 available days in the selected date range. Currently, you have only ${availableDays} available days.`,
                confirmButtonColor: "#7BBA83",
            });
            return;
        }

        const requestMetaData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                title: title,
                description: description,
                promotionType: promotionType,
                product: product,
                additionalDetails: additionalDetails,
                status: "A",
                usage: serviceList,
                startDate: dateRange[0],
                endDate: dateRange[1],
                busHrList: timeAvailability,
                dayList: dayAvailability,
                dailyLimit: dailyLimit,
                dateSpecificHours: dateSpecificHours,
                unavaliableDateList: unavailableDateList,
                clientId: selectedClient?.value,
                clientEmailId: selectedClient?.emails[0]?.id,
                newClientName: newClientName,
                newClientEmail: newClientEmail,
            }),
        };

        try {
            const response = await fetch(url, requestMetaData);
            const result = await response.json();
            if (!result || !result.success) {
                if (result.data && result.data.failure_reason) {
                    switch (result.data.failure_reason) {
                        case 1:
                            Swal.fire({
                                icon: "error",
                                title: "Invalid Start Date",
                                text: "The start date needs to be at least today or any day onwards. Please adjust the start date.",
                                confirmButtonColor: "#7BBA83",
                            });
                            break;
                        case 2:
                            Swal.fire({
                                icon: "error",
                                title: "Invalid End Date",
                                text: "The end date must be at least 10 days from the start date. Please adjust the end date.",
                                confirmButtonColor: "#7BBA83",
                            });
                            break;
                        default:
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                confirmButtonColor: "#7BBA83",
                                footer: "Please try again later.",
                            });
                            break;
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                }
                setLoading(false);
            } else {
                setLoading(false);
                props.setShowModal(false);
                Swal.fire({
                    icon: "success",
                    title: "Done!",
                    text: "Successfully created and launched campaign!",
                    confirmButtonColor: "#7BBA83",
                }).then(() => window.location.reload());
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    };

    const countAvailableDays = (startDate, endDate, unavailableDates) => {
        let availableDays = 0;
        for (let d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
            // Check if the date is not in the unavailable dates list
            const isUnavailable = unavailableDates.some(unavailableDate =>
                d.getFullYear() === unavailableDate.getFullYear() &&
                d.getMonth() === unavailableDate.getMonth() &&
                d.getDate() === unavailableDate.getDate()
            );
            if (!isUnavailable) {
                availableDays += 1;
            }
        }
        return availableDays;
    };

    const validateTimeAvailability = () => {
        const missingTimeSlots = Object.keys(dayAvailability).filter(
            (day) => dayAvailability[day] && (!timeAvailability[day] || timeAvailability[day].length === 0)
        );

        if (missingTimeSlots.length > 0) {
            Swal.fire({
                icon: "warning",
                title: "Missing Time Slots",
                text: `Please add at least one time slot for the following days: ${missingTimeSlots.join(", ")}`,
                confirmButtonColor: "#7BBA83",
            });
            return false;
        }
        return true;
    };

    const hasOverlappingTimeSlots = (timeSlots) => {
        for (let i = 0; i < timeSlots.length; i++) {
            for (let j = i + 1; j < timeSlots.length; j++) {
                const [start1, end1] = [
                    new Date(`1970-01-01T${timeSlots[i].start}:00`),
                    new Date(`1970-01-01T${timeSlots[i].end}:00`),
                ];
                const [start2, end2] = [
                    new Date(`1970-01-01T${timeSlots[j].start}:00`),
                    new Date(`1970-01-01T${timeSlots[j].end}:00`),
                ];

                if (start1 < end2 && start2 < end1) {
                    // Overlap found
                    return true;
                }
            }
        }
        return false;
    };

    const validateDateSpecificHours = () => {
        const overlappingDates = [];

        Object.entries(dateSpecificHours).forEach(([date, timeSlots]) => {
            if (timeSlots && timeSlots.length > 1 && hasOverlappingTimeSlots(timeSlots)) {
                overlappingDates.push(date);
            }
        });

        if (overlappingDates.length > 0) {
            Swal.fire({
                icon: "error",
                title: "Overlapping Time Slots",
                text: `Overlapping time slots detected for the following date-specific hours: ${overlappingDates.join(
                    ", "
                )}. Please adjust them before proceeding.`,
                confirmButtonColor: "#7BBA83",
            });
            return false; // Validation failed
        }

        return true; // Validation passed
    };

    const validateWeeklyHoursNoOverlap = () => {
        const overlappingDays = []; // Array to store days with overlaps

        for (const [day, timeSlots] of Object.entries(timeAvailability)) {
            if (dayAvailability[day] && timeSlots && hasOverlappingTimeSlots(timeSlots)) {
                overlappingDays.push(day); // Add the day to the list
            }
        }

        if (overlappingDays.length > 0) {
            // Show error message with all overlapping days
            Swal.fire({
                icon: "error",
                title: "Overlapping Time Slots",
                text: `Overlapping time slots detected for the following days: ${overlappingDays.join(", ")}. Please adjust before proceeding.`,
                confirmButtonColor: "#7BBA83",
            });
            return false; // Validation failed
        }

        return true; // No overlaps found
    };




    // get client list on page load
    useEffect(() => {
        if (props.accountType === "Agency")
            getClientList();
    }, []);

    useEffect(() => {
        if (loading && formComplete()) {
            createAndLaunchCampaign();
        }
    }, [loading]);

    const removeUnavailableDate = (dateToRemove) => {
        setUnavailableDates(currentDates =>
            currentDates.filter(date =>
                date.getTime() !== dateToRemove.getTime()
            )
        );
    };

    function StepTwo() {
        const DatePickerCustomInput = forwardRef(({ value, onClick, inputName }, ref) => (
            <div style={{ position: 'relative' }}>
                <button
                    className="w-40 rounded-full py-1 shadow-lg bg-gray-200 outline-gray-400 text-gray-700"
                    onClick={(e) => {
                        onClick(e); // Open the DatePicker
                    }}
                    ref={ref}
                >
                    {value || "Select Date"}
                </button>
            </div>
        ));

        const handleDateChange = (date, index) => {
            if (currentStep === 2) { // Ensure changes are only applied if in step 2
                const currentTime = new Date(); // Get the current time
                if (date === null) {
                    setDateRange([null, null]);
                } else {
                    const newDates = [...dateRange];
                    const updatedDate = new Date(date); // Convert to Date object if not already
                    updatedDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());

                    newDates[index] = updatedDate;

                    // Ensure the date range remains valid
                    if (index === 0 && newDates[1] && updatedDate > newDates[1]) {
                        const newEndDate = new Date(newDates[1]);
                        newEndDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
                        newDates[1] = newEndDate;
                    }
                    if (index === 1 && newDates[0] && updatedDate < newDates[0]) {
                        const newStartDate = new Date(newDates[0]);
                        newStartDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
                        newDates[0] = newStartDate;
                    }
                    setDateRange(newDates);
                }
            }
        };

        return (
            <div className="w-min">
                <div className="text-neutral-600 text-base font-semibold font-poppins pb-8">Scheduling Setting</div>
                <div className="text-neutral-600 text-sm font-semibold font-poppins pb-2">Date Range</div>
                <div className="text-gray-600 py-4">Start Date:</div>
                <DatePicker
                    minDate={new Date()}
                    maxDate={dateRange[1]} // End date or no restriction
                    selected={dateRange[0]}
                    onChange={date => handleDateChange(date, 0)}
                    isClearable
                    disabledKeyboardNavigation
                    customInput={<DatePickerCustomInput inputName="start" />}
                />
                <div className="text-gray-600 py-4">End Date:</div>
                <DatePicker
                    minDate={dateRange[0] || new Date()} // Start date or today's date
                    selected={dateRange[1]}
                    onChange={date => handleDateChange(date, 1)}
                    isClearable
                    disabledKeyboardNavigation
                    customInput={<DatePickerCustomInput inputName="end" />}
                />
            </div>
        );
    }

    function StepThree() {
        return (
            <div className="w-min">
                <div className="text-neutral-600 text-base font-semibold font-poppins pb-4">Weekly Visiting Hours</div>
                <div className="text-neutral-600 text-sm font-semibold font-poppins pb-6 w-[90%]">
                    Set your weekly hours for regular availability.
                </div>

                <div className="text-gray-600 pb-2">Weekly Hours</div>
                <div className="bg-white rounded-xl shadow-custom w-min">
                    <ScheduleForm
                        updateDayAvailability={handleDayAvailabilityChange}
                        dayAvailability={dayAvailability}
                        updateSchedule={handleScheduleUpdate}
                        timeAvailability={timeAvailability}
                        extendModal={false}
                    />
                </div>
            </div>
        );
    }

    // Step Four: Date-Specific Scheduling
    function StepFour() {
        const handleHoursChange = (date, index, field, value) => {
            setDateSpecificHours((prev) => {
                const updatedRanges = prev[date].map((range, i) => {
                    if (i === index) {
                        const newRange = { ...range, [field]: value };

                        // Enforce a minimum 1-hour range
                        const start = new Date(`1970-01-01T${newRange.start}:00`);
                        const end = new Date(`1970-01-01T${newRange.end}:00`);

                        if (field === "start" && end - start < 60 * 60 * 1000) {
                            newRange.end = new Date(start.getTime() + 60 * 60 * 1000).toTimeString().slice(0, 5);
                        } else if (field === "end" && end - start < 60 * 60 * 1000) {
                            newRange.start = new Date(end.getTime() - 60 * 60 * 1000).toTimeString().slice(0, 5);
                        }

                        return newRange;
                    }
                    return range;
                });

                return { ...prev, [date]: updatedRanges };
            });
        };

        const handleAddTimeRange = (date) => {
            setDateSpecificHours((prev) => {
                const existingRanges = prev[date] || [];
                const lastEnd = existingRanges.length > 0 ? existingRanges[existingRanges.length - 1].end : "09:00";

                const [hours, minutes] = lastEnd.split(":").map(Number);
                const newStartTime = new Date(1970, 0, 1, hours, minutes + 60);
                const newStart = newStartTime.toTimeString().slice(0, 5);
                const newEnd = new Date(newStartTime.getTime() + 60 * 60 * 1000).toTimeString().slice(0, 5);

                return {
                    ...prev,
                    [date]: [...existingRanges, { start: newStart, end: newEnd }]
                };
            });
        };

        const handleRemoveTimeRange = (date, index) => {
            setDateSpecificHours((prev) => ({
                ...prev,
                [date]: prev[date].filter((_, i) => i !== index),
            }));
        };

        const handleToggleDateAvailability = (date) => {
            // Normalize date using isoToDate utility
            const dateString = isoToDate(date, "date"); // Converts to "MM/DD/YYYY"
            const dayOfWeek = new Date(date).toLocaleDateString("en-US", { weekday: "short" }); // Get day of the week

            let wasUnavailable = false; // Track if it was unavailable initially

            // Remove from unavailableDates
            setUnavailableDates((prevUnavailableDates) => {
                const updatedUnavailable = prevUnavailableDates.filter((unavailableDate) => {
                    const isSameDate =
                        new Date(unavailableDate).toLocaleDateString("en-US") === dateString;
                    if (isSameDate) wasUnavailable = true;
                    return !isSameDate;
                });
                return updatedUnavailable;
            });

            // Remove from selectedDates
            setSelectedDates((prevSelectedDates) => {
                const updatedSelectedDates = prevSelectedDates.filter((d) => d !== dateString);
                return [...updatedSelectedDates]; // Force a re-render with a new array
            });

            // Remove custom time slots for this date
            setDateSpecificHours((prev) => {
                const updatedHours = { ...prev };
                delete updatedHours[dateString];
                return updatedHours;
            });

            // Check if the day is disabled in dayAvailability and re-add as unavailable if needed
            if (!dayAvailability[dayOfWeek] && !wasUnavailable) {
                setUnavailableDates((prevUnavailableDates) => [
                    ...prevUnavailableDates,
                    new Date(date),
                ]);
            }
        };

        return (
            <div className="w-full md:w-min min-w-48 px-4 md:px-0">
                <div className="text-neutral-600 text-lg md:text-base font-semibold font-poppins pb-4">Date-Specific Visiting Hours</div>
                <div className="text-neutral-600 text-sm font-semibold font-poppins pb-6 w-full md:w-[90%]">
                    Set specific hours for individual dates when your schedule differs from your regular weekly hours.
                </div>

                <div className="mt-6 p-4 border rounded-md bg-gray-50">
                    <h4 className="font-semibold text-gray-700 mb-2 text-base md:text-md">Date-Specific Hours</h4>
                    <p className="text-sm md:text-base text-neutral-600 mb-4">
                        Override your availability for specific dates.
                    </p>

                    {selectedDates
                        .sort((a, b) => new Date(a) - new Date(b))
                        .map((date) => (
                            <div key={date} className="mb-4">
                                <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                                    <span className="text-sm font-medium text-gray-600">{date}</span>
                                    <div className="flex items-center space-x-2 mt-2 md:mt-0">
                                        {(dateSpecificHours[date]?.length || 0) < 3 && (
                                            <button
                                                onClick={() => handleAddTimeRange(date)}
                                                className="text-blue-600 text-sm md:text-base"
                                            >
                                                + Add Time Range
                                            </button>
                                        )}
                                        <button
                                            onClick={() => handleToggleDateAvailability(date)}
                                            className="text-gray-400 hover:text-red-600 text-sm md:text-base"
                                        >
                                            ✕
                                        </button>
                                    </div>
                                </div>

                                {(dateSpecificHours[date] || []).map((range, index) => (
                                    <div key={index} className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2 mt-2 mr-4">
                                        <input
                                            type="time"
                                            value={range.start}
                                            onChange={(e) => handleHoursChange(date, index, "start", e.target.value)}
                                            className="border rounded-md p-2 text-gray-600 text-sm w-full md:w-auto"
                                        />
                                        <span className="text-gray-500 hidden md:inline">to</span>
                                        <input
                                            type="time"
                                            value={range.end}
                                            onChange={(e) => handleHoursChange(date, index, "end", e.target.value)}
                                            className="border rounded-md p-2 text-gray-600 text-sm w-full md:w-auto"
                                        />
                                        <button
                                            onClick={() => handleRemoveTimeRange(date, index)}
                                            className="text-gray-400 hover:text-red-600 text-sm md:text-base mt-2 md:mt-0"
                                        >
                                            ✕
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ))}
                </div>
            </div>
        );
    }

    function StepFive() {
        return (
            <div>
                <h2 className="text-base font-semibold text-neutral-600 pb-8 font-poppins">Visit Constraints</h2>

                <div className="mb-4">
                    <label className="text-sm font-semibold text-neutral-600 font-poppins block">Daily Limit</label>
                    <div className="flex items-center">
                        <input
                            type="number"
                            className="w-min rounded-xl p-1 shadow-lg bg-gray-200 outline-none text-gray-700 mr-2"
                            value={dailyLimit}
                            onChange={(e) => setDailyLimit(e.target.value)}
                        />
                        <span className="text-gray-600">visits max per day</span>
                    </div>
                </div>

                <div>
                    <label className="text-sm font-semibold text-neutral-600 font-poppins block">Unavailable Dates</label>
                    <div className="text-gray-600 py-2">Click on a date to remove it from the list of unavailable dates.</div>
                    <div style={{ maxHeight: '280px', overflowY: 'auto' }}> {/* Scrollable container */}
                        <ul className="list-none pl-0">
                            {unavailableDates.sort((a, b) => a - b).map((date, index) => (
                                <li key={index} className="mb-2 flex items-center">
                                    <span className="w-24">{date.toLocaleDateString('en-US')}</span> {/* Set width */}
                                    <button
                                        onClick={() => removeUnavailableDate(date)}
                                        className="flex-shrink-0"
                                    >
                                        <img src={CloseBlueButton} alt="Remove" className="w-4 h-4" />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Modal setShowModal={props.setShowModal}>
                {/* header */}
                <div className="w-[95%] ml-4 mr-4">
                    <div className="flex flex-row mt-6 mb-4 items-center w-full border-b border-solid border-slate-200">
                        <h2 className="flex-auto mb-4">
                            {getHeaderText(currentStep)}
                        </h2>
                        <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mx-3 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white fixed top-5 right-2">
                            <img src={CloseBlackButton} onClick={() => props.setShowModal(false)} alt="Close" className="w-6 h-6" />
                        </button>
                    </div>

                    {/* {renderStep()} */}
                    {currentStep === 1 ?
                        <>
                            <div className="flex flex-col gap-2 md:grid md:grid-cols-2 md:w-[50rem]">
                                {props.accountType === "Agency" && (
                                    <>
                                        <div className="mx-2">
                                            <h4>Select Client:</h4>
                                            {isAddingNewClientName ? (
                                                <TWTextField
                                                    whitebox
                                                    value={newClientName}
                                                    onChange={e => {
                                                        setNewClientName(e);
                                                        setSelectedClient(prev => ({
                                                            ...prev,
                                                            label: e,
                                                            value: "-99",
                                                        }));
                                                    }}
                                                    placeholder="Enter new client name"
                                                />
                                            ) : (
                                                <TWDropDown white data={[...clientOptions, { value: "-99", label: "Add New Client" }]} onChange={handleClientChange} value={selectedClient?.value || ""}>
                                                    Select Client
                                                </TWDropDown>
                                            )}
                                        </div>
                                        <div className="mx-2">
                                            <h4>Select Client Email:</h4>
                                            {isAddingNewClientEmail ? (
                                                <div className="relative">
                                                    <TWTextField
                                                        whitebox
                                                        value={newClientEmail}
                                                        onChange={e => {
                                                            setNewClientEmail(e);
                                                            setSelectedClient(prev => ({
                                                                ...prev,
                                                                emails: [{
                                                                    id: "-99",
                                                                    email: e,
                                                                }],
                                                            }));
                                                        }}
                                                        placeholder="Enter new client email" />
                                                    <button className="absolute top-0 right-0" onClick={cancelNewClientAndEmail} style={{ cursor: 'pointer' }}>
                                                        <img src={CloseBlackButton} alt="Close" className="w-6 h-6" />
                                                    </button>
                                                </div>
                                            ) : (
                                                <TWDropDown
                                                    white
                                                    data={[
                                                        ...clientEmails.map(email => ({ value: email.id.toString(), label: email.email })),
                                                        { value: "-99", label: "Add New Email" }
                                                    ]}
                                                    onChange={handleClientEmailChange}
                                                    value={selectedClient?.emails?.[0]?.id || ""}
                                                    disabled={!selectedClient && !isAddingNewClientName}
                                                >
                                                    Select Email
                                                </TWDropDown>

                                            )}
                                        </div>
                                    </>
                                )}


                                <div className="mb-4 md:px-2">
                                    <h4 className="w-full font-semibold text-gray-600">Campaign Title</h4>
                                    <div className="flex">
                                        <TWTextField whitebox placeholder="e.g. New 5 Topping Pizza!" value={title} onChange={setTitle} />

                                    </div>
                                </div>

                                <div className="mb-4 md:px-2">
                                    <h4 className="w-full font-semibold text-gray-600">Promotion Type</h4>
                                    <div className="flex">
                                        <TWDropDown white height="12" data={promotions} value={promotionType} onChange={setPromotionType}>Select</TWDropDown>
                                    </div>
                                </div>

                                <div className="mb-4 md:px-2">
                                    <h4 className="w-full font-semibold text-gray-600">Campaign Description</h4>
                                    <div className="flex">
                                        <TWTextArea placeholder="e.g. Promoting our new menu item." value={description} onChange={setDescription} />
                                    </div>
                                </div>


                                <div className="mb-2 md:px-2">
                                    <h4 className="w-full font-semibold text-gray-600">Additional Details</h4>
                                    <div className="flex">
                                        <TWTextArea placeholder="e.g. Based in Atlanta." value={additionalDetails} onChange={setAdditionalDetails} />
                                    </div>

                                </div>
                            </div>
                            <div className="flex flex-row w-full items-center px-5 pb-5 justify-between">
                                <div>
                                    <h4 className="text-xl DosisBold text-mi-black">Products</h4>
                                </div>
                                <div>
                                    {product.length < 4 && (
                                        <TWButton sm type="button"
                                            onClick={addProduct}
                                            className="add-btn">+ Add Product</TWButton>
                                    )}
                                </div>
                            </div>

                            <div className="w-full mb-4">
                                {product.map((prod, index) => (
                                    <div key={index} className="flex flex-col p-4 gap-6 md:grid md:grid-cols-6 md:w-[50rem] items-center mx-auto">
                                        <div className="md:col-span-1 w-full">
                                            <h4 className="text-sm font-semibold text-gray-600 text-center md:text-left">Product {index + 1}</h4>
                                        </div>
                                        <div className="md:col-span-2 w-full">
                                            <TWTextField
                                                whitebox
                                                value={prod.productName}
                                                onChange={e => productName(index, e)}
                                                placeholder="Name"
                                            />
                                        </div>
                                        <div className="md:col-span-2 w-full">
                                            <TWTextField
                                                whitebox
                                                value={prod.productDescription}
                                                onChange={e => productDesc(index, e)}
                                                placeholder="Description"
                                            />
                                        </div>
                                        <div className="md:col-span-1 w-full flex justify-end">
                                            <TWButton
                                                delete
                                                sm
                                                onClick={() => product.length > 1 && deleteProduct(index)}
                                                disabled={product.length <= 1}
                                                className={`${product.length <= 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            >
                                                Delete
                                            </TWButton>
                                        </div>
                                    </div>
                                ))}

                            </div>

                            <div className="flex gap-2 items-center w-full px-5 pb-5 justify-between">
                                <div>
                                    <h4 className="w-full font-semibold text-gray-600">
                                        Intended Usage
                                        <InfoPing placement="right-start" className="ml-2 cursor-pointer">
                                            <Fragment>
                                                <div className="text-sm text-gray-500">Please add <em><u>specific URL's</u></em> of anywhere you intend to use the content created by the influencer in order to <b>reserve rights</b> to use the content there.</div>
                                            </Fragment>
                                        </InfoPing>
                                    </h4>
                                </div>
                                <div>
                                    {serviceList.length < 4 && (
                                        <TWButton sm type="button"
                                            onClick={handleServiceAdd}
                                            className="add-btn">Add Usage</TWButton>
                                    )}
                                </div>
                            </div>
                            {serviceList.map((singleService, index) => (
                                <div className="flex gap-2 items-center w-full pb-3 px-5" key={index}>
                                    {(
                                        <div className="flex flex-col p-2 gap-3 md:grid md:grid-cols-6 md:w-[50rem] w-full">
                                            <div className="col-span-5 md:items-center">
                                                <TWTextField
                                                    whitebox
                                                    placeholder="e.g. https://www.facebook.com/your_page"
                                                    value={singleService.service}
                                                    onChange={e => { handleServiceChange(e, index); }}
                                                    required>
                                                </TWTextField>
                                            </div>
                                            <div className="col-span-1 md:px-2">
                                                <div className="md:w-full w-1/3 mx-auto md:mx-none">
                                                    <TWButton sm delete onClick={() => handleServiceRemove(index)}>Remove</TWButton>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </> : <></>}
                    {currentStep >= 2 && (
                        <>
                            <div className="flex flex-col md:flex-row gap-12 mb-8">

                                <CalendarComponentBusiness
                                    onDateChange={onDateChange}
                                    isSameDay={isSameDay}
                                    isUnavailableDate={isUnavailableDate}
                                    setUnavailableDates={setUnavailableDates}
                                    dateRange={dateRange}
                                    selectSingleDate={false}
                                    currentStep={currentStep}
                                    selectedDates={selectedDates}
                                    unavailableDates={unavailableDates}
                                    dayAvailability={dayAvailability}
                                />
                                {currentStep === 2 && <StepTwo />}
                                {currentStep === 3 && <StepThree />}
                                {currentStep === 4 && <StepFour />}
                                {currentStep === 5 && <StepFive />}
                            </div>
                        </>
                    )}

                    {/* footer */}
                    <div className="flex flex-row gap-2 mb-4 mr-4 items-center md:justify-end w-full pt-3 border-t border-solid border-slate-200">
                        <div className="flex-auto md:grow-0">
                            {currentStep !== 1 && (
                                <TWButton md onClick={goBackward} disabled={currentStep === 1}>
                                    Previous
                                </TWButton>
                            )}
                        </div>
                        <div className="flex-auto md:grow-0">
                            {currentStep === totalSteps ? (
                                <TWButton
                                    md
                                    loading={loading}
                                    disabled={!formComplete() || loading}
                                    onClick={() => {
                                        const sortedSelectedDates = [...selectedDates].sort((a, b) => new Date(a) - new Date(b));

                                        // Validate weekly schedule setup
                                        if (!validateTimeAvailability()) {
                                            Swal.fire({
                                                icon: "error",
                                                title: "Weekly Hours Required",
                                                text: "Please ensure weekly hours are set for each enabled day before submitting.",
                                                confirmButtonColor: "#7BBA83",
                                            });
                                            return;
                                        }

                                        // Check if selected dates overlap with unavailable dates
                                        const unavailableDatesSelected = sortedSelectedDates.some(date =>
                                            unavailableDates.some(unavailableDate =>
                                                new Date(date).toDateString() === new Date(unavailableDate).toDateString()
                                            )
                                        );

                                        if (unavailableDatesSelected) {
                                            Swal.fire({
                                                icon: "error",
                                                title: "Unavailable Date Selected",
                                                text: "One or more time-specific dates are marked as unavailable. Please enable the dates or remove them from the schedule before submitting.",
                                                confirmButtonColor: "#7BBA83",
                                            });
                                            return;
                                        }

                                        // Validate date-specific time slots
                                        const missingTimeSlots = sortedSelectedDates.some(date =>
                                            !dateSpecificHours[date] || dateSpecificHours[date].length === 0
                                        );

                                        if (missingTimeSlots) {
                                            Swal.fire({
                                                icon: "error",
                                                title: "Time Slots Required",
                                                text: "Please ensure that all selected dates have at least one time slot before submitting.",
                                                confirmButtonColor: "#7BBA83",
                                            });
                                            return;
                                        }

                                        // Proceed with submission
                                        setLoading(true);
                                    }}
                                >
                                    Submit
                                </TWButton>
                            ) : (
                                <TWButton
                                    md
                                    onClick={() => {
                                        const sortedSelectedDates = [...selectedDates].sort((a, b) => new Date(a) - new Date(b));

                                        if (currentStep === 2) {
                                            // Validate date range
                                            if (!dateRange || dateRange.length !== 2 || !dateRange[0] || !dateRange[1]) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Date Range Required",
                                                    text: "Please select a valid date range before proceeding to the next step.",
                                                    confirmButtonColor: "#7BBA83",
                                                });
                                                return;
                                            }
                                        } else if (currentStep === 3) {
                                            // Validate weekly hours for overlaps
                                            if (!validateWeeklyHoursNoOverlap()) {
                                                return;
                                            }
                                        } else if (currentStep === 4) {
                                            if (!validateDateSpecificHours()) {
                                                return;
                                            }
                                            // Validate date-specific time slots
                                            const missingTimeSlots = sortedSelectedDates.some(date =>
                                                !dateSpecificHours[date] || dateSpecificHours[date].length === 0
                                            );

                                            if (missingTimeSlots) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Time Slots Required",
                                                    text: "Please ensure that all selected dates have at least one time slot before proceeding.",
                                                    confirmButtonColor: "#7BBA83",
                                                });
                                                return;
                                            }

                                            // Check if selected dates are marked unavailable
                                            const unavailableDatesSelected = sortedSelectedDates.some(date =>
                                                unavailableDates.some(unavailableDate =>
                                                    new Date(date).toDateString() === new Date(unavailableDate).toDateString()
                                                )
                                            );

                                            if (unavailableDatesSelected) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Unavailable Date Selected",
                                                    text: "You have selected unavailable dates. Please enable or remove them before proceeding.",
                                                    confirmButtonColor: "#7BBA83",
                                                });
                                                return;
                                            }
                                        }

                                        // Proceed to the next step
                                        goForward();
                                    }}
                                    disabled={currentStep === totalSteps}
                                >
                                    Next Step
                                </TWButton>
                            )}
                        </div>
                    </div>

                </div>



            </Modal >
        </>
    );
}
export default CreateCampaignModal;
