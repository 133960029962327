import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { isoToDate, formatDate } from "../../dateUtils";
import { InfoPing } from "../Pings";
import AcceptContract from "./AcceptContract";
import InfluencerAmendment from "./InfluencerAmendment";
import Modal from "../Modal";
import DatesModal from "./DatesModal";
import TWButton from "../TWButton";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const InfluencerContractBody = (props) => {
    const [productOverflow, setProductOverflow] = useState(false);
    const [fullDescription, setFullDescription] = useState(false);
    const productRef = useRef(null);

    const [showVisitDateModal, setShowVisitDateModal] = useState(false);
    const [showSubmissionDateModal, setShowSubmissionDateModal] = useState(false);

    const [visitDate, setVisitDate] = useState();
    const [visitTime, setVisitTime] = useState();
    const [submissionDate, setSubmissionDate] = useState();
    const [tz, setTz] = useState();
    const [specDateList, setSpecDateList] = useState();
    const [dateRange, setDateRange] = useState([]);
    const [unavailableDates, setUnavailableDates] = useState([]);
    const [busHr, setBusHr] = useState([]);
    const csrf_token = Cookies.get("csrftoken");

    useLayoutEffect(() => {
        if (productRef.current && !fullDescription) {
            setProductOverflow(productRef.current.offsetHeight < productRef.current.scrollHeight);
        }
    });

    useEffect(() => {
        if (props.data.status) {
            const getDates = async () => {
                const token = await auth.currentUser.getIdToken();
                const url = `${process.env.REACT_APP_DJANGO_API}/get_avaliable_dates`;
                const requestMetaData = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf_token,
                        "Authorization": `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        id: props.data.contract_id,
                    }),
                };
                try {
                    await fetch(url, requestMetaData)
                        .then(res => res.json())
                        .then((result) => {
                            if (!result || !result.success) {
                                Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Something went wrong!",
                                    confirmButtonColor: "#7BBA83",
                                    footer: "Please try again later.",
                                });
                            } else {
                                setDateRange([isoToDate(result.data.start_date), isoToDate(result.data.end_date)]);
                                setUnavailableDates(result.data.off_date_list);
                                setBusHr(result.data.bus_hr_list);
                                setTz(result.data.timezone);
                                setSpecDateList(result.data.spec_date_list);
                            }
                        });
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "DatesModal!",
                        confirmButtonColor: "#7BBA83",
                        footer: "Please try again later.",
                    });
                }
            }
            getDates();
        }
    }, []);

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    // Helper function to add days to a date
    const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    return (
        <>
            {(props.data.status === "Current" || props.data.status === "Fullfilled")
                ? <h2>Contracts Details</h2>
                : <h2 className="mt-7 font-bold">Proposal Details</h2>
            }
            <div className="w-full h-fit flex flex-col items-center mx-auto p-8 mb-4 rounded-3xl gap-2 shadow-all-xl bg-white  md:overflow-y-auto md:scrollbar border">
                {/* Campaign Info */}
                <h2 className="w-full font-bold text-neutral-950 text-lg text-left">Campaign Descriptions</h2>
                <div className="mt-2 mb-2 h-fit w-full bg-white rounded-xl">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="flex flex-col gap-4">
                            <h3>Title: <h3 className="inline">{props.data.campaign.title}</h3></h3>
                            <h3>Description: </h3>
                            <p className="inline font-normal text-base text-mi-black font-poppins">{props.data.campaign.description}</p>
                            <h3>Additional Details: </h3>
                            <p className="inline font-normal text-base text-mi-black font-poppins">{props.data.campaign.additional_details}</p>
                        </div>
                    </div>
                </div>
                <h2 className="w-full text-left text-neutral-950 mb-8 mt-7">Deliverable Details</h2>
                {/* Promotion Info */}
                <div className="w-full flex flex-col gap-2">

                    <div className="flex items-center">
                        <h3 className="w-1/6">Execution Timeframe:</h3>
                        <p className="font-base">
                            {formatDate(addDays(props.data.start_date, 1))} - {formatDate(addDays(props.data.end_date, 1))}
                        </p>
                    </div>

                    <div className="flex items-center">
                        <h3 className="w-1/6">Deliverable:</h3>
                        <p>Instagram {props.data.post_type}</p>
                    </div>

                    <div className="flex items-center">
                        <h3 className="w-1/6">Focus of Promotion:</h3>
                        <p>{props.data.campaign.promotion_type}</p>
                    </div>
                </div>


                {/* Intended Usage */}
                <div className="w-full bg-white rounded-xl py-8">
                    <p className="text-sm font-semibold text-mi-black font-poppins mb-4">The business intends to use your content for promotion on these links:</p>
                    {(props.data.campaign.intended_usage !== null && props.data.campaign.intended_usage.length !== 0)
                        ? props.data.campaign.intended_usage.map((item, index) => <div key={index}>
                            <a href={item} className="text-mi-blue underline font-poppins" >{item}</a>
                            <br />
                        </div>)
                        : <p className="text-mi-black font-poppins">No intended usage provided</p>
                    }

                </div>

                <div className="w-full flex flex-col items-center space-y-8">
                    <h2 className="w-full text-neutral-950 font-bold text-lg font-bold text-left mt-5">Visit Details</h2>

                    <div className="flex w-full grid grid-cols-2">
                        {/* Left Column */}
                        <div className="w-full flex flex-col gap-4">
                            {/* Product or Service Included */}
                            <div className="flex flex-col gap-4">
                                <h3>Product or Service Included:</h3>
                                <p className="text-mi-black w-11/12">{props.data.campaign.product_name}</p>
                            </div>

                            {/* Address */}
                            <div className="flex flex-col gap-4">
                                <h3 className="inline mr-2">Address:</h3>
                                <span className="text-mi-black">
                                    {
                                        `${props.data.address.line_1}, ${props.data.address.line_2 ? `${props.data.address.line_2}, ` : ''}
                                        ${props.data.address.line_3 ? `${props.data.address.line_3}, ` : ''}
                                        ${props.data.address.city}, ${props.data.address.state} ${props.data.address.zip_code}`
                                    }
                                </span>
                            </div>
                        </div>

                        {/* Right Column */}
                        <div className="w-1/2 flex flex-col">
                            {/* Product Description */}
                            <div className="flex flex-col gap-4">
                                <h3>Product Description:</h3>
                                <p ref={productRef} className={`text-mi-black ${!fullDescription ? "line-clamp-2" : ""}`}>
                                    {props.data.campaign.product_description}
                                </p>
                                {productOverflow &&
                                    <div className="flex justify-end w-full">
                                        <button onClick={() => setFullDescription(!fullDescription)} className="text-blue-600 underline text-sm font-semibold">{fullDescription ? "Hide" : "Read more..."}</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {(props.data.status === "Pending") &&
                    <>
                        <h2 className="w-full text-left mt-10 mb-8">Pick Dates *</h2>
                        <p className="text-mi-black text-left w-full">Please select tentative Pickup and Post Dates.</p>
                        <p className="text-mi-black text-left w-full">Don’t fret! you can always reschedule these dates within the date range (listed above)</p>
                    </>}
                {(props.data.status !== "Pending") &&
                    <>
                        <h2 className="w-full text-left mt-10">My Dates</h2>
                    </>}
                <div className="flex w-full justify-start space-x-24 mt-8">
                    <div className="w-1/6 flex flex-col">
                        {/* Product Description */}
                        <div className="flex flex-col gap-4">
                            <h3>Visit Date:</h3>
                            {(props.data.status !== "Pending") &&
                                <div>
                                    {Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0 ? (
                                        <>
                                            <p className="text-mi-black">{isoToDate(props.data.amendment_approved[0].new_visit_date, "datetime")}</p>
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-mi-black">{props.data.visit_date ? isoToDate(props.data.visit_date, "datetime") : 'Not Selected'}</p>
                                        </>

                                    )}
                                </div>
                            }
                            {(props.data.status === "Pending") &&
                                <>
                                    <div className="bg-white text-mi-blue underline" onClick={() => setShowVisitDateModal(true)}>
                                        {visitDate ? `Selected Visit Date: ${formatDate(visitDate)} at ${visitTime}` : "Please Select Visit Date"}
                                    </div>
                                    {showVisitDateModal &&
                                        <DatesModal
                                            setShowModal={setShowVisitDateModal}
                                            onDateSelected={setVisitDate}
                                            onTimeSelected={setVisitTime}
                                            contractID={props.data.contract_id}
                                            time={true}
                                            setTz={setTz}
                                            step={1}
                                            minDate={new Date(Math.max(new Date(dateRange[0]).getTime(), new Date().getTime()))}
                                            maxDate={submissionDate}
                                            dateRange={dateRange}
                                            unavailableDates={unavailableDates}
                                            specDateList={specDateList} // Use state value here
                                            busHr={busHr}
                                            timezone={tz}
                                            visitDate={visitDate}
                                            submissionDate={null}
                                        />
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className="w-1/2 flex flex-col">
                        {/* Product Description */}
                        <div className="flex flex-col gap-4">
                            <h3>Submission Date:</h3>
                            {(props.data.status !== "Pending") &&
                                <div>
                                    {Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0 ? (
                                        <>
                                            <p className="text-mi-black">{formatDate(props.data.amendment_approved[0].new_submission_date)}</p>
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-mi-black">{props.data.submission_date ? formatDate(props.data.submission_date) : 'Not Selected'}</p>
                                        </>
                                    )}
                                </div>
                            }
                            {(props.data.status === "Pending") &&
                                <>
                                    <div className="bg-white text-mi-blue underline" onClick={() => setShowSubmissionDateModal(true)}>
                                        {submissionDate ? `Selected Submission Date: ${formatDate(submissionDate)}` : "Please Select Submission Date"}
                                    </div>
                                    {showSubmissionDateModal &&
                                        <DatesModal
                                            setShowModal={setShowSubmissionDateModal}
                                            onDateSelected={setSubmissionDate}
                                            setTz={setTz}
                                            contractID={props.data.contract_id}
                                            time={false}
                                            step={2}
                                            minDate={new Date(Math.max(visitDate ? visitDate.getTime() : 0, new Date().getTime()))}
                                            maxDate={null}
                                            dateRange={dateRange}
                                            unavailableDates={unavailableDates}
                                            busHr={busHr}
                                            timezone={tz}
                                            visitDate={null}
                                            submissionDate={submissionDate}
                                        />}
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className={`grid grid-cols-1 md:${(props.data.status !== "Pending") ? "grid-cols-3" : "grid-cols-2"} gap-4 w-full`}>
                    {(props.data.status !== "Pending") &&
                        <div className="pb-4 pt-2 rounded-xl bg-white">
                            {Array.isArray(props.data.amendment_approved) && props.data.amendment_approved.length > 0 ? (
                                <>
                                    <p className="text-mi-black">Visit Date: {isoToDate(props.data.amendment_approved[0].new_visit_date, "datetime")}</p>
                                    <p className="text-mi-black">Final Post Date: {formatDate(props.data.amendment_approved[0].new_submission_date)}</p>
                                </>
                            ) : (
                                <>
                                    <p className="text-mi-black">Visit Date: {props.data.visit_date ? isoToDate(props.data.visit_date, "datetime") : 'Not Selected'}</p>
                                    <p className="text-mi-black">Final Post Date: {props.data.submission_date ? formatDate(props.data.submission_date) : 'Not Selected'}</p>
                                </>
                            )}
                        </div>
                    }
                </div>

                {/* Modify Dates */}
                {(props.data.status === "Current" || props.data.status === "Ready For Pay") &&
                    <>
                        <p className="w-full text-left font-bold text-mi-blue Dosis text-xl">Modify Dates</p>
                        <InfluencerAmendment
                            data={props.data}
                            dateRange={dateRange}
                            unavailableDates={unavailableDates}
                            busHr={busHr}
                            timezone={tz}
                            visitDate={props.data.visit_date}
                            submissionDate={props.data.submission_date}
                            setVisitDate={setVisitDate}
                            setSubmissionDate={setSubmissionDate}
                            specDateList={specDateList}
                        />
                    </>
                }

                {/* Compensation */}
                <h2 className="w-full text-left mt-9 text-neutral-950 mb-6">Compensation</h2>
                <div className="h-fit w-full bg-white rounded-xl">
                    <div className="w-full flex flex-row justify-between mb-2">
                        <h3 className="text-left font-bold text-neutral-800">Advertised Rate</h3>
                        <h3 className="text-right">{props.data.price_details.influencer_price}</h3>
                    </div>

                    <div className="w-full flex flex-row justify-between mb-2">
                        <h3 className="text-left">My Influency Commission Fee (6.5%)</h3>
                        <h3 className="text-right">- {props.data.price_details.MI_fees}</h3>
                    </div>

                    <div className="w-full flex flex-row justify-between mb-4">
                        <h3 className="text-left">
                            Stripe Payment Processing Fee
                            <InfoPing placement="right-start" className="ml-2 cursor-pointer">
                                Stripe charges:
                                (0.25% + 2.9% + $0.55)
                                per transaction
                            </InfoPing>
                        </h3>
                        {(props.data.price_details.influencer_price !== "$0.00")
                            ? <h3 className="text-right">- {props.data.price_details.Stripe_fees}</h3>
                            : <h3 className="text-right">- $0.00</h3>
                        }
                    </div>

                    <div className="w-full h-[2px] my-2 bg-mi-black mb-4"></div>

                    <div className="w-full flex flex-row justify-between mb-2">
                        <h3 className="text-left">Expected Compensation</h3>
                        {(props.data.price_details.influencer_price !== "$0.00")
                            ? <h1 className="text-right">{props.data.price_details.take_home}</h1>
                            : <h1 className="text-right">$0.00</h1>
                        }
                    </div>
                    <h2 className="mt-9 font-bold text-neutral-950">Accept or Decline</h2>
                    {/* <div className="w-full flex flex-row justify-between mb-2">
                        <h3 className="text-left">Expected Payment Date</h3>
                        <h3 className="text-right">{isoToDate(props.data.pay_expiration_date).slice(0, -7)}</h3>
                    </div> */}

                </div>

                {/* Accept or Decline */}
                {(props.data.status === "Pending") &&
                    <>
                        <AcceptContract data={props.data} visitDate={visitDate} submissionDate={submissionDate} visitTime={visitTime} tz={tz} />
                    </>
                }

            </div>
        </>
    );
}
export default InfluencerContractBody;
