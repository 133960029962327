import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import './custom-calendar.css';

const CalendarComponentInfluencer = ({ dateRange, onDateChange, isSameDay, isUnavailableDate, selectSingleDate, currentStep, minDate, maxDate, visitDate, submissionDate }) => {
    const [clickedDate, setClickedDate] = useState(null);
    const onClickDay = (day) => {
        setClickedDate(new Date(day.getFullYear(), day.getMonth(), day.getDate()));
    };

    const isClickedDate = (date) => {
        return clickedDate &&
            clickedDate.getFullYear() === date.getFullYear() &&
            clickedDate.getMonth() === date.getMonth() &&
            clickedDate.getDate() === date.getDate();
    };

    const isInDateRange = (date) => {
        if (!dateRange || dateRange.length < 2) return false;
    
        // Normalize the dates by setting the time to midnight
        const [start, end] = dateRange.map(d => {
            const normalizedDate = new Date(d);
            return new Date(normalizedDate.getFullYear(), normalizedDate.getMonth(), normalizedDate.getDate());
        });
    
        // Normalize the input date as well
        const checkDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    
        return checkDate >= start && checkDate <= end;
    };
    
    const isSelectedVisitDate = (date) => {
        return visitDate && isSameDay(date, new Date(visitDate));
    };

    const isSelectedSubmissionDate = (date) => {
        return submissionDate && isSameDay(date, new Date(submissionDate));
    };

    return (
        <div className="bg-white rounded-xl shadow-custom">
            <Calendar
                onChange={onDateChange}
                minDate={minDate || new Date()}
                maxDate={maxDate || (dateRange[1] ? new Date(dateRange[1]) : undefined)}
                value={dateRange && dateRange.length ? [minDate, dateRange[1]] : null}
                selectRange={!selectSingleDate}
                allowPartialRange={true}
                returnValue={currentStep > 2 ? "range" : "start"}
                onClickDay={onClickDay}
                formatShortWeekday={(locale, date) => date.toLocaleString(locale, { weekday: 'narrow' }).toUpperCase()}
                tileContent={({ date, view }) =>
                    view === 'month' && isSameDay(date, new Date()) ? (
                        <div style={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <div style={{
                                width: '5px',
                                height: '5px',
                                background: '#7096D1',
                                borderRadius: '9999px',
                                position: 'absolute',
                                left: '-9px',
                                bottom: '-15px'
                            }}></div>
                        </div>
                    ) : null
                }
                tileClassName={({ date, view }) => {
                    if (view !== 'month') {
                        return null;
                    }

                    if (isUnavailableDate(date)) {
                        return 'special-date';
                    }

                    if (isClickedDate(date) || isSelectedVisitDate(date) || isSelectedSubmissionDate(date)) {
                        return 'clicked-date';
                    }

                    if (isInDateRange(date)) {
                        return 'date-range';
                    }

                    return null;
                }}
                tileDisabled={({ date, view }) => {
                    if (selectSingleDate && view === 'month' && isUnavailableDate(date)) {
                        return true;
                    }
                    return false;
                }}
            />

            <div className="m-8 flex justify-center">
                <div className="flex items-center mr-4">
                    <div className="w-6 h-6 bg-mi-blue rounded-full border mr-2"></div>
                    <div className="text-sm font-semibold text-gray-600">Available Dates</div>
                </div>
                {selectSingleDate && (
                    <div className="flex items-center mr-4">
                        <div className="w-6 h-6 bg-mi-red rounded-full border mr-2"></div>
                        <div className="text-sm font-semibold text-gray-600">Unavailable Dates</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CalendarComponentInfluencer;
