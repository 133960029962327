import dropdownArrow from "../assets/images/dropdown_arrow.svg";
import whiteDropdownArrow from "../assets/images/white_dropdown_arrow.svg";

const TWDropDown = (props) => {
    const widthClass = props.lg ? "w-8/12" : "w-full";
    const colorClass = props.white ? "bg-white border-mi-grey border-2" : props.grey ? "bg-gray-400 border-grey-500 border-1" : "bg-mi-blue";
    const checkboxColorClass = props.actionBlue ? "bg-mi-blue" : "bg-[#dfe6f4]";
    const dropdownWidth = props.lg ? "w-8/12" : "w-11/12";
    const hasData = props.data && props.data.length > 0;
    const heightClass = props.height ? `h-${props.height}` : "h-auto";

    // Filter out keys with -1 or 0 if excludeZeroKey prop is true
    const filteredData = props.excludeZeroKey
        ? props.data.filter((option) => option.value !== -1 && option.value !== 0)
        : props.data;

    return (
        <>
            {props.checkbox ? (
                <div>
                    <label className="relative w-full h-full">
                        <input
                            type="checkbox"
                            className="hidden peer"
                            onChange={() => {
                                props.onToggle();
                            }}
                        />
                        <div
                            className={`flex justify-between items-center ${checkboxColorClass} ${!props.isOpen ? "" : "bg-mi-blue"} rounded-lg ${dropdownWidth} p-2 text-black text-xs font-medium font-poppins cursor-pointer`}
                        >
                            <p className={`text-sm ${props.whitePlaceholderText ? "text-white" : " text-neutral-600"}`}>Select</p>
                            {
                                <img
                                    src={props.whiteDropdownArrow ? whiteDropdownArrow : dropdownArrow}
                                    alt="Open"
                                    className="h-3 w-3 rotate"
                                />
                            }
                        </div>

                        <div
                            className={`${
                                !props.isOpen ? "absolute" : ""
                            } bg-white border rounded-lg transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto overflow-y-auto scrollbar ${dropdownWidth} max-h-60`}
                        >
                            {hasData ? (
                                <ul>
                                    {filteredData.map((option, index) => (
                                        <li key={index}>
                                            <label className="flex cursor-pointer p-2 text-xs transition-colors hover:bg-indigo-400 hover:bg-opacity-20 [&:has(input:checked)]:bg-indigo-400 [&:has(input:checked)]:bg-opacity-20">
                                                <input
                                                    type="checkbox"
                                                    value={option.value}
                                                    className="cursor-pointer"
                                                    onChange={props.checkboxOnChange}
                                                />
                                                <span className="ml-3">{option.label}</span>
                                            </label>
                                            <hr />
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="flex flex-col items-center justify-center p-8">
                                    <div className="text-lg font-semibold text-mi-blue">
                                        Create a list first.
                                    </div>
                                    <div className="mt-2 text-sm text-gray-600">
                                        Get started by creating a new list to organize your items.
                                    </div>
                                </div>
                            )}
                        </div>
                    </label>
                </div>
            ) : (
                <select
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    className={`${colorClass} bg-opacity-20 rounded-lg ${
                        props.xs ? "w-3/12" : `${widthClass}`
                    } ${heightClass} p-2 text-black text-xs font-medium font-poppins cursor-pointer`}
                >
                    {!props.excludeZeroKey && (
                        <option key={-1} value={null} defaultValue>
                            {props.children}
                        </option>
                    )}
                    {filteredData.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            )}
        </>
    );
};

export default TWDropDown;
